import CollectionShow from '@/components/collections/Show'
import { CollectionProps } from '@/navigation/types'
import PageWithDrawer from '@/components/layouts/PageWithDrawer'

const CollectionPage = ({
  route
}: CollectionProps) => {

  const { collectionId } = route.params

  return (
    <PageWithDrawer>
      <CollectionShow collectionId={collectionId} />
    </PageWithDrawer>
  )
}


export default CollectionPage
