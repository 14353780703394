import React, { useEffect, useState } from "react"
import { useTranslation } from "@/i18n"
import { useNavigation } from '@/navigation'
import Alert from '@/lib/Alert'
import Platform from '@/lib/Platform'

// Custom hook to handle unsaved changes
const useUnsavedChangesWarning = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] => {
  const { t } = useTranslation()

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false)
  const navigation = useNavigation()

  useEffect(() => {
    let beforeUnload: any = null
    if(Platform.OS === 'web') {
      beforeUnload = (e: BeforeUnloadEvent) => {
        if (!unsavedChanges) return

        e.preventDefault()
        e.returnValue = t('alert.confirmLeave.message')
      }
      window.addEventListener("beforeunload", beforeUnload)
    }

    const handleNavigate = (e: any) => {
      if (!unsavedChanges) return
      e.preventDefault()

      const leave = ()=> { navigation.dispatch(e.data.action) }

      Alert.alert(
        t('alert.confirmLeave.title'),
        t('alert.confirmLeave.message') || '',
        [
          { text: t('alert.cancel')||'', style: 'cancel' },
          { text: t('alert.confirmLeave.ok')||'', onPress: leave  },
        ],
      )
    }
    const unsubscribe = navigation.addListener('beforeRemove', handleNavigate)

    return () => {
      beforeUnload ? window.removeEventListener("beforeunload", beforeUnload) : null
      unsubscribe()
    }
  }, [navigation, unsavedChanges])

  return [unsavedChanges, setUnsavedChanges]
}

export default useUnsavedChangesWarning
