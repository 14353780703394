import { useState } from 'react'
import { TextInput, ViewStyle } from 'react-native'
import { space, colors } from '@/styles/theme'
import Platform from '@/lib/Platform'
import { useTranslation } from '@/i18n'
import { styles as textStyles } from '@/components/Text'
import { StyleSheet } from 'react-native'
import Text from '@/components/Text'

const styles = StyleSheet.create({
  input: {
    ...textStyles.text,
    borderColor: 'transparent',
    marginBottom: space.xs,
    marginTop: space.xs,
    textAlignVertical: 'top',
    padding: space[2],
    backgroundColor: 'white',
    borderRadius: space[1],
    overflow: 'hidden',
  },
  caption: {
    marginBottom: space.xs,
  }
})

type Props = {
  value: string
  onChangeText: (value: string) => void
  inputStyle?: ViewStyle
}

const OutputArea = ({
  value,
  onChangeText,
  inputStyle,
}: Props) => {

  const { t } = useTranslation()

  const [height, setHeight] = useState(40)

  const onContentSizeChange = (event: any)=> {
    setHeight(event.nativeEvent.contentSize.height)
  }

  const adjustTextInputSize = (event: any) => {
    if(Platform.OS !== 'web') return

    const el = event?.target || event?.nativeEvent?.target;
    if (el) {
      el.style.height = 0;
      const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight;
      el.style.height = `${newHeight}px`;
    }
  }

  return (
    <>
      <Text caption style={styles.caption}>
        {t('fragments.form.output.label')}
      </Text>
      <TextInput multiline testID='input-output'
        value={value}
        onChange={adjustTextInputSize}
        onContentSizeChange={onContentSizeChange}
        onChangeText={onChangeText}
        onFocus={(e: any) => { e.target.style.outline = 'none' }}
        placeholderTextColor={colors.grey[600]}
        placeholder={t('fragments.form.output.placeholder') || ''}
        style={[
          styles.input,
          { height },
          inputStyle,
        ]}
      />
    </>
  )

}

export default OutputArea
