import SmallButton from '@/components/SmallButton'
import Dialog from '@/components/Dialog'
import { FragmentId, Reference } from '@/types'
import {
  ViewStyle,
  View,
  ScrollView,
  StyleSheet,
} from 'react-native'
import Text, { styles as textStyles } from '@/components/Text'
import { useTranslation } from '@/i18n'
import { useState } from 'react'
import { read } from '@/features/fragments'
import { useStore } from '@/store'
import useEntityValue from '@/hooks/useEntityValue'
import useLoading from '@/hooks/useLoading'
import { space } from '@/styles/theme'
import { NativeLink } from '@/components/Link'
import FontIcon from '@/components/FontIcon'

const styles = StyleSheet.create({
  item: {
    marginBottom: space[2],
  },
  text: {
    marginTop: space[0.5],
    marginLeft: space[1],
  },
  footer: {
    marginTop: space[0.5],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  similarity: {
    marginRight: space[1],
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
    ...textStyles.caption,
  },
  navText: {
    marginLeft: space[0.5],
  }
})

const round = (n: number)=> Math.round(n * 100) / 100

const Item = ({
  reference,
}: {
  reference: Reference
})=> {

  const { t } = useTranslation()

  return(
    <View style={styles.item}>

      <Text label>{t('fragments.form.input.label')}</Text>
      <Text body2 style={styles.text}>{reference.input}</Text>

      <Text label>{t('fragments.form.output.label')}</Text>
      <Text body2 style={styles.text}>{reference.output.trim()}</Text>


      <View style={styles.footer}>
        <Text caption style={styles.similarity}>
          {t('references.item.similarity')}: {round(reference.similarity)}
        </Text>

        <NativeLink
          style={styles.nav}
          to={{ screen: 'Doc', params: { docId: reference.docId, fragmentId: reference.id } }}
        >
          <FontIcon name='open-outline'/>
          <Text caption style={styles.navText}>
            {t('references.item.viewDoc')}
          </Text>
        </NativeLink>
      </View>
    </View>
  )

}

const referencesAreSame = (a: Reference, b: Reference)=> {
  return a.id === b.id
}

const ReferencesButton = ({
  fragmentId,
  style
}: {
  fragmentId: FragmentId
  style?: ViewStyle
})=> {

  const { dispatch, getState } = useStore()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const references = useEntityValue('fragments', fragmentId, 'references', referencesAreSame)
  const { loading, withLoading } = useLoading()

  const open = async()=> {
    await withLoading(async()=> {
      // read to make sure the fragment.references are loaded
      await read(fragmentId)(dispatch, getState)
      setIsOpen(true)
    })
  }

  return(
    <>
      <SmallButton testID='button-references'
        label={t('fragments.form.button.references') || ''}
        onPress={open}
        loading={loading}
        iconName='receipt-outline'
        style={style}
      />
      <Dialog
        title={t('references.list.title') || ''}
        visible={isOpen}
        onClose={()=> setIsOpen(false)}
      >
        <ScrollView>
          {references?.map((reference, i)=> (
            <Item key={i} reference={reference}/>
          ))}
        </ScrollView>
      </Dialog>
    </>
  )

}

export default ReferencesButton
