import View from '@/components/View'
import { ViewStyle } from 'react-native'

const PageContainer = ({
  children,
  style,
}: {
  children: React.ReactNode
  style?: ViewStyle
})=> {

  return (
    <View style={{
      backgroundColor: 'white',
      flex: 1,
      alignItems: 'center',
    }}>
      <View style={{
        maxWidth: 1080,
        width: '100%',
        flex: 1,
        ...style
      }}>
        {children}
      </View>
    </View>
  )

}

export default PageContainer
