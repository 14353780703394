import { CollectionId, KeywordId } from '@/types'
import { useStore } from '@/store'
import { index } from '@/features/keywords'
import KeywordCard from '@/components/keywords/Card'
import FlatList from '@/components/FlatList'
import { ViewStyle } from 'react-native'
import usePaginate from '@/hooks/usePaginate'

const KeywordsList = ({
  collectionId,
  ListHeaderComponent,
  style,
}: {
  collectionId: CollectionId
  ListHeaderComponent?: any
  style?: ViewStyle
}) => {

  const { dispatch, getState } = useStore()

  const {
    loading,
    ids,
    read,
  } = usePaginate(
    (next)=> index(collectionId, next)(dispatch, getState),
    'keywords',
    [collectionId],
  )

  const renderItem = ({ item }: { item: unknown })=> {
    return (
      <KeywordCard keywordId={item as KeywordId} />
    )
  }

  return (
    <FlatList
      data={ids}
      renderItem={renderItem}
      loading={loading}
      onEndReached={read}
      ListHeaderComponent={ListHeaderComponent}
      style={style}
    />
  )

}

export default KeywordsList
