import { View, StyleSheet, ViewStyle } from 'react-native'
import FontIcon from '@/components/FontIcon'
import { colors, space, iconSize } from '@/styles/theme'
import Text from '@/components/Text'
import { useState } from 'react'
import TouchableOpacity from '@/components/TouchableOpacity'

const styles = StyleSheet.create({
  icon: {
    marginRight: space[2],
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: space[2],
    borderRadius: space[1],
  },
  title: { marginBottom: space[1], },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fold: { height: 'unset', }
})

const iconMap = {
  info: 'information-circle-outline',
  warning: 'warning-outline',
  code: 'code-outline',
  danger: 'alert-circle-outline',
}

const colorMap = {
  info: colors.blue[500],
  warning: colors.amber[500],
  code: colors.grey[600],
  danger: colors.red[600],
}

const bgColorMap = {
  info: colors.blue[50],
  warning: colors.amber[50],
  code: colors.grey[100],
  danger: colors.red[50],
}

type AlertProps = {
  type: 'info' | 'warning' | 'code' | 'danger'
  title: string
  description?: string
  children?: React.ReactNode
  style?: ViewStyle
  testID?: string
  foldable?: boolean
}

const Header = ({
  title,
  onPress,
  foldable,
  closed,
}: {
  title: string
  onPress: () => void
  foldable?: boolean
  closed?: boolean
}) => {

  if(foldable){
    return(
      <TouchableOpacity testID='touchable-alert-header'
        style={styles.header}
        onPress={onPress}
      >
        <Text bold body2 style={styles.title}>{title}</Text>
        <FontIcon
          name={`chevron-${closed ? 'down' : 'up'}-outline`}
          style={styles.fold}
        />
      </TouchableOpacity>
    )
  }

  return(
    <View style={styles.header}>
      <Text bold body2 style={styles.title}>{title}</Text>
    </View>
  )
}

const Alert = ({
  type,
  title,
  description,
  children,
  style,
  foldable,
}: AlertProps) => {

  const [closed, setClosed] = useState(foldable)

  return(
    <View style={[
      styles.container,
      { backgroundColor: bgColorMap[type] },
      style
    ]}>
      <View style={styles.icon}>
        <FontIcon name={iconMap[type]} color={colorMap[type]} size={iconSize.medium} />
      </View>
      <View style={{ flex: 1 }}>
        <Header
          title={title}
          onPress={() => setClosed(!closed)}
          foldable={foldable}
          closed={closed}
        />

        {closed ? null : (
          <>
            {description ? (
              <Text body2>{description}</Text>
            ) : null}
            {children}
          </>
        )}
      </View>
    </View>
  )
}

export default Alert
