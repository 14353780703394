import Picker from '@/components/lib/Picker'
import { StyleSheet, ViewStyle } from 'react-native'
import { styles as textStyles } from '@/components/Text'
import { styles as buttonStyles } from '@/components/SmallButton'
import { card, colors } from '@/styles/theme'
import { useEffect } from 'react'
import { getLocales } from '@/lib/Localization'
import { Locale, check } from '@/i18n'
import { useTranslation } from '@/i18n'
import { setLanguage } from '@/features/generate'
import { selectLanguage } from '@/features/generate/selectors'
import { useSelector, useStore } from '@/store'

const styles = StyleSheet.create({
  picker: {
    ...textStyles.caption,
    color: colors.grey[900],
    ...buttonStyles.button,
    ...card.button,
    borderWidth: 0,
  }
})

const options = [ 'en', 'ja' ]

const LanguagePicker = ({
  style
}: {
  style?: ViewStyle
}) => {

  const { t } = useTranslation()
  const { dispatch } = useStore()
  const lng = useSelector(selectLanguage)
  useEffect(() => {
    setDefaultLanguage()
  }, [])

  const setDefaultLanguage = async()=> {
    // don't set if already set
    if(lng) return

    const locales = await getLocales()
    if(locales?.length > 0) {
      const checked = check(locales[0].languageCode as Locale)
      setLanguage(checked)(dispatch)
    }else{
      setLanguage('en')(dispatch)
    }
  }

  const onChange = (itemValue: string) => {
    setLanguage(itemValue as Locale)(dispatch)
  }

  return (
    <Picker testID='picker-language'
      selectedValue={lng}
      style={[styles.picker, style]}
      onValueChange={onChange}
    >
      {options.map((option, i) => (
        <Picker.Item key={i} label={t(`language.${option}`)} value={option} />
      ))}
    </Picker>
  )

}

export default LanguagePicker
