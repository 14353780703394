import { htmlToFragments } from './html'
import { URL, DraftDoc, DraftFragment } from './types'
import { filterDocs } from './utils'

type Node = {
  _: string
  $: { type: 'text' | 'html' }
}

type Link = {
  $: {
    href: URL
    type: 'text/html' | 'application/atom+xml'
    rel: 'next' | 'self' | string
  }
}

type Entry = {
  id: string[]
  title: Node[]
  content: Node[] // with html text
  link: Link[]
  published?: string[]
}

export type Atom = {
  feed: {
    entry: Entry[]
    link: Link[]
  }
}


// use link href as externalId where type is text/html if multiple links are found
const getId = (entry: Entry): string => {
  if(!entry.link) throw new Error('no link found')
  if(entry.link.length === 1) return entry.link[0].$.href

  const htmlLinks = entry.link.filter(link => link.$.type === 'text/html')
  if(htmlLinks.length > 0) return htmlLinks[0].$.href

  const selfLinks = entry.link.filter(link => link.$.rel === 'self')
  if(selfLinks.length > 0) return selfLinks[0].$.href

  return htmlLinks[0].$.href
}

const checkId = (entry: Entry): boolean => {
  const id = entry.id[0]

  const isBlogger = id.includes('tag:blogger.com')
  if(!isBlogger) return true // only filters for blogger now

  const isPost = id.includes('post-')
  if(!isPost) return false

  return true
}

const getCreatedAt = (entry: Entry): number | undefined => {
  const published = entry.published?.[0]
  if(!published) return undefined

  const date = new Date(published)
  return date.getTime()
}

export const atomToDraftDocs = async(json: Atom): Promise<DraftDoc[]> => {

  const entries = json.feed.entry

  const docs: DraftDoc[] = []
  for(const entry of entries) {
    const passed = checkId(entry)

    if(!passed) continue

    const title = entry.title[0]._
    const externalId = getId(entry)
    const externalCreatedAt = getCreatedAt(entry)

    const doc: DraftDoc = {
      title,
      isImported: true,
      externalId,
      fragments: [],
      externalCreatedAt
    }

    const html = entry.content[0]._
    doc.fragments = await htmlToFragments(html)

    docs.push(doc)
  }

  return filterDocs(docs)
}

export const getAtomNextPage = (atom: Atom): URL | undefined => {
  const links = atom.feed.link
  if(!links) return undefined

  const nextLinks = links.filter(link => link.$.rel === 'next')
  if(nextLinks.length === 0) return undefined

  return nextLinks[0].$.href
}


