import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '@/locales/en.json'
import ja from '@/locales/ja.json'
import { getLocales } from '@/lib/Localization'

export type Locale = 'en' | 'ja'

export const getCurrentLocale = (): Locale => {
  const locale = i18n.language
  return check(locale)
}

export const check = (locale: string): Locale => {
  const isOk = locale === 'en' || locale === 'ja'
  if(!isOk){
    return 'en'
  }
  return locale as Locale
}

const resources = {
  en: {
    translation: en
  },
  ja: {
    translation: ja
  }
}

// Intl.Locale may not be available on some devices
let locale = null
try{
  locale = getLocales()[0]
}catch(e){
  console.log(e)
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: locale?.languageCode || 'en',
    fallbackLng: 'en',
  });

export { I18nextProvider, useTranslation, Trans } from 'react-i18next'

export default i18n
