import { useState, useEffect } from 'react'
import { ViewStyle } from 'react-native'
import useIsMobile from '@/hooks/useIsMobile'

const useResponsiveFlex = ({
  reverseColumns = false,
}={}) => {
  const isMobile = useIsMobile()
  const [flexDirection, setFlexDirection] = useState<ViewStyle['flexDirection']>('row')
  const [alignItems, setAlignItems] = useState<ViewStyle['alignItems']>('flex-start')

  useEffect(() => {
    const updateLayout = () => {
      if(isMobile) { // mobile
        if(reverseColumns) {
          setFlexDirection('column-reverse')
        }else{
          setFlexDirection('column')
        }
        setAlignItems('center')
      } else { // desktop
        setFlexDirection('row')
        setAlignItems('flex-start')
      }
    }

    updateLayout()
  }, [isMobile])

  return {
    flexDirection,
    alignItems,
  }
}

export default useResponsiveFlex
