import { htmlToFragments } from './html'
import { URL, DraftDoc, DraftFragment } from './types'
import { filterDocs } from './utils'

type Node = string[]
type RSSLink = URL[]
type WPPostType = 'post' | string
type Item = {
  title?: Node
  link?: RSSLink
  description?: Node // text content inside CDATA
  'content:encoded'?: Node // html from wordpress, may not be present on other platform
  'wp:post_type'?: WPPostType[]
  pubDate?: string[]
}

export type RSS = {
  rss: {
    channel: {
      item: Item[]
    }[]
  }
}

const getId = (item: Item): string => {
  if(!item.link) throw new Error('no link found')
  return item.link[0]
}

const check = (item: Item): boolean => {
  const postType = item['wp:post_type']?.[0]
  if(!postType) return true // allow if not wordpress (or exported XML)

  return postType === 'post'
}

const getCreatedAt = (item: Item): number | undefined => {
  const pubDate = item.pubDate?.[0]
  if(!pubDate) return undefined

  return new Date(pubDate).getTime()
}

export const rssToDraftDocs = async(json: RSS): Promise<DraftDoc[]> => {

  const items = json.rss.channel[0].item

  const docs: DraftDoc[] = []
  for(const item of items) {
    const title = item.title?.[0] || ''
    const externalId = getId(item)
    const externalCreatedAt = getCreatedAt(item)

    const passed = check(item)
    if(!passed) continue

    const doc: DraftDoc = {
      title,
      isImported: true,
      fragments: [],
      externalId,
      externalCreatedAt,
    }

    let html = item['content:encoded']?.[0]
    if(!html) html = item.description?.[0] || ''

    doc.fragments = await htmlToFragments(html)

    docs.push(doc)
  }

  return filterDocs(docs)
}

