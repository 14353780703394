import TextInput from '@/components/TextInput'
import Text from '@/components/Text'
import Button from '@/components/Button'
import View from '@/components/View'
import { useState } from 'react'
import { useTranslation } from '@/i18n'
import { post } from '@/features/requests'
import { OrgId } from '@/types'
import api from '@/api'
import { RootState, useStore, useSelector } from '@/store'
import { space } from '@/styles/theme'
import useLoading from '@/hooks/useLoading'
import { add } from '@/features/flash'
import { orgRole } from '@/features/entities/selectors'

const InviteMember = ({
  orgId
}: {
  orgId: OrgId
}) => {

  const { dispatch, getState } = useStore()
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const { loading, withLoading } = useLoading()
  const role = useSelector((state: RootState )=> orgRole(state, orgId))

  const onPress = async()=> {
    await withLoading(async()=> {
      // value could be a string of emails separated by newlines or commas
      const emails = value.split(/[\n,]/).map((email)=> email.trim()).filter((email)=> email.length > 0)
      const response = await post(api.orgs.invite(orgId), { emails })(dispatch, getState)
      if(response.ok){
        setValue('')
        add({ type: 'success', text: t('flash.inviteMember.success') })(dispatch, getState)
      }
    })
  }

  if(role !== 'owner') return null

  return (
    <View style={{ marginTop: space[2] }}>
      <Text label>{t('inviteMember.invite')}</Text>
      <TextInput testID='input-emails' multiline
        placeholder={t('inviteMember.placeholder')}
        numberOfLines={4}
        value={value}
        onChangeText={(text)=> setValue(text)}
      />

      <View style={{
        marginTop: space[2],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
      }}>
        <Button testID='button-invite'
          small
          variant='contained'
          onPress={onPress}
          label={t('inviteMember.invite')}
          loading={loading}
        />
      </View>
    </View>
  )

}

export default InviteMember
