import {
  TouchableOpacity,
  StyleSheet,
  ViewStyle,
  TextStyle,
} from 'react-native'
import ActivityIndicator from '@/components/ActivityIndicator'
import Text from '@/components/Text'
import { colors, space, fontSize } from '@/styles/theme'
import Link from '@/components/Link'
import View from '@/components/View'

const styles = StyleSheet.create({
  container: {
    borderRadius: space[3],
    minWidth: 64,
    maxWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: space[1],
    paddingLeft: space[2],
    paddingRight: space[2],
  },
  outlinedButton: {
    backgroundColor: colors.contrast,
    borderColor: colors.grey[900],
    borderWidth: 2,
  },
  outlinedLabel: {
    color: colors.grey[900],
  },
  containedButton: {
    backgroundColor: colors.primary[400],
  },
  containedLabel: {
    color: colors.contrastText,
  },
})

const Label = ({
  text,
  variant,
  danger,
  small,
  thin,
  style,
}: {
  text?: string | null
  variant?: 'contained'
  danger?: boolean
  small?: boolean
  thin?: boolean
  style?: TextStyle
}) => {
  return (
    <Text bold={!thin} light={thin}
      style={[
        variant === 'contained' ? styles.containedLabel : styles.outlinedLabel,
        danger && { color: colors.red[600] },
        small && { fontSize: fontSize.body2 },
        style,
      ]}
    >
      {text}
    </Text>
  )
}


type BaseButtonProps = {
  testID?: string
  label?: string | null
  labelStyle?: TextStyle
  style?: ViewStyle
  variant?: 'contained'
  disabled?: boolean
  fullWidth?: boolean
}

type LinkButtonProps = BaseButtonProps & {
  to: string
}

export const LinkButton = ({
  to,
  label,
  style,
  variant,
  disabled,
  fullWidth,
  labelStyle,
}: LinkButtonProps) => {

  return (
    <Link
      to={disabled ? '' : to}
      style={[
        styles.container,
        fullWidth && { maxWidth: '100%' },
        variant === 'contained' ? styles.containedButton : styles.outlinedButton,
        disabled && { opacity: 0.5 },
        style
      ]}
    >
      <Label text={label} variant={variant} style={labelStyle} />
    </Link>
  )
}

type ButtonProps = BaseButtonProps & {
  onPress: () => void
  loading?: boolean
  icon?: React.ReactNode
  danger?: boolean
  small?: boolean
  thin?: boolean
}

const Button = ({
  onPress,
  label,
  style,
  labelStyle,
  variant,
  loading,
  icon,
  danger,
  small,
  thin,
  disabled,
  fullWidth,
}: ButtonProps) => {

  const handlePress = () => {
    if(loading || disabled) return

    onPress()
  }

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={[
        styles.container,
        fullWidth && { maxWidth: '100%' },
        variant === 'contained' ? styles.containedButton : styles.outlinedButton,
        danger && { borderColor: colors.red[600] },
        loading && { opacity: 0.5 },
        disabled && { opacity: 0.5 },
        style
      ]}
      activeOpacity={disabled || loading ? 0.5 : 0.6}
    >

      {icon && !loading ? (
        <View style={{  marginRight: space[1] }}>
          {icon}
        </View>
      ) : null}

      {loading ? (
        <ActivityIndicator
          size='small'
          color={variant === 'contained' ? colors.contrastText : colors.primary[700]}
          style={{ marginRight: space[1] }}
        />
      ) : null}

      <Label
        small={small}
        text={label}
        variant={variant}
        danger={danger}
        thin={thin}
        style={labelStyle}
      />
    </TouchableOpacity>
  )

}

export default Button
