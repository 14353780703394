import Text from '@/components/Text'
import View from '@/components/View'
import { StyleSheet } from 'react-native'
import { space } from '@/styles/theme'
import Link from '@/components/Link'

const styles = StyleSheet.create({
  contanier: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    marginBottom: space[2],
    flexWrap: 'wrap',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
  },
  next: {
    marginLeft: space[1],
    marginRight: space[1],
  }
})

type Item = {
  label?: string
  to?: string
}

const isValidLink = (to: string): boolean => {
  if (!to) return false
  if (to.includes('undefined')) return false
  return true
}

const ItemText = ({
  item,
  isLast
}: {
  item: Item
  isLast: boolean
}) => {
  if (!item.label) return null

  const itemContent = item.to && isValidLink(item.to) ? (
    <Link to={item.to}>
      <Text label>{item.label}</Text>
    </Link>
  ) : (
    <Text label>{item.label}</Text>
  )

  const nextArrow = !isLast && <Text label style={styles.next}>{">"}</Text>

  return (
    <View style={styles.item}>
      {itemContent}
      {nextArrow}
    </View>
  )
}

const Breadcrumbs = ({
  list,
}: {
  list: Item[]
}) => {

  return (
    <View style={styles.contanier}>
      {list.map((item, index)=> (
        item ? <ItemText key={index} item={item} isLast={index === list.length - 1}/> : null
      ))}
    </View>
  )

}

export default Breadcrumbs
