import * as WebBrowser from 'expo-web-browser'
import * as Google from 'expo-auth-session/providers/google'
import log from '@/log'
import Alert from '@/lib/Alert'
import i18n from '@/i18n'

try{
  WebBrowser.maybeCompleteAuthSession()
} catch (err) {
  console.error(err)
  log.capture(err as Error)
  // alert to reload
  Alert.alert(
    i18n.t('auth.google.errors.failedToLoad') || '',
    i18n.t('auth.google.errors.pleaseTryAgain') || '',
    [
      {
        text: 'Reload',
        onPress: ()=> {
          // open root path in the browser
          window.open('/', '_self')
        }
      }
    ]
  )
}

import Button from '@/components/Button'
import api from '@/api'
import { setAuth } from '@/features/auth'
import { useStore } from '@/store'
import config from '@/config'
import { useNavigation } from '@/navigation'
import { OrgId, InviteId } from '@/types'
import { useTranslation } from '@/i18n'
import { add } from '@/features/flash'
import useLoading from '@/hooks/useLoading'
import Image from '@/components/Image'
import googleIcon from '@/assets/google.svg'
import analytics from '@/analytics'

const AuthGoogleButton = ({
  orgId,
  inviteId,
  isLogin,
}: {
  orgId?: OrgId,
  inviteId?: InviteId,
  isLogin?: boolean,
}) => {

  const { t } = useTranslation()
  const { dispatch, getState } = useStore()
  const navigation = useNavigation()
  const { loading, withLoading } = useLoading()

  const [_req, _res, promptAsync] = Google.useAuthRequest({
    // androidClientId: 'GOOGLE_GUID.apps.googleusercontent.com',
    // iosClientId: 'GOOGLE_GUID.apps.googleusercontent.com',
    webClientId: config.google.webClientId,
  });

  const handlePress = async () => {
    analytics().logEvent(isLogin ? 'login_google' : 'signup_google')

    await withLoading(async()=> {

      const result = await promptAsync();
      if(result.type !== 'success') {
        add({ type: 'error', text: t('flash.auth.google.error'), })(dispatch, getState)
        return
      }

      const { authentication } = result

      const response = await fetch(api.auth.google(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...authentication,
          orgId,
          inviteId,
          isLogin,
        }),
      } )

      const json = await response.json()
      const { jwt } = json
      await setAuth(jwt)(dispatch, getState)

      navigation.reset({ index: 0, routes: [{ name: 'Home' }] })
    })
  }

  return (
    <Button testID='button-google'
      onPress={handlePress}
      icon={<Image source={googleIcon} style={{ width: 25, height: 24 }} />}
      label={isLogin ? t('auth.google.login') : t('auth.google.signup')}
      loading={loading}
    />
  )

}

export default AuthGoogleButton
