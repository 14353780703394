import useAuth from '@/hooks/useAuth'
import { useEffect } from 'react'
import { HomeProps } from '@/navigation/types'
import CollectionShow from '@/components/collections/Show'
import PageWithDrawer from '@/components/layouts/PageWithDrawer'
import { defaultCollection } from '@/features/entities/selectors'
import { useSelector } from '@/store'

const Home = ({
  navigation,
}: HomeProps) => {

  const { isLoggedIn, loaded } = useAuth()
  const collection = useSelector(defaultCollection)
  const collectionId = collection?.id

  useEffect(()=> {
    if (!isLoggedIn && loaded) {
      navigation.reset({ index: 0, routes: [{ name: 'Landing' }] })
    }
  }, [loaded, isLoggedIn])

  if(!loaded || !isLoggedIn) return null

  return (
    <>
      <PageWithDrawer>
        {collectionId ? (
          <CollectionShow collectionId={collectionId} />
        ) : null}
      </PageWithDrawer>
    </>
  )

}

export default Home
