import { useSelector, RootState } from '@/store'
import type { Name, EntityMap } from '@/features/entities'

// Helper type to extract entity type from Entities
type EntityType<T extends Name> = EntityMap[T]

// Helper type to extract attribute key from entity type
type Attribute<T extends Name> = keyof EntityType<T>

export const hasSameIds = (a: string[], b: string[]) => {
  if (a.length !== b.length) return false
  return a.every(id => b.includes(id))
}

const useEntityValue = <T extends Name, K extends Attribute<T>>(
  entityName: T,
  entityId: string | undefined,
  attributeName: K,
  compareFn?: (a: any, b: any) => boolean
): EntityType<T>[K] | undefined => {
  return useSelector((state: RootState) => {
    if (!entityId) return undefined

    const entity = state.entities?.[entityName]?.[entityId] as unknown as EntityType<T>
    if (!entity) return undefined

    const value = entity[attributeName]

    if(value && typeof value === 'object' && !compareFn){
      console.warn(`useEntityValue: ${entityName}.${String(attributeName)} is typeof object. Be aware of performance implications.`)
      console.warn(value)
    }

    return value
  })
}

export default useEntityValue
