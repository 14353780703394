import { FragmentId } from '@/types'
import FragmentUpdateForm from '@/components/fragments/UpdateForm'

const FragmentItem = ({
  fragmentId,
}: {
  fragmentId: FragmentId
})=> {

  return(
    <FragmentUpdateForm
      fragmentId={fragmentId}
    />
  )
}

export default FragmentItem
