import SmallButton from '@/components/SmallButton'

import { OrgId } from '@/types'
import { useTranslation } from '@/i18n'
import { create } from '@/features/accessTokens'
import { useStore, useSelector, RootState } from '@/store'
import { orgAccessTokenIds } from '@/features/entities/selectors'
import useLoading from '@/hooks/useLoading'
import AccessTokenForm from '@/components/accessTokens/Form'

const OrgAccessTokens = ({
  orgId,
}: {
  orgId: OrgId
}) => {

  const { t } = useTranslation()
  const { dispatch, getState } = useStore()
  const tokenIds = useSelector((state: RootState) => orgAccessTokenIds(state, orgId))
  const { loading, withLoading } = useLoading()

  const handleCreate = async() => {
    await withLoading(async()=>{
      await create({ orgId })(dispatch, getState)
    })
  }

  return (
    <>
      <SmallButton testID='button-create-token'
        iconName="add-outline"
        label={t('accessTokens.create.button')}
        onPress={handleCreate}
        loading={loading}
      />

      {tokenIds.map((tokenId) => (
        <AccessTokenForm key={tokenId} tokenId={tokenId} />
      ))}
    </>
  )

}

export default OrgAccessTokens
