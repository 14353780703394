import {
  TouchableOpacity as NativeTouchable,
  StyleSheet,
  GestureResponderEvent,
  TouchableOpacityProps,
} from 'react-native'

interface CustomProps extends TouchableOpacityProps {
  children?: React.ReactNode;
  disabled?: boolean;
}

const defaultOpacity = 0.6
const disabledOpacity = 0.5
const styles = StyleSheet.create({
  disabled: {
    opacity: disabledOpacity,
  },
})

const TouchableOpacity = ({
  disabled,
  onPress,
  style,
  ...rest
}: CustomProps)=> {

  const handlePress = (e: GestureResponderEvent)=> {
    if(disabled) return

    onPress?.(e)
  }

  return (
    <NativeTouchable
      onPress={handlePress}
      style={[
        disabled && styles.disabled,
        style,
      ]}
      activeOpacity={disabled ? disabledOpacity : defaultOpacity}
      {...rest}
    />
  )
}

export default TouchableOpacity
