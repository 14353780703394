import { RootState } from '@/store'
import {
  OrgId,
  Role,
  DocId,
  AccessToken,
} from '@/types'

export const defaultCollection = (state: RootState) => {
  const collections = state.entities?.collections
  if(!collections) return null

  const ids = Object.keys(collections)
  if(ids.length === 0) return null
  return collections[ids[0]]
}

export const orgRole = (state: RootState, id: OrgId): Role | null => {
  const org = state.entities?.orgs?.[id]
  if(!org) return null

  return org.role
}

export const orgAccessTokenIds = (state: RootState, id: OrgId): string[] => {
  const org = state.entities?.orgs?.[id]
  if(!org?.accessTokens) return []

  return org.accessTokens
}

export const accessToken = (state: RootState, id: string): AccessToken | null => {
  const token = state.entities?.accessTokens?.[id]
  if(!token) return null

  return token
}

export const selectOrgIds = (state: RootState): OrgId[] => {
  const orgs = state.entities?.orgs
  if(!orgs) return []

  return Object.keys(orgs)
}

export const selectFragments = (state: RootState, docId: DocId) => {
  const fragmentIds = state.entities?.docs?.[docId]?.fragments
  if(!fragmentIds) return []

  return fragmentIds.map(id => state.entities?.fragments?.[id])
}

export const selectDocCollectionName = (state: RootState, docId: DocId) => {
  const collectionId = state.entities?.docs?.[docId]?.collectionId
  if(!collectionId) return null

  return state.entities?.collections?.[collectionId]?.name
}
