import {
  ActivityIndicator as NativeIndicator,
  ViewStyle
} from 'react-native'
import { colors } from '@/styles/theme'

const ActivityIndicator = ({
  color = colors.primary[700],
  style,
  size,
}: {
  color?: string
  style?: ViewStyle
  size?: 'small' | 'large'
}) => {

  return (
    <NativeIndicator
      color={color}
      style={style}
      size={size}
    />
  )
}

export default ActivityIndicator
