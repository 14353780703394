import { useNavigation as nativeUseNavigation } from '@react-navigation/native'

export { NavigationContainer } from '@react-navigation/native';
export { useRoute } from '@react-navigation/native';
import type { RouteName } from '@/navigation/types'

type ResetParams = {
  index: number
  routes: {
    name: RouteName,
    params?: any,
  }[]
}

export const useNavigation = ()=> {

  const navigation = nativeUseNavigation()

  const navigate = (name: RouteName, params?: any) => {
    // @ts-ignore
    navigation.navigate(name, params)
  }

  const reset = (params: ResetParams) => {
    // @ts-ignore
    navigation.reset(params)
  }

  return {
    ...navigation,
    reset,
    navigate,
  }

}
