import {
  TextInput as NativeInput,
  StyleSheet,
  View,
  ViewStyle,
  TextStyle,
} from 'react-native'
import Text, { styles as textStyles } from '@/components/Text'
import { space, colors } from '@/styles/theme'
import { useTranslation } from '@/i18n'

const styles = StyleSheet.create({
  default: {
    backgroundColor: colors.contrast,
    borderWidth: 1,
    borderRadius: 6,
    padding: space[1],
    borderColor: colors.grey[300],
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  required: {
    marginLeft: space[0.5],
    color: colors.red[600],
  },
  optional: {
    marginLeft: space[0.5],
    color: colors.grey[500],
  },
})

const TextInput = ({
  label,
  value,
  onChangeText,
  onPressEnter,
  multiline,
  numberOfLines,
  style,
  inputStyle,
  placeholder,
  selectTextOnFocus,
  right,
  required,
  optional,
}:{
  testID?: string,
  label?: string
  value: string
  onChangeText: (text: string) => void
  onPressEnter?: () => void
  multiline?: boolean
  numberOfLines?: number
  style?: ViewStyle
  inputStyle?: TextStyle
  placeholder?: string | undefined | null
  selectTextOnFocus?: boolean
  right?: React.ReactNode
  required?: boolean
  optional?: boolean
}) => {

  let nLines = numberOfLines ? numberOfLines : 1
  if(multiline) {
    nLines = 5
  }

  const { t } = useTranslation()

  return (
    <>
      {label && (
        <Text label style={{
          marginBottom: space.xs,
          marginTop: space.md,
        }}>
          {label}
          {required && (
            <Text caption style={styles.required}>*</Text>
          )}
          {optional && (
            <Text caption style={styles.optional}> ({t('general.optional')})</Text>
          )}
        </Text>
      )}
      <View style={[styles.container, style]}>
        <NativeInput
          multiline={multiline}
          numberOfLines={nLines}
          value={value}
          onChangeText={onChangeText}
          onSubmitEditing={onPressEnter}
          selectTextOnFocus={selectTextOnFocus}
          placeholder={placeholder || ''}
          placeholderTextColor={colors.grey[500]}
          style={[
            textStyles.text,
            styles.default,
            inputStyle,
          ]}
        />
        {right}
      </View>
    </>
  )

}

export default TextInput
