import Link from '@/components/Link'
import Text from '@/components/Text'
import View from '@/components/View'
import FontIcon from '@/components/FontIcon'
import { useTranslation } from '@/i18n'
import { colors, space } from '@/styles/theme'
import { StyleSheet } from 'react-native'
import { A } from '@expo/html-elements';

const styles = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: space[1],
  },
  icon: {
    marginRight: space[1],
  },
  text: {
    color: colors.grey[900],
  },
  child: {
    paddingLeft: space[1],
    marginTop: space[1],
    marginLeft: space[1],
    borderLeftWidth: 1,
    borderLeftColor: colors.grey[300],
    borderStyle: 'solid',
  }
})


const MenuItems = () => {

  const { t } = useTranslation()

  return(
    <>
      <View style={styles.item}>
        <FontIcon name="code-outline" style={styles.icon} />
        <Text label style={styles.text}>{t('developers.title')}</Text>
      </View>

      <View style={styles.child}>
        <Link to="/developers" style={[styles.item, { marginTop: 0 }]}>
          <FontIcon name="key-outline" style={styles.icon} />
          <Text label style={styles.text}>{t('developers.accessToken')}</Text>
        </Link>

        <A href="https://docs.tuneup.cc" style={styles.item}>
          <FontIcon name="document-text-outline" style={styles.icon} />
          <Text label style={styles.text}>{t('developers.documentation')}</Text>
        </A>
      </View>

      <Link to="/inquiry" style={styles.item}>
        <FontIcon
          name="help-circle-outline"
          style={styles.icon}
        />
        <Text label style={styles.text}>{t('inquiry.title')}</Text>
      </Link>
    </>
  )
}


export default MenuItems
