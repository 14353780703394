import { Text as NativeText, StyleSheet, TextStyle } from 'react-native'
import { fontSize, colors } from '@/styles/theme'
import Platform from '@/lib/Platform'

const defaultFonts = "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;"

// Don't directly apply fontFamily to the Text component, it doesn't work
export const styles = StyleSheet.create({
  text: {
    fontSize: fontSize.body1,
    fontWeight: '400',
    fontFamily: `NotoSans_400Regular, ${defaultFonts}`,
    color: colors.grey[900],
  },
  textLight: {
    fontWeight: '300',
    fontFamily: `NotoSans_300Light, ${defaultFonts}`,
    color: colors.grey[900],
  },
  textBold: {
    fontWeight: '600',
    fontFamily: `NotoSans_600SemiBold, ${defaultFonts}`,
    color: colors.grey[900],
  },
  caption: {
    fontSize: fontSize.caption,
    color: colors.grey[600],
  },
  label: {
    fontSize: fontSize.body2,
    color: colors.grey[600],
  },
  link: {
    textDecorationLine: 'underline',
    textDecorationColor: colors.grey[600],
    textDecorationStyle: 'solid',
  },
  body2: {
    fontSize: fontSize.body2,
    color: colors.grey[900],
  },
  h1: {
    fontSize: fontSize.h1,
    fontWeight: '600',
    fontFamily: `NotoSans_600SemiBold, ${defaultFonts}`,
    color: colors.grey[900],
  },
  h2: {
    fontSize: fontSize.h2,
    fontWeight: '600',
    fontFamily: `NotoSans_600SemiBold, ${defaultFonts}`,
    color: colors.grey[900],
  },
  h3: {
    fontSize: fontSize.h3,
    fontWeight: '600',
    fontFamily: `NotoSans_600SemiBold, ${defaultFonts}`,
    color: colors.grey[900],
  },
  h4: {
    fontSize: fontSize.h4,
    fontWeight: '400',
    fontFamily: `NotoSans_400Regular, ${defaultFonts}`,
    color: colors.grey[900],
  },
  inverted: {
    color: colors.contrastText,
  },
})

export type TextOptions = {
  style?: any
  bold?: boolean
  light?: boolean
  caption?: boolean
  label?: boolean
  link?: boolean
  body2?: boolean
  h1?: boolean
  h2?: boolean
  h3?: boolean
  h4?: boolean
  preLine?: boolean // supported on web only
  inverted?: boolean
}

export type TextProps = TextOptions & {
  children: any
}

const Text = ({
  children,
  style,
  bold,
  light,
  caption,
  label,
  link,
  body2,
  h1,
  h2,
  h3,
  h4,
  preLine,
  inverted,
}:TextProps) => {

  return (
    <NativeText style={[
      styles.text,
      body2 && styles.body2,
      h1 && styles.h1,
      h2 && styles.h2,
      h3 && styles.h3,
      h4 && styles.h4,
      link && styles.link,
      bold && styles.textBold,
      light && styles.textLight,
      caption && styles.caption,
      label && styles.label,
      inverted && styles.inverted,
      (preLine && Platform.OS === 'web') ? { whiteSpace: 'pre-line' } : {},
      style
    ]}>
      {children}
    </NativeText>
  )

}

export default Text

