import {
  Image as NativeImage,
  ImageProps,
} from 'react-native'

const Image = ({
  source,
  style,
  resizeMode,
}: {
  source: any,
  style?: any,
  resizeMode?: ImageProps['resizeMode'],
}) => {

  return (
    <NativeImage
      source={source}
      style={{
        width: '100%',
        height: '100%',
        ...style,
      }}
      resizeMode={resizeMode || 'contain'}
    />
  )
}

export default Image
