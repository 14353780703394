import { useState, useEffect } from 'react'

const useTypingEffect = (
  text: string,
  ms: number,
  hasShown: boolean,
) => {
  const [displayedText, setDisplayedText] = useState("")
  const [currentIndex, setCurrentIndex] = useState(0)
  const [hasStarted, setHasStarted] = useState(false)

  useEffect(() => {
    if (!hasStarted && hasShown) setHasStarted(true)

    if (hasStarted && currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prevDisplayedText) => prevDisplayedText + text.charAt(currentIndex))
        setCurrentIndex((prevIndex) => prevIndex + 1)
      }, ms)
      return () => clearTimeout(timeout)
    }
  }, [currentIndex, text, ms, hasShown, hasStarted])

  return displayedText

}

export default useTypingEffect
