import { ViewStyle } from 'react-native'
import { fontSize, space, colors } from '@/styles/theme'
import View from '@/components/View'
import Text from '@/components/Text'
import Icon from '@/components/Icon'
import ActivityIndicator from '@/components/ActivityIndicator'
import { useState } from 'react'
import AdaptiveInput from '@/components/AdaptiveInput'

const TitleInput = ({
  value,
  onChangeText,
  onPressEnter,
  placeholder,
  caption,
  loading,
  onPressCtrlEnter,
  style,
}: {
  value: string
  onChangeText: (text: string) => void
  onPressEnter?: () => void
  placeholder?: string | undefined | null
  caption?: string | undefined | null
  testID?: string
  loading?: boolean
  multiline?: boolean
  autoAdjustHeight?: boolean
  onPressCtrlEnter?: () => void
  style?: ViewStyle
}) => {

  const [showCaption, setShowCaption] = useState(false)

  const handleFocus = (e: any)=> {
    e.target.style.outline = 'none'
    setShowCaption(true)
  }

  const handleKeyPress = (e: any)=> {
    if(e.key === 'Enter' && e.ctrlKey) {
      if(onPressCtrlEnter) onPressCtrlEnter()
    }
  }

  return (
    <View>

      <View>
        {/* Keep loading and input aligned */}
        <AdaptiveInput testID='input-title'
          onKeyPress={handleKeyPress}
          placeholder={placeholder || ''}
          value={value}
          onChangeText={onChangeText}
          onPressEnter={onPressEnter}
          onFocus={handleFocus}
          onBlur={()=> setShowCaption(false)}
          style={style}
        />

        {loading ? (
          <ActivityIndicator
            style={{
              position: 'absolute',
              right: space[1],
              top: 0,
              bottom: 0,
            }}
            size='small'
            color={colors.grey[600]}
          />
        ) : null}
      </View>

      {caption ? (
        <View style={{
          marginTop: space[1],
          height: fontSize.caption,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}>

          {showCaption ? (
            <>
              <Icon
                icon='arrow-turn-down'
                size={fontSize.caption}
                color={colors.grey[600]}
                style={{transform: [{rotate: '90deg'}]}}
              />
              <Text caption style={{ marginLeft: space[1] / 2 }}>
                {caption}
              </Text>
            </>
          ) : null}

        </View>
      ) : null}

    </View>
  )
}

export default TitleInput
