import { DraftFragment } from './types'
import init, { toDocument } from './init'
import { htmlToStructure } from './markdown'

export const takeTitle = (html: string): string | undefined => {
  const document = toDocument(html)
  return document.title
}

export const htmlToFragments = (html: string): DraftFragment[] => {

  // remove any comments
  const cleaned = html.replace(/<!--[^]*?-->/g, '')
    // remove any style tags
    .replace(/<style[^]*?<\/style>/g, '')
    // remove any script tags
    .replace(/<script[^]*?<\/script>/g, '')
    // remove any noscript tags
    .replace(/<noscript[^]*?<\/noscript>/g, '')
    // remove any input tags
    .replace(/<input[^]*?>/g, '')
    // remove any img tags
    .replace(/<img[^]*?>/g, '')

  //console.log('htmlToFragments#cleaned')
  const text = init(cleaned)

  const structure = htmlToStructure(text)
  return structure
}

