import PageWithDrawer from '@/components/layouts/PageWithDrawer'
import ScrollablePage from '@/components/layouts/ScrollablePage'
import Card from '@/components/layouts/Card'
import OrgAccessTokens from '@/components/orgs/AccessTokens'

import Text from '@/components/Text'

import { space } from '@/styles/theme'
import { useTranslation } from '@/i18n'
import { selectOrgIds } from '@/features/entities/selectors'
import { useSelector } from '@/store'

const DevelopersPage = () => {

  const { t } = useTranslation()
  const orgIds = useSelector(selectOrgIds)

  return(
    <PageWithDrawer>
      <ScrollablePage>

        <Text h2 style={{ marginBottom: space[2] }}>
          {t('developers.accessToken')}
        </Text>

        {orgIds.map((orgId)=> (
          <Card key={orgId}>
            <Text light>{t('orgs.list.title')} ID: {orgId}</Text>
            <OrgAccessTokens orgId={orgId} />
          </Card>
        ))}
      </ScrollablePage>
    </PageWithDrawer>
  )
}

export default DevelopersPage
