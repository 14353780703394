import DropDownPicker from '@/components/lib/DropDownPicker'
import { CollectionId } from '@/types'
import { useState, useEffect } from 'react'
import { RootState, useSelector, useStore } from '@/store'
import { useTranslation } from '@/i18n'
import { View, StyleSheet } from 'react-native'
import Button from '@/components/Button'
import { space } from '@/styles/theme'
import { update } from '@/features/collections'
import { NormalizedCollection } from '@/features/entities'
import useLoading from '@/hooks/useLoading'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
})

const collectionsToItems = (
  unnamed: string,
  collections?: (NormalizedCollection|undefined)[],
) => {
  if(!collections) return []

  return collections.map(collection => ({
    label: collection?.name || unnamed,
    value: collection?.id,
  })) as CollectionItem[]
}

type CollectionItem = {
  label: string, // collection name
  value: CollectionId,
}

const CollectionPicker = ({
  collectionId,
}: {
  collectionId: CollectionId
}) => {

  const { t } = useTranslation()
  const { dispatch, getState } = useStore()
  const { loading, withLoading } = useLoading()

  const sources = useSelector((state: RootState) => {
    const collection = state.entities?.collections[collectionId]
    return collectionsToItems(
      t('collections.item.unnamed'),
      collection?.sourceCollections,
    )
  })

  const collections = useSelector((state: RootState) => {
    const all = Object.values(state.entities?.collections || {})
    const items = collectionsToItems(
      t('collections.item.unnamed'),
      all,
    )
    const allItems = [...items, ...sources]
    const uniqueItems = allItems.filter((item, index, self) => self.findIndex(i => i.value === item.value) === index)
    return uniqueItems.filter(collection => collection.value && collection.value !== collectionId)
  })

  // items are the base list of collections to pick from
  useEffect(()=> {
    setItems(collections)
  }, [collections.length])

  // values are the list of selected collections
  useEffect(()=> {
    setValues(sources.map(source => source.value))
  }, [sources.length])

  const [items, setItems] = useState<CollectionItem[]>([]);
  const [values, setValues] = useState<CollectionId[]>([]);

  const submit = async()=> {
    await withLoading(async()=> {
      await update(collectionId, { sourceCollectionIds: values })(dispatch, getState)
    })
  }

  const isChanged = sources.length !== values.length || sources.some(source => !values.includes(source.value))

  return (
    <View style={styles.container}>
      <DropDownPicker testID='picker-collections'
        // items are the base list of collections to pick from
        items={items}
        setItems={setItems}
        // values are the list of selected collections
        value={values}
        setValue={setValues}
      />

      {isChanged ? (
        <Button small thin variant='contained'
          testID='button-submit'
          label={t('general.save')}
          style={{ marginLeft: space[1], height: 32, }}
          onPress={submit}
          loading={loading}
        />
      ) : null}
    </View>
  )
}

export default CollectionPicker
