import { FragmentId } from '@/types'
import InputArea from '@/components/fragments/InputArea'
import OutputArea from '@/components/fragments/OutputArea'
import { space, colors } from '@/styles/theme'
import { useState, useEffect } from 'react'
import { update, remove, poll, UpdateParams } from '@/features/fragments'
import { useStore } from '@/store'
import { useTranslation } from '@/i18n'
import View from '@/components/View'
import useLoading from '@/hooks/useLoading'
import SmallButton from '@/components/SmallButton'
import ReferencesButton from '@/components/fragments/ReferencesButton'
import { StyleSheet } from 'react-native'
import Alert from '@/lib/Alert'
import useUnsavedChangesWarning from '@/hooks/useUnsavedChangesWarning'
import useEntityValue from '@/hooks/useEntityValue'
import usePolling from '@/hooks/usePolling'

const styles = StyleSheet.create({
  outputButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: space[1],
  },
  references: { marginLeft: space[1], },
  continue: { marginLeft: space[1], },
  check: { marginLeft: space[1], },
  unsaved: {
    backgroundColor: colors.amber[30],
  }
})

const DeleteButton = ({
  fragmentId,
}: {
  fragmentId: FragmentId
})=> {

  const { loading, withLoading } = useLoading()
  const { dispatch, getState } = useStore()
  const { t } = useTranslation()

  const handleRemove = async()=> {
    await withLoading(async()=> {
      await remove(fragmentId)(dispatch, getState)
    })
  }

  const onPress = async()=> {
    await Alert.alert(
      t('alert.confirmRemove.title'),
      t('alert.confirmRemove.message') || '',
      [
        { text: t('alert.cancel') || '', style: 'cancel', },
        { text: t('alert.confirmRemove.ok') || '', onPress: handleRemove, },
      ]
    )
  }

  return (
    <SmallButton
      loading={loading}
      onPress={onPress}
      iconName='trash-outline'
    />
  )

}

const FragmentUpdateForm = ({
  fragmentId,
}: {
  fragmentId: FragmentId
})=> {

  const { dispatch, getState } = useStore()
  const { t } = useTranslation()

  // <InputOutput>
  const defaultInput     = useEntityValue('fragments', fragmentId, 'input')
  const defaultOutput    = useEntityValue('fragments', fragmentId, 'output')

  const [input, setInput] = useState(defaultInput || '')
  const [output, setOutput] = useState(defaultOutput || '')

  useEffect(()=> setInput(defaultInput || ''), [defaultInput])
  useEffect(()=> setOutput(defaultOutput || ''), [defaultOutput])

  // warn before leave
  const [inputUnsaved,  setInputUnsaved] = useUnsavedChangesWarning()
  const [outputUnsaved, setOutputUnsaved] = useUnsavedChangesWarning()

  const isInputUpdated  = input  !== defaultInput
  const isOutputUpdated = output !== defaultOutput

  const onInputChange = (value: string)=> {
    setInput(value)
    setInputUnsaved(value !== defaultInput)
  }

  const onOutputChange = (value: string)=> {
    setOutput(value)
    setOutputUnsaved(value !== defaultOutput)
  }
  // </InputOutput>

  const defaultIsChecked = useEntityValue('fragments', fragmentId, 'isChecked')
  const [isChecked, setIsChecked] = useState(defaultIsChecked || false)

  const updatingFrom = useEntityValue('fragments', fragmentId, 'updatingFrom')
  const [loading, setLoading] = useState(false)
  const { loading: polling } = usePolling(
    updatingFrom,
    async()=> await poll(fragmentId)(dispatch, getState),
  )

  const handleSubmit = async()=> {
    setLoading(true)
    await handleUpdate()
    setLoading(false)
  }

  const handleContinue = async()=> {
    setLoading(true)
    await handleUpdate({ continue: true })
    setLoading(false)
  }

  const handleUpdate = async(params?: UpdateParams)=> {
    const id = fragmentId
    if(!id) return

    const isManuallyEdited = isOutputUpdated || isInputUpdated

    const json = await update(id, {
      input,
      output,
      isChecked: isManuallyEdited ? true : !isChecked,
      ...params
    })(dispatch, getState)
    if(!json.fragment) return

    setIsChecked(json.fragment.isChecked)
    setInput(json.fragment.input)
    setOutput(json.fragment.output)
    setInputUnsaved(false)
    setOutputUnsaved(false)
  }

  let caption = t('fragments.form.update.input.caption')
  if(!output) {
    caption = t('fragments.form.input.caption')
  }

  let label = t('fragments.form.button.useForGenerate')
  const isUsedForGenerate = isChecked && !isOutputUpdated && !isInputUpdated
  if(isUsedForGenerate) {
    label = t('fragments.form.button.usedForGenerate')
  }
  if(isOutputUpdated) {
    label = t('fragments.form.button.update')
  }

  return (
    <>
      <View style={{ marginBottom: space[2] }}>
        <InputArea
          value={input}
          onChangeText={onInputChange}
          onPressEnter={handleSubmit}
          loading={loading || polling}
          caption={caption}
          inputStyle={inputUnsaved ? styles.unsaved : undefined}
        />
        {!output ? (
          <SmallButton
            label={t('fragments.form.button.regenerate') || ''}
            loading={loading || polling}
            onPress={handleSubmit}
            iconName='color-wand-outline'
          />
        ): null}
      </View>

      <OutputArea
        value={output}
        onChangeText={onOutputChange}
        inputStyle={outputUnsaved ? styles.unsaved : undefined}
      />

      <View style={styles.outputButtons}>
        <DeleteButton fragmentId={fragmentId} />
        {output ? (
          <>
            <ReferencesButton style={styles.references} fragmentId={fragmentId} />
            <SmallButton testID='button-continue'
              label={t('fragments.form.button.continue') || ''}
              loading={loading || polling}
              onPress={handleContinue}
              iconName='caret-forward-circle-outline'
              style={styles.continue}
            />
            <SmallButton testID='button-check'
              label={label}
              loading={loading || polling}
              onPress={handleSubmit}
              iconName={'checkmark-outline'}
              color={isUsedForGenerate ? colors.green[600] : undefined}
              style={styles.check}
            />
          </>
        ) : null}
      </View>

    </>
  )
}

export default FragmentUpdateForm
