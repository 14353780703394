import { enableAllPlugins } from 'immer'
import { normalize, schema } from 'normalizr'
import { ClearAction, AppDispatch } from '@/store'
import produce from "immer"
import {
  FragmentId,
  CollectionId,
  AnyId,
  AccessTokenId,
  Doc,
  Fragment,
  Org,
  Collection,
  AccessToken,
  Keyword,
  Draft,
  DraftId,
} from '@/types'
import log from '@/log'

enableAllPlugins()

export type Name = 'fragments' |
  'docs' |
  'orgs' |
  'collections' |
  'accessTokens' |
  'keywords' |
  'drafts'
type Appendable = 'fragments' | 'docs' | 'collections' | 'accessTokens'

export type NormalizedFragment = Fragment

export type NormalizedDoc = Doc & {
  readonly fragments?: FragmentId[]
}

type DeNormalizedDoc = Doc & {
  readonly fragments?: Fragment[]
}

export type NormalizedOrg = Org & {
  readonly collections?: CollectionId[]
  readonly accessTokens?: AccessTokenId[]
}

export type DeNormalizedOrg = Org & {
  readonly collections?: Collection[]
  readonly accessTokens?: AccessToken[]
}

export type NormalizedCollection = Collection & {
  readonly id: CollectionId
}

export type NormalizedAccessToken = AccessToken

export type NormalizedKeyword = Keyword & {
  readonly drafts?: DraftId[]
}
export type DeNormalizedKeyword = Keyword & {
  readonly drafts?: Draft[]
}
export type NormalizedDraft = Draft

type Entity = NormalizedDoc |
  DeNormalizedDoc |
  NormalizedFragment |
  NormalizedOrg |
  DeNormalizedOrg |
  NormalizedCollection |
  NormalizedAccessToken |
  NormalizedKeyword |
  DeNormalizedKeyword |
  NormalizedDraft

export type EntityMap = {
  fragments: NormalizedFragment
  docs: NormalizedDoc
  orgs: NormalizedOrg
  collections: NormalizedCollection
  accessTokens: NormalizedAccessToken
  keywords: NormalizedKeyword
  drafts: NormalizedDraft
}

export type Entities = {
  readonly fragments: {
    [key: string]: NormalizedFragment | undefined
  }
  readonly docs: {
    [key: string]: NormalizedDoc | undefined
  }
  readonly orgs: {
    [key: string]: NormalizedOrg | undefined
  }
  readonly collections: {
    [key: string]: NormalizedCollection | undefined
  }
  readonly accessTokens: {
    [key: string]: NormalizedAccessToken | undefined
  }
  readonly keywords: {
    [key: string]: NormalizedKeyword | undefined
  }
  readonly drafts: {
    [key: string]: NormalizedDraft | undefined
  }
}

type SetAction = {
  type: 'entities/set'
  entities: Entities
}

type RemoveAction = {
  type: 'entities/remove'
  name: Name
  id: AnyId
}

type AppendAction = {
  type: 'entities/append'
  childName: Appendable
  childId: AnyId
  parentId: AnyId
}

type PurgeAction = {
  type: 'entities/purge'
}

type Action = SetAction | RemoveAction | AppendAction | PurgeAction | ClearAction

const types = {
  set: 'entities/set',
  remove: 'entities/remove',
  purge: 'entities/purge',
  append: 'entities/append',
} as const

const fragments = new schema.Entity('fragments')
const docs = new schema.Entity('docs')
docs.define({
  fragments: new schema.Array(fragments),
})

const collections = new schema.Entity('collections')
const accessTokens = new schema.Entity('accessTokens')
const orgs = new schema.Entity('orgs')
orgs.define({
  collections: new schema.Array(collections),
})
orgs.define({
  accessTokens: new schema.Array(accessTokens),
})

const drafts = new schema.Entity('drafts')
const keywords = new schema.Entity('keywords')
keywords.define({
  drafts: new schema.Array(drafts),
})

const schemas = {
  fragments,
  docs,
  orgs,
  collections,
  accessTokens,
  keywords,
  drafts,
}

export const append = (
  childName: Appendable,
  childId: AnyId,
  parentId: AnyId
) => {
  return (dispatch: AppDispatch) => {
    try{
      dispatch({
        type: types.append,
        childId,
        childName,
        parentId
      })
    }catch(err){
      console.error(err)
    }
  }
}

export const remove = (name: Name, id: AnyId) => {
  return (dispatch: AppDispatch) => {
    try{
      dispatch({ type: types.remove, id, name })
    }catch(err){
      console.error(err)
    }
  }
}

export const set = (name: Name, data?: Entity) => {
  return (dispatch: AppDispatch) => {
    const schema = schemas[name]
    if(!schema) throw `schema is ${schema}`

    // silentry quit if no data
    if(!data) return

    // check the name includes data.type
    if(!name.includes(data.type)) throw `entity name is ${name}, data.type is ${data.type}`
    // check for id
    if(!data.id) throw `data.id is ${data.id}`

    try{
      const { entities } = normalize(data, schema)
      dispatch({ type: types.set, entities: entities as Entities })
    }catch(err){
      log.capture(err as Error, { data, schema })
      p(err)
    }
  }
}

export const setMany = (name: Name, data?: Entity[]) => {
  return (dispatch: AppDispatch) => {
    const schema = schemas[name]
    if(!schema) throw `setMany ${name}, schema is ${schema}`
    if(!data) throw `setMany ${name}, data is ${data}`

    try{
      const { entities } = normalize(data, [schema])
      dispatch({ type: types.set, entities: entities as Entities })
    }catch(err){
      log.capture(err as Error, { data, schema })
      p(err)
    }
  }
}

export const purge = () => {
  return (dispatch: AppDispatch) => {
    try{
      dispatch({ type: types.purge })
    }catch(err){
      console.error(err)
    }
  }
}

// pre define list of relationship for `append`
// ex. docs has many fragments => fragments: should append to 'docs'
const appendTarget = {
  fragments: 'docs',
  docs: 'collections',
  orgs: null,
  collections: 'orgs',
  accessTokens: 'orgs',
}

const initialState: Entities = {
  fragments: {},
  docs: {},
  orgs: {},
  collections: {},
  accessTokens: {},
  keywords: {},
  drafts: {},
}

export const reducer = (state = initialState, action: Action) => {
  try{
    switch (action.type) {
      case types.set:
        if(!action.entities) throw `entities is ${action.entities}`
        const entities: Entities = action.entities

        return produce(state, draft => {
          // ex. ['fragments', 'docs', 'orgs', 'collections']
          const names = Object.keys(entities) as Name[]

          names.forEach(name => {
            // ex. { 'uuid-1': { ... }, 'uuid-2': { ... } }
            const update = entities[name]

            Object.keys(update).forEach(id => {
              const current = draft[name][id]
              draft[name][id] = {
                ...current,
                ...update[id]
              } as typeof current
            })
          })
        })

      case types.remove: {
        if(!action.name) throw `name is ${action.name}`
        if(!action.id) throw `id is ${action.id}`

        const name: Name = action.name
        const id = action.id
        return produce(state, draft => {
          const target = draft[name]

          // remove association
          if (name === 'collections') {
            // Assuming the action has an 'orgId' property that contains the org's ID
            const collection = target[id] as Collection
            // If the orgId is defined, remove the collection ID from the org.collections array
            const org = draft.orgs[collection.orgId];
            if (org && org.collections) {
              const index = org.collections.indexOf(id);
              if (index > -1) {
                org.collections.splice(index, 1);
              }
            }
          }

          delete target[id]
        })
      }

      case types.append: {
        if(!action.childName) throw `childName is ${action.childName}`
        if(!action.childId) throw `childId is ${action.childId}`
        if(!action.parentId) throw `parentId is ${action.parentId}`

        const name: Name = action.childName
        const to = appendTarget[name] as Name
        if(!to) throw `to is ${to}`

        const parentId = action.parentId
        const id = action.childId

        return produce(state, draft => {
          // I don't know how to type check this
          const parent: any = draft[to][parentId]
          if(!parent) throw `parent is ${parent}`

          if(!parent[name]) parent[name] = []
          parent[name].push(id)
        })

      }
      case types.purge: {
        return initialState
      }
      case 'all/clear': { return initialState }
      default:
        return state
    }
  }catch(err){
    console.error(err)
  }
}
