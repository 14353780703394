import SecureStorage from '@/lib/SecureStorage'
import { JWT } from '@/types'
import { ClearAction, AppDispatch, GetState } from '@/store'

export const setAuth = (jwt: string) => {
  return async(dispatch: AppDispatch, getState: GetState) => {
    await dispatch({ type: 'auth/set', payload: jwt })
    await SecureStorage.setItemAsync('jwt', jwt)
  }
}

export const logout = ()=> {
  return async(dispatch: AppDispatch, getState: GetState) => {
    await removeAuth()(dispatch, getState)

    dispatch({ type: 'all/clear' })
  }
}

export const removeAuth = () => {
  return async(dispatch: AppDispatch, getState: GetState) => {
    await SecureStorage.deleteItemAsync('jwt')

    dispatch({ type: 'auth/remove' })
  }
}

export const readAuth = async() => {
  return await SecureStorage.getItemAsync('jwt')
}

export const getAuth = () => {
  return async(dispatch: AppDispatch, getState: GetState): Promise<string> => {
    const jwt = await SecureStorage.getItemAsync('jwt')
    if(jwt){
      dispatch({ type: 'auth/set', payload: jwt })
    }
    return jwt || ''
  }
}

export const parse = (jwt: string): any => {
  const [header, payload, signature] = jwt.split('.')
  const parsed: JWT = JSON.parse(atob(payload))
  return parsed
}

type State = {
  jwt: string | null
}
const initialState: State = {
  jwt: null,
}

type SetAction = {
  type: 'auth/set'
  payload: string
}
type RemoveAction = {
  type: 'auth/remove'
}
type Action = SetAction | RemoveAction | ClearAction

const types = {
  set: 'auth/set',
  remove: 'auth/remove',
} as const

export const reducer = (state = initialState, action: Action) => {

  switch(action.type){
    case types.set: {
      return {
        ...state,
        jwt: action.payload,
      }
    }
    case types.remove: {
      return {
        ...state,
        jwt: null,
      }
    }

    case 'all/clear': { return initialState }
    default: { return state }
  }
}

