import Link from '@/components/Link'
import Text from '@/components/Text'
import { Draft } from '@/types'
import { View } from 'react-native'
import { useTranslation } from '@/i18n'
import { StyleSheet } from 'react-native'
import { space, card, colors } from '@/styles/theme'
import useEntityValue from '@/hooks/useEntityValue'
import FontIcon from '@/components/FontIcon'
import ResponsiveBox from '@/components/ResponsiveBox'

const styles = StyleSheet.create({
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: space[2],
    padding: space[2],
    rowGap: space[1],
    ...card.listItem,
  },

  titleLink: {
    flex: 1,
    minWidth: '50%',
    display: 'flex',
  },

  title: {
    marginLeft: space[1]
  },

  statuses: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  status: {
    display: 'flex',
    marginLeft: space[1],
  },
  active: { color: colors.green[600] },
  inactive: { color: colors.grey[600] },
})

const ListItem = ({
  draftId,
}: {
  draftId: Draft['id']
}) => {

  const { t } = useTranslation()
  const title = useEntityValue('drafts', draftId, 'title')
  const docId = useEntityValue('drafts', draftId, 'docId')
  const outline = useEntityValue('drafts', draftId, 'outline')

  const outlineStatus = outline ? styles.active : styles.inactive
  const docStatus     = docId ? styles.active : styles.inactive

  return (
    <ResponsiveBox style={styles.card}>

      <Link to={`/drafts/${draftId}`} style={styles.titleLink}>
        <FontIcon name='document-text-outline' />
        <Text body2 style={styles.title}>{title}</Text>
      </Link>

      <View style={styles.statuses}>
        <Link to={`/drafts/${draftId}`} style={styles.status}>
          <FontIcon name='checkmark-circle-outline' style={outlineStatus} />
          <Text body2 style={outlineStatus}>
            {t('drafts.outline.label')}
          </Text>
        </Link>

        <Link to={`/docs/${docId}`} style={styles.status}>
          <FontIcon name='checkmark-circle-outline' style={docStatus} />
          <Text body2 style={docStatus}>
            {t('drafts.docId.label')}
          </Text>
        </Link>
      </View>

    </ResponsiveBox>
  )

}

export default ListItem
