import PageWithDrawer from '@/components/layouts/PageWithDrawer'
import { CollectionKeywordsProps } from '@/navigation/types'
import useEntityValue from '@/hooks/useEntityValue'
import Breadcrumbs from '@/components/Breadcrumbs'
import KeywordForm from '@/components/keywords/Form'
import KeywordsList from '@/components/keywords/List'
import { space } from '@/styles/theme'
import { StyleSheet, View } from 'react-native'
import { useTranslation } from '@/i18n'
import Text from '@/components/Text'

const styles = StyleSheet.create({
  page: {
    padding: space[3]
  },
  item: {
    paddingLeft: space[3],
    paddingRight: space[3],
  },
  listHeader: {
    marginBottom: space[1]
  },
  form: {
    marginBottom: space[3]
  }
})

const KeywordsPage = ({
  route,
}: CollectionKeywordsProps) => {

  const { t } = useTranslation()
  const { collectionId } = route.params
  const collectionName = useEntityValue('collections', collectionId, 'name')

  const list = [
    { label: collectionName, to: `/collections/${collectionId}` },
    { label: t('keywords.list.title') },
  ]

  return (
    <PageWithDrawer>
      <KeywordsList
        collectionId={collectionId}
        style={styles.page}
        ListHeaderComponent={(
          <>
            <Breadcrumbs list={list} />

            <View style={styles.form}>
              <KeywordForm collectionId={collectionId} />
            </View>

            <Text caption style={styles.listHeader}>{t('keywords.list.title')}</Text>
          </>
        )}
      />
    </PageWithDrawer>
  )
}


export default KeywordsPage
