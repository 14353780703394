import PageContainer from '@/components/layouts/PageContainer'
import TextInput from '@/components/TextInput'
import Button from '@/components/Button'
import { useState } from 'react'
import { StyleSheet } from 'react-native'
import { useTranslation } from '@/i18n'
import { space } from '@/styles/theme'
import { post } from '@/features/requests'
import { useStore } from '@/store'
import api from '@/api'
import useLoading from '@/hooks/useLoading'
import Alert from '@/components/Alert'
import { add } from '@/features/flash'

const styles = StyleSheet.create({
  container: {
    padding: space[2],
  },
  input: {
    marginTop: space[1],
    marginBottom: space[1],
  },
  button: {
    alignSelf: 'flex-end',
  }
})

const InquiryPage = () => {

  const { t } = useTranslation()
  const [body, setBody] = useState('')
  const [email, setEmail] = useState('')
  const { dispatch, getState } = useStore()
  const { loading, withLoading } = useLoading()

  const onSubmit = async () => {
    await withLoading(async()=> {
      const response = await post(api.inquiries.create(), {
        body,
        email,
      })(dispatch, getState)
      if(response.ok) {
        setBody('')
        add({ type: 'success', text: t('inquiry.sent') })(dispatch, getState)
      }
    })
  }

  return (
    <PageContainer style={styles.container}>

      <Alert type='info'
        title={t('inquiry.title')}
        description={t('inquiry.alert')}
      />

      <TextInput optional
        label={t('inquiry.email.label')}
        placeholder={t('inquiry.email.placeholder')}
        value={email}
        onChangeText={setEmail}
        style={styles.input}
      />

      <TextInput multiline
        placeholder={t('inquiry.placeholder')}
        value={body}
        onChangeText={setBody}
        style={styles.input}
      />

      <Button small variant='contained'
        label={t('inquiry.submit')}
        onPress={onSubmit}
        style={styles.button}
        disabled={!body}
        loading={loading}
      />
    </PageContainer>
  )

}

export default InquiryPage;
