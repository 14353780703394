import { TextInput, ViewStyle } from 'react-native'
import { useState } from 'react'
import { colors, space } from '@/styles/theme'
import { StyleSheet } from 'react-native'
import { styles as textStyles } from '@/components/Text'
import Platform from '@/lib/Platform'

const minHeight = 40

const styles = StyleSheet.create({
  text: {
    ...textStyles.text,
    borderColor: 'transparent',
    padding: space[2],
    backgroundColor: 'white',
    borderRadius: space[1],
    ...Platform.select({
      web: { outlineStyle: 'none' }
    }),
    minHeight,
  }
})

const AdaptiveInput = ({
  value,
  onChangeText,
  placeholder,
  onPressEnter,
  onFocus,
  onBlur,
  onKeyPress,
  style,
  disableAdjustHeight,
}: {
  value: string
  onChangeText: (text: string) => void
  onPressEnter?: () => void
  placeholder?: string | undefined | null
  disableAdjustHeight?: boolean
  onPressCtrlEnter?: () => void
  style?: ViewStyle
  onFocus?: (e: any) => void
  onBlur?: (e: any) => void
  onKeyPress?: (e: any) => void
  testID?: string
}) => {

  const [height, setHeight] = useState(minHeight)

  const onContentSizeChange = (event: any)=> {
    if(disableAdjustHeight) return

    setHeight(event.nativeEvent.contentSize.height)
  }

  const adjustTextInputSize = (event: any) => {
    if(disableAdjustHeight) return
    const el = event?.target || event?.nativeEvent?.target;
    if (el) {
      el.style.height = 0;
      const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight;
      el.style.height = `${newHeight}px`;
    }
  }

  return(
    <TextInput testID='input-title'
      multiline={onPressEnter ? false : true}
      onChange={adjustTextInputSize}
      onContentSizeChange={onContentSizeChange}

      onKeyPress={onKeyPress}
      placeholder={placeholder || ''}
      value={value}
      onChangeText={onChangeText}
      onSubmitEditing={onPressEnter}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholderTextColor={colors.grey[600]}
      style={[
        styles.text,
        !disableAdjustHeight && { height: height },
        style,
      ]}
    />
  )

}
export default AdaptiveInput
