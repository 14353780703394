
import Icon from '@/components/Icon'
import TouchableOpacity from '@/components/TouchableOpacity'
import { space, colors } from '@/styles/theme'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: space[2],
  }
})

const BackButton = ({
  onPress,
}: {
  onPress: ()=> void
}) => {

  return (
    <TouchableOpacity onPress={onPress}
      style={styles.container}
    >
      <Icon icon='arrow-left' size={16} />
    </TouchableOpacity>
  )

}

export default BackButton
