
import {
  View,
  StyleSheet,
} from 'react-native'
import Text from '@/components/Text'
import TextInput from '@/components/TextInput'
import FontIcon from '@/components/FontIcon'
import TouchableOpacity from '@/components/TouchableOpacity'
import { useState } from 'react'
import { useTranslation, getCurrentLocale, Locale } from '@/i18n'
import { colors, space } from '@/styles/theme'
import config from '@/config'
import { createClient } from '@tuneup/client-js'
import useLoading from '@/hooks/useLoading'
import ActivityIndicator from '@/components/ActivityIndicator'
import AutoLink from 'react-native-autolink'

const client = createClient(config.tuneup.apiKey)

const styles = StyleSheet.create({
  contanier: {
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  textInput: {
    marginTop: space[2],
  },
  input: {
    minHeight: space[7],
    borderRadius: space[4],
    marginRight: space[2],
    paddingLeft: space[3],
  },

  note: {
    marginTop: space[0.5],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  loading: {
    marginTop: space[3],
  },

  answer: {
    marginTop: space[3],
    padding: space[3],
    backgroundColor: colors.blue[50],
    borderRadius: space[3],
  },
  aText: {
    fontSize: 20,
  },
  accuracy: {
    marginBottom: space[1],
  }
})

type Collections = {
  [key in Locale]: string
}
const collections: Collections = {
  ja: 'f5101c62-8b21-497c-befc-1edf649e3b91',
  en: '3ed50ede-54e7-4d94-b09b-95924dbb630e',
}

const TuneupQA = () => {

  const { t } = useTranslation()
  const [q, setQ] = useState('')
  const [a, setA] = useState('')
  const { loading, withLoading } = useLoading()

  const submit = async() => {
    if(!q) return
    if(loading) return

    const locale = getCurrentLocale()
    const collectionId = collections[locale]

    await withLoading(async()=> {
      await client.ask({
        question: q,
        collectionId,
        onUpdate: setA,
      })
    })
  }

  return (
    <View style={styles.contanier}>
      <TextInput
        value={q}
        onChangeText={setQ}
        placeholder={t('landing.qa.placeholder')}
        style={styles.textInput}
        inputStyle={styles.input}
        right={
          <TouchableOpacity onPress={submit}>
            <FontIcon name="search-outline" size={32} color={colors.grey[900]} />
          </TouchableOpacity>
        }
        onPressEnter={submit}
      />
      <View style={styles.note}>
        <Text caption>{t('landing.qa.note')}</Text>
      </View>

      {loading ? (<ActivityIndicator style={styles.loading} />) : null}

      {a ? (
        <View style={styles.answer}>
          <View style={styles.accuracy}>
            <Text caption>{t('landing.qa.accuracy')}</Text>
          </View>
          <AutoLink text={a} style={styles.aText} />
        </View>
      ) : null}
    </View>
  )

}

export default TuneupQA
