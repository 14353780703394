import { useState, useRef, useCallback } from 'react'
import useFocusEffect from '@/hooks/useFocusEffect'
import useLoading from '@/hooks/useLoading'
import {
  DocsIndex,
  KeywordsIndex,
  NextPageKey
} from '@/types/response'
import { Doc, Keyword } from '@/types'

type Result = DocsIndex | KeywordsIndex
type Entity = Doc | Keyword

const usePaginate = (
  reader: (nextPageKey: NextPageKey)=> Promise<Result>,
  entity: 'docs' | 'keywords',
  // triggers page load with dependency change
  dependencies: string[],
)=> {

  const [ids, setIds] = useState<string[]>([])
  const nextPageKey = useRef<NextPageKey>(null)
  const isLastPage = useRef<boolean>(false)
  const { loading, withLoading } = useLoading()

  const reset = ()=> {
    setIds([])
    nextPageKey.current = null
    isLastPage.current = false
  }

  const read = async()=> {
    if(isLastPage.current) return

    await withLoading(async()=> {
      const json = await reader(nextPageKey.current)
      // @ts-ignore FIXME: maybe not taking entity
      const nextIds = json[entity].map((item: Entity)=> item.id)
      setIds(current => current.concat(nextIds))

      nextPageKey.current = json.nextPageKey
      isLastPage.current = !json.nextPageKey?.PK
    })
  }

  useFocusEffect(
    useCallback(() => {
      reset()
      read()
    }, dependencies)
  )

  return {
    ids,
    loading,
    read,
  }

}

export default usePaginate
