import TouchableOpacity from '@/components/TouchableOpacity'
import FontIcon from '@/components/FontIcon'
import { ViewStyle, StyleSheet } from 'react-native'
import { space } from '@/styles/theme'

const styles = StyleSheet.create({
  button: {
    minWidth: 40,
    minHeight: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: space[2]
  }
})

const IconButton = ({
  name,
  color,
  onPress,
  style,
  size,
}: {
  name: string
  color?: string
  onPress: () => void
  style?: ViewStyle
  size?: number
  testID?: string
}) => {

  return(
    <TouchableOpacity onPress={onPress} style={[styles.button, style]}>
      <FontIcon name={name} color={color} size={size} />
    </TouchableOpacity>
  )

}

export default IconButton
