import { update } from '@/features/docs'
import { useStore, useSelector } from '@/store'
import { DocId } from '@/types'
import SmallButton from '@/components/SmallButton'
import { useTranslation } from '@/i18n'
import useLoading from '@/hooks/useLoading'
import useEntityValue, { hasSameIds } from '@/hooks/useEntityValue'
import Alert from '@/lib/Alert'
import { selectModel } from '@/features/generate/selectors'

const DocUpdateForm = ({
  docId,
  title,
}: {
  docId: DocId
  title?: string
}) => {

  const { t } = useTranslation()
  const { dispatch, getState } = useStore()
  const { loading, withLoading } = useLoading()
  const fragments = useEntityValue('docs', docId, 'fragments', hasSameIds)
  const model = useSelector(selectModel)

  const onPress = async()=> {
    if(fragments?.length) { // any fragments
      Alert.alert(
        t('alert.confirmRegenerate.title'),
        t('alert.confirmRegenerate.message'),
        [
          { text: t('alert.cancel'), style: 'cancel' },
          { text: t('alert.confirmRegenerate.ok'), onPress: submit },
        ]
      )
    } else {
      await submit()
    }
  }

  const submit = async()=> {
    await withLoading(async()=> {
      await update(docId, {
        generateOutline: true,
        title,
        model,
      })(dispatch, getState)
    })
  }

  if(!title) return null

  return (
    <SmallButton testID='button-generate-outline'
      onPress={onPress}
      loading={loading}
      iconName='color-wand-outline'
      label={t('docs.form.generateOutline.label')}
    />
  )

}

export default DocUpdateForm
