import PageWithDrawer from '@/components/layouts/PageWithDrawer'
import { DraftProps } from '@/navigation/types'
import { StyleSheet, ScrollView, View } from 'react-native'
import { space, colors } from '@/styles/theme'
import useEntityValue from '@/hooks/useEntityValue'
import Text from '@/components/Text'
import { poll, update } from '@/features/drafts'
import { useEffect, useState } from 'react'
import { useStore, useSelector } from '@/store'
import type { Section, Draft } from '@/types'
import AdaptiveInput from '@/components/AdaptiveInput'
import SmallButton from '@/components/SmallButton'
import { useTranslation } from '@/i18n'
import ActivityIndicator from '@/components/ActivityIndicator'
import { useNavigation } from '@/navigation'
import Breadcrumbs from '@/components/Breadcrumbs'
import { selectModel } from '@/features/generate/selectors'
import GenerateOutlineButton from '@/components/drafts/GenerateOutlineButton'
import usePolling from '@/hooks/usePolling'
import PollingCard from '@/components/PollingCard'
import useUnsavedChangesWarning from '@/hooks/useUnsavedChangesWarning'
import { set } from '@/features/entities'

const styles = StyleSheet.create({
  page: {
    padding: space[3],
    backgroundColor: colors.contrast,
  },
  title: {
    marginTop: space[2],
    marginBottom: space[2]
  },
  remove: {
    alignSelf: 'flex-end'
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: space[2],
    flexWrap: 'wrap',
    rowGap: space[1]
  },
  button: {
    marginLeft: space[1]
  }
})

const Section = ({
  header,
  body,
  index,
  onUpdate,
  onRemove
}: {
  header: string
  body: string
  index: number
  onUpdate: (index: number, section: Section) => void
  onRemove: (index: number) => void
}) => {

  const { t } = useTranslation()

  return (
    <View>
      <Text caption>
        {t('drafts.section.header.label')}
      </Text>
      <AdaptiveInput testID='input-header'
        value={header}
        placeholder={header}
        onChangeText={updated => onUpdate(index, { body, header: updated })}
      />
      <Text caption>
        {t('drafts.section.body.label')}
      </Text>
      <AdaptiveInput testID='input-body'
        value={body}
        placeholder={body}
        onChangeText={updated => onUpdate(index, { header, body: updated })}
      />

      <SmallButton testID='button-remove'
        onPress={() => onRemove(index)}
        iconName='trash-outline'
        style={styles.remove}
      />
    </View>
  )
}

const DraftPage = ({
  route
}: DraftProps)=> {

  const navigation = useNavigation()
  const { draftId } = route.params
  const [submitting, setSubmitting] = useState(false)
  const { t } = useTranslation()
  const { dispatch, getState } = useStore()

  const [_anyUpdates, setAnyUpdates] = useUnsavedChangesWarning()

  // title updates
  const title = useEntityValue('drafts', route.params.draftId, 'title') || ''
  const setTitle = (updated: string) => {
    set('drafts', {
      id: route.params.draftId,
      type: 'draft',
      title: updated
    } as Draft)(dispatch)
  }
  useEffect(() =>  setTitle(title), [title])

  const onUpdateTitle = (updated: string) => {
    setTitle(updated)
    setAnyUpdates(true)
  }

  // outline updates
  const outline = useEntityValue('drafts', route.params.draftId, 'outline') || '[]'
  const [sections, setSections] = useState<Section[]>([])
  useEffect(() => {
    const converted = JSON.parse(outline)
    setSections(converted)
  }, [outline])

  const syncOutline = (newSections: Section[]) => {
    const converted = JSON.stringify(newSections)
    set('drafts', {
      id: route.params.draftId,
      type: 'draft',
      outline: converted
    } as Draft)(dispatch)
  }

  const onUpdateSection = (index: number, section: Section) => {
    const newSections = [...sections]
    newSections[index] = section
    setSections(newSections)
    syncOutline(newSections)
    setAnyUpdates(true)
  }

  const onRemoveSection = (index: number) => {
    const newSections = [...sections]
    newSections.splice(index, 1)
    setSections(newSections)
    syncOutline(newSections)
  }

  const onAddSection = () => {
    const newSections = [...sections]
    newSections.push({ header: '', body: '' })
    setSections(newSections)
    syncOutline(newSections)
  }

  // Submit and data polling
  const docId = useEntityValue('drafts', route.params.draftId, 'docId')
  const updatingFrom = useEntityValue('drafts', route.params.draftId, 'updatingFrom')
  const { loading } = usePolling(
    updatingFrom,
    async() => await poll(draftId)(dispatch, getState),
    [docId]
  )

  const model = useSelector(selectModel)

  const submit = async () => {
    setSubmitting(true)
    // POST to api to generate content
    const outline = JSON.stringify(sections)
    const data = await update(draftId,
      {
        title,
        outline,
        generateDoc: true,
        model,
      }
    )(dispatch, getState)
    setSubmitting(false)
    if(data?.draft?.docId){
      setAnyUpdates(false) // TODO: Add spec. This is difficult to write test.
      navigation.navigate('Doc', { docId: data.draft.docId })
    }
  }

  // Breadcrumbs
  const keywordId = useEntityValue('drafts', route.params.draftId, 'keywordId')
  const keywordValue = useEntityValue('keywords', keywordId, 'value') || t('keywords.item.value.label')
  const list = [
    { label: keywordValue, to: `/keywords/${keywordId}` },
    { label: title },
  ]

  // show generated outline text while polling
  const outlineText = useEntityValue('drafts', route.params.draftId, 'outlineText') || ''

  return (
    <PageWithDrawer>
      <ScrollView style={styles.page} >
        {loading && <ActivityIndicator />}
        <Breadcrumbs list={list} />

        <View style={styles.title}>
          <Text caption>
            {t('drafts.title.label')}
          </Text>
          <AdaptiveInput testID='input-draft-title'
            value={title}
            placeholder={title}
            onChangeText={onUpdateTitle}
          />
        </View>

        <PollingCard updatingFrom={updatingFrom} progress={outlineText} />

        {sections.map((section, i) => (
          <Section key={i}
            header={section.header}
            body={section.body}
            index={i}
            onUpdate={onUpdateSection}
            onRemove={onRemoveSection}
          />
        ))}

        <View style={styles.action}>
          <GenerateOutlineButton
            draftId={draftId}
            disabled={loading}
            title={title}
          />
          {sections.length > 0 ? (
            <>
              <SmallButton
                testID='button-add'
                onPress={onAddSection}
                iconName='add-outline'
                label={t('drafts.section.add')}
                style={styles.button}
              />
              <SmallButton
                testID='button-submit'
                onPress={submit}
                iconName='color-wand-outline'
                style={styles.button}
                label={t('drafts.generateDoc')}
                loading={submitting}
              />
            </>
          ) : null}
          {docId ? (
            <SmallButton
              onPress={() => navigation.navigate('Doc', { docId })}
              iconName='document-text-outline'
              style={styles.button}
              label={t('drafts.openDoc')}
            />
          ) : null}
        </View>

      </ScrollView>
    </PageWithDrawer>
  )

}

export default DraftPage
