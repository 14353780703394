import {
  ScrollView,
  StyleSheet,
  ViewStyle,
  Animated,
} from 'react-native'
import { useRef, useEffect } from 'react'
import View from '@/components/View'
import Text from '@/components/Text'
import { LinkButton } from '@/components/Button'
import Header from '@/components/layouts/Header'
import Footer from '@/components/layouts/Footer'
import Image from '@/components/Image'
import TuneupQA from '@/components/TuneupQA'
import Link from '@/components/Link'
import ExternalLink from '@/components/ExternalLink'
import FontIcon from '@/components/FontIcon'
import { LinearGradient } from 'expo-linear-gradient';

import heroImg from '@/assets/lp/bg-grad.jpg'
import fileImg from '@/assets/lp/file.png'
import dataCollectingImg from '@/assets/lp/data-collecting.png'
import dataDiscoveryImg from '@/assets/lp/data-discovery.png'
import fontImg from '@/assets/lp/font.png'
import educationImg from '@/assets/lp/education.png'

import { LandingProps } from '@/navigation/types'
import { useTranslation } from '@/i18n'
import useAuth from '@/hooks/useAuth'
import useIsMobile from '@/hooks/useIsMobile'
import { space, colors, card } from '@/styles/theme'
import useResponsiveFlex from '@/hooks/useResponsiveFlex'
import useTypingEffect from '@/hooks/useTypingEffect'
import useIntersectionObserver from '@/hooks/useIntersectionObserver'

const styles = StyleSheet.create({
  heroImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100vh',
  },
  bg: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: '100%',
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inquiry: {
    marginRight: space[2],
  },
  pricing: {
    marginRight: space[2],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  page: {
    maxWidth: '100%',
    minHeight: '100vh',
    backgroundColor: 'white',
  },
  container: {
    padding: space[2],
    width: '100%',
    maxWidth: 1080,
    alignSelf: 'center',
  },
  section: { marginTop: space[3], marginBottom: space[3] },
  tagline: { marginTop: space[2], },

  feature: {
    backgroundColor: colors.contrast,
  },
  featureAlt: {
    backgroundColor: colors.blue[10],
  },

  featureItem: {
    padding: space[2],
    paddingTop: space[6],
    paddingBottom: space[6],
    width: '100%',
    maxWidth: 1080,
    alignSelf: 'center',
  },
  featureImg: {
    flex: 1,
    padding: space[2],
    minHeight: 250,
    maxHeight: 250,
  },
  featureDetail: {
    flex: 2,
    marginLeft: space[2],
  },
  featureHeadline: {
    marginBottom: space[2],
  },

  samples: {
    display: 'flex',
    flexDirection: 'row',
    // @ts-ignore web only
    flexOverflow: 'wrap',
    marginTop: space[3],
    minHeight: '600px'
  },
  sample: {
    flex: 1,
    padding: space[2],
    backgroundColor: 'white',
    borderRadius: space[3],
    borderColor: colors.blue[10],
    borderWidth: space[2],
    borderStyle: 'solid',
  },

  qaHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  inoutLabel: {
    marginTop: space[2],
    marginBottom: space[0.5],
  },
  ctaButton: {
    ...card.button,
    minHeight: space[7],
    borderRadius: space[4],
    borderWidth: 0,
    backgroundColor: colors.deepPurple[400],
    maxWidth: 1080,
    width: '100%',
    alignSelf: 'center',
  },
  ctaButtonLabel: {
    color: colors.contrastText,
  },

  sticky: {
    // @ts-ignore web only
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: space[2],
    backgroundColor: colors.grey[100],
  },
  spacer: {
    height: 100,
  },

  extra: {
    marginLeft: space[2],
    display: 'flex',
    flexDirection: 'row',
  }

})

const Sample = ({
  label,
  input,
  output,
  style,
}: {
  label: string
  input: string
  output: string
  style: ViewStyle
})=> {

  const ref = useRef(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting

  const typed = useTypingEffect(output, 30, isVisible)

  return (
    <View style={[styles.sample, style]} ref={ref} >
      <Text h4 bold>{label}</Text>
      <Text bold style={styles.inoutLabel}>Prompt:</Text>
      <Text h4 light>{input}</Text>
      <Text bold preLine style={styles.inoutLabel}>Completion:</Text>
      <Text h4 light>{typed}</Text>
    </View>
  )
}

const Feature = ({
  headline,
  description,
  img,
  alt,
}: {
  headline: string
  description: string
  img: any
  alt?: boolean
}) => {

  const { flexDirection } = useResponsiveFlex({
    reverseColumns: !alt
  })

  return(
    <View style={[
      alt ? styles.featureAlt : styles.feature,
    ]}>
      <View style={[
        styles.featureItem,
        { flexDirection }
      ]} >
        {alt ? (
          <View style={styles.featureImg}>
            <Image source={img} />
          </View>
        ) : null}
        <View style={styles.featureDetail}>
          <Text bold h1 style={styles.featureHeadline}>
            {headline}
          </Text>
          <Text light h4>
            {description}
          </Text>
        </View>
        {!alt ? (
          <View style={styles.featureImg}>
            <Image source={img} />
          </View>
        ): null}
      </View>
    </View>
  )

}

const HeaderRight = ()=> {

  const { t } = useTranslation()

  return(
    <View style={styles.headerRight}>
      <Link to="/inquiry" style={styles.inquiry}>
        <Text bold>{t('inquiry.title')}</Text>
      </Link>
      <Link to="/auth/signup" style={styles.pricing}>
        <Text bold>{t('landing.pricing')}</Text>
        <FontIcon name='chevron-forward-outline' />
      </Link>
      <LinkButton to="/auth/signup" label={t('general.loginOrSignup')} />
    </View>
  )
}

const BGImage = ()=> {
  return(
    <View style={styles.bg}>
      <Image source={heroImg} resizeMode='cover' />
      <LinearGradient
        colors={['transparent', '#fff', '#fff']}
        style={styles.gradient}
        locations={[0, 0.7, 1]}
        start={{ x: 0, y: 0 }}
        end={{ x: 0.1, y: 1 }}
      />
    </View>
  )
}

const Landing = ({
  navigation,
}: LandingProps) => {

  const { t } = useTranslation()
  const { isLoggedIn } = useAuth()
  const isMobile = useIsMobile()
  const { flexDirection } = useResponsiveFlex()

  useEffect(()=> {
    if (isLoggedIn) {
      navigation.reset({ index: 0, routes: [{ name: 'Home' }] })
    }
  }, [isLoggedIn])

  const ctaRef = useRef(null)
  const entry = useIntersectionObserver(ctaRef, {})
  const ctaVisible = !!entry?.isIntersecting
  const footerAnim = useRef(new Animated.Value(0)).current
  const toggleStartNow = (isVisible: boolean) => {
    const toValue = isVisible ? 1 : 0
    Animated.timing(
      footerAnim,
      { toValue, duration: 250, useNativeDriver: false }
    ).start()
  }
  useEffect(()=> {
    toggleStartNow(ctaVisible)
  }, [ctaVisible])

  return (
    <View style={styles.page}>

      <ScrollView>

        <View style={styles.heroImg}>
          <BGImage />
        </View>

        <Header right={<HeaderRight />} logoColor='white' />

        <View style={[
          styles.container,
          !isMobile && { marginTop: space[10] },
        ]} >

          <Text bold style={{
            fontSize: isMobile ? 48 : 64
          }}>
            {t('landing.hero.headline')}
          </Text>

          <View style={styles.section} >
            <Text light h2>{t('landing.hero.subheadline')}</Text>

            <View style={styles.tagline} >
              <Text light h4>{t('landing.hero.tagline.part1')}</Text>
              <Text light h4>{t('landing.hero.tagline.part2')}</Text>
              <Text light h4>{t('landing.hero.tagline.part3')}</Text>
            </View>
          </View>

          <View style={styles.section} >
            <View style={{ width: 200 }}>
              <LinkButton to="/auth/signup" label={t('landing.startNow')} />
            </View>
            <View style={{ marginTop: space[2]}} >
              <Text light>{t('landing.startNowSubline')}</Text>
            </View>
          </View>
        </View>

        <Feature alt
          headline={t('landing.smallData.headline')}
          description={t('landing.smallData.description')}
          img={fileImg}
        />

        <View ref={ctaRef} >

          <Feature
            headline={t('landing.dataImport.headline')}
            description={t('landing.dataImport.description')}
            img={dataCollectingImg}
          />

          <Feature alt
            headline={t('landing.smartSearch.headline')}
            description={t('landing.smartSearch.description')}
            img={dataDiscoveryImg}
          />

          <Feature
            headline={t('landing.contentSuite.headline')}
            description={t('landing.contentSuite.description')}
            img={fontImg}
          />

          <Feature alt
            headline={t('landing.learning.headline')}
            description={t('landing.learning.description')}
            img={educationImg}
          />

          <View style={styles.feature} >
            <View style={[
              styles.container,
              styles.section, {
                paddingBottom: space[4]
              }]}
            >
              <View style={styles.section} >
                <Text bold h1>{t('landing.gpt4.headline')}</Text>
              </View>

              <View style={styles.section} >
                <Text light h2>{t('landing.gpt4.subheadline')}</Text>
              </View>

              <View style={styles.tagline} >
                <Text light h4>{t('landing.gpt4.tagline.part1')}</Text>
                <Text light h4>{t('landing.gpt4.tagline.part2')}</Text>
              </View>
            </View>
          </View>

          <View style={styles.featureAlt} >
            <View style={[styles.container, styles.section]} >
              <Text bold h1>{t('landing.samples.headline')}</Text>

              <View style={[
                styles.samples,
                { flexDirection },
                ]} >
                <Sample
                  label={t('landing.samples.with')}
                  input={t('landing.samples.input')}
                  output={t('landing.samples.output')}
                  style={{ marginRight: isMobile ? 0 : space[2] }}
                />

                <Sample
                  label={t('landing.samples.without')}
                  input={t('landing.samples.input')}
                  output={t('landing.samples.outputWithout')}
                  style={{ marginLeft: isMobile ? 0 : space[2] }}
                />
              </View>

            </View>
          </View>

        </View>

        <View style={styles.feature} >
          <View style={[styles.container, styles.section]} >
            <View style={styles.qaHeader} >
              <Text bold h1>{t('landing.qa.headline')}</Text>
            </View>

            <TuneupQA />
          </View>
        </View>

        <Footer />

        <View style={styles.extra}>
          <ExternalLink to="https://www.freepik.com" >
            <Text caption>
              Image by Freepik
            </Text>
          </ExternalLink>

          <ExternalLink to="https://www.flaticon.com" style={{ marginLeft: space[1] }} >
            <Text caption>
              Icon by Flaticon
            </Text>
          </ExternalLink>
        </View>

        <View style={styles.spacer} />

      </ScrollView>

      <Animated.View style={[
        styles.sticky,
        { opacity: footerAnim },
      ]} >
        <LinkButton fullWidth
          to="/auth/signup"
          label={t('landing.startNow')}
          style={styles.ctaButton}
          labelStyle={styles.ctaButtonLabel}
        />
      </Animated.View>
    </View>
  )
}

export default Landing
