import { useEffect, useMemo, useState, } from 'react'
import { CollectionId } from '@/types'
import Button from '@/components/Button'

import { useStore } from '@/store'
import { useTranslation } from '@/i18n'
import useLoading from '@/hooks/useLoading'
import { read, sync } from '@/features/collections'
import useEntityValue from '@/hooks/useEntityValue'

const SyncButton = ({
  collectionId,
  type,
}: {
  collectionId: CollectionId
  type?: 'sitemap'
}) => {

  const { t } = useTranslation()
  const { dispatch, getState } = useStore()

  const syncStartAt = useEntityValue('collections', collectionId, 'syncStartAt')
  // start polling when the syncStartAt is already set
  const [hasStarted, setHasStarted] = useState(!!syncStartAt)
  const [syncHasBeenSet, setSyncHasBeenSet] = useState(!!syncStartAt)

  // syncHasBeenSet will be true only once and do not change
  useEffect(()=> {
    if (!syncHasBeenSet && syncStartAt){
      setSyncHasBeenSet(true)
    }
  }, [syncStartAt, syncHasBeenSet])

  // trigger to clear interval when sync is finished
  useEffect(()=> {
    if (syncHasBeenSet && (!syncStartAt && hasStarted)) {
      setHasStarted(false)
    }
  }, [syncStartAt, syncHasBeenSet, hasStarted])

  useEffect(()=> {
    const load = async()=> {
      await read(collectionId)(dispatch, getState)
    }
    if (hasStarted) {
      const interval = setInterval(load, 1000)
      return ()=> clearInterval(interval)
    }
  }, [hasStarted, dispatch, getState, collectionId])

  const { loading, withLoading } = useLoading()
  const onPressSync = async()=> {
    await withLoading(async()=> {
      await sync(collectionId, { type })(dispatch, getState)
      setHasStarted(true)
    })
  }

  const syncRunningMoreThanOneHour = useMemo(()=> {
    if (!syncStartAt) return false
    const now = Date.now()
    const anHour = 1000 * 60 * 60 * 1
    return now - syncStartAt > anHour
  }, [syncStartAt])
  const isSyncStarted = !!syncStartAt
  const hasActiveSync = isSyncStarted && !syncRunningMoreThanOneHour
  const disableSync = loading || hasActiveSync

  return (
    <Button small testID='button-sync'
      variant="contained"
      onPress={onPressSync}
      loading={disableSync}
      label={t('collections.settings.syncUrl.sync')}
    />
  )
}

export default SyncButton
