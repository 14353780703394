import { StyleSheet, View } from 'react-native'
import { space, colors, card } from '@/styles/theme'
import Text from '@/components/Text'
import ActivityIndicator from '@/components/ActivityIndicator'

const styles = StyleSheet.create({
  card: {
    padding: space[2],
    alignItems: 'flex-start',
    ...card.button,
    maxWidth: 250,
    backgroundColor: colors.contrast,
    flex: 1,
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginLeft: space[1],
  }
})

const OptionCard = ({
  title,
  icon,
  description,
  loading,
}: {
  title: string,
  icon: React.ReactNode,
  description: string,
  loading?: boolean,
}) => {

  return (
    <View style={styles.card}>
      <View style={styles.cardHeader}>
        {loading ? (<ActivityIndicator />) : icon}
        <Text body2 style={styles.text}>
          {title}
        </Text>
      </View>
      <Text label style={{ marginTop: space[1] }}>
        {description}
      </Text>
    </View>
  )
}

export default OptionCard
