import { View, StyleSheet } from 'react-native'
import ActivityIndicator from '@/components/ActivityIndicator'
import Text from '@/components/Text'
import { card, colors, space } from '@/styles/theme'
import { useTranslation } from '@/i18n'
import { useState, useEffect } from 'react'

const styles = StyleSheet.create({
  container: {
    ...card[5],
    backgroundColor: colors.grey[50],
    padding: space[3],
    marginBottom: space[3],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    marginTop: space[2],
  },
  progress: {
    marginTop: space[2],
    width: '100%',
  }
})

const PollingCard = ({
  updatingFrom,
  progress,
}: {
  updatingFrom: number | null | undefined
  progress?: string
})=> {

  const { t } = useTranslation()

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval: any = null;
    if (updatingFrom) {
      interval = setInterval(() => {
        setSeconds(Math.round((Date.now() - updatingFrom) / 1000));
      }, 1000);
    } else {
      clearInterval(interval);
      setSeconds(0);
    }
    return () => clearInterval(interval);
  }, [updatingFrom]);

  const isMoreThanTenMinutesOld = seconds > 600
  if(isMoreThanTenMinutesOld) return null

  if(!updatingFrom) return null

  return (
    <View style={styles.container}>
      <ActivityIndicator />
      <Text label style={styles.text}>
        {t('generate.polling')}
        {t('generate.seconds', { seconds })}
      </Text>

      {progress && (
        <Text style={styles.progress}>
          {progress}
        </Text>
      )}
    </View>
  )

}

export default PollingCard
