
import Image from '@/components/Image'
import icon from '@/assets/icon.png'
import Link from '@/components/Link'
import { space } from '@/styles/theme'

const AppIconButton = () => {

  return (
    <Link
      style={{
        width: space[4],
        height: space[4],
      }}
      to="/home"
    >
      <Image source={icon} style={{ borderRadius: space[1] }} />
    </Link>
  )

}

export default AppIconButton
