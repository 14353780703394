import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { RootStackParamList } from '@/navigation/types'
import { Suspense, lazy } from 'react'
import { colors } from '@/styles/theme'

const Stack = createNativeStackNavigator<RootStackParamList>()

import HeaderLeftButton from '@/navigation/HeaderLeftButton'
import GenerateOptions from '@/components/generate/Options'

import Landing from '@/pages/index'
import Signup from '@/pages/auth/signup'
import Login from '@/pages/auth/login'
import Home from '@/pages/home'

import Collection from '@/pages/collections/[collectionId]'
const CollectionSettings = lazy(() => import('@/pages/collections/[collectionId]/settings'))
import CollectionKeywords from '@/pages/collections/[collectionId]/keywords'
import Doc from '@/pages/docs/[docId]'
import OrgSettings from '@/pages/orgs/[orgId]/settings'
import OrgCollectionNew from '@/pages/orgs/[orgId]/collections/new'
import Invite from '@/pages/orgs/[orgId]/invites/[inviteId]'
import Keyword from '@/pages/keywords/[keywordId]'
import Draft from '@/pages/drafts/[draftId]'

import Developers from '@/pages/developers/index'

import Inquiry from '@/pages/inquiry'

const Commerce = lazy(() => import('@/pages/legal/commerce'))
const Privacy = lazy(() => import('@/pages/legal/privacy'))
const Terms = lazy(() => import('@/pages/legal/terms'))

import { useTranslation } from '@/i18n'

const RootStack = () => {

  const { t } = useTranslation()

  return (
    <Suspense fallback={null}>
      <Stack.Navigator
        screenOptions={{
          headerStyle: {
            // @ts-ignore it's working but type error is raised
            height: 60,
          },
          headerTitleStyle: {
            fontSize: 14,
            color: colors.grey[600],
          },
          headerLeft: (props)=> (
            <HeaderLeftButton {...props} />
          ),
        }}
      >
        <Stack.Screen
          name="Landing"
          component={Landing}
          options={{
            headerShown: false,
            title: t('routes.Landing') || '',
          }}
        />
        <Stack.Screen
          name="Signup"
          component={Signup}
          options={{
            headerShown: false,
            title: t('routes.Signup') || '',
          }}
        />
        <Stack.Screen
          name="Login"
          component={Login}
          options={{
            headerShown: false,
            title: t('routes.Login') || '',
          }}
        />

        <Stack.Screen
          name="Home"
          component={Home}
          options={{ title: 'Dashboard', }}
        />

        <Stack.Screen
          name="Collection"
          component={Collection}
          options={{ title: t('routes.Collection') || '', }}
        />
        <Stack.Screen
          name="CollectionKeywords"
          component={CollectionKeywords}
          options={{
            title: t('routes.CollectionKeywords') || '',
            headerRight: () => <GenerateOptions />,
          }}
        />
        <Stack.Screen
          name="CollectionSettings"
          component={CollectionSettings}
          options={{ title: t('routes.CollectionSettings') || '' }}
        />

        <Stack.Screen
          name="Keyword"
          component={Keyword}
          options={{
            title: t('routes.Keyword') || '',
            headerRight: () => <GenerateOptions />
          }}
        />
        <Stack.Screen
          name="Draft"
          component={Draft}
          options={{
            title: t('routes.Draft') || '',
            headerRight: () => <GenerateOptions />
          }}
        />

        <Stack.Screen
          name="Doc"
          component={Doc}
          options={{
            title: t('routes.Doc') || '',
            headerRight: () => <GenerateOptions />
          }}
        />

        <Stack.Screen
          name="OrgSettings"
          component={OrgSettings}
          options={{ title: t('routes.OrgSettings') || '' }}
        />
        <Stack.Screen
          name="OrgCollectionNew"
          component={OrgCollectionNew}
          options={{ title: t('routes.OrgCollectionNew') || '' }}
        />

        <Stack.Screen
          name="Invite"
          component={Invite}
          options={{ title: t('routes.Invite') || '' }}
        />

        <Stack.Screen
          name="Developers"
          component={Developers}
          options={{ title: t('routes.Developers') || '' }}
        />

        <Stack.Screen
          name="Inquiry"
          component={Inquiry}
          options={{ title: t('routes.Inquiry') || '' }}
        />

        <Stack.Screen
          name="Commerce"
          component={Commerce}
          options={{ title: t('general.commerce') || '' }}
        />
        <Stack.Screen
          name="Privacy"
          component={Privacy}
          options={{ title: t('general.privacy') || '' }}
        />
        <Stack.Screen
          name="Terms"
          component={Terms}
          options={{ title: t('general.terms') || '' }}
        />

      </Stack.Navigator>
    </Suspense>
  );
}

export default RootStack
