import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  link: {
    color: 'inherit',
    textDecorationColor: 'transparent',
  },
})

const ExternalLink = ({
  to,
  children,
  style,
  ...props
}: {
  to: string
  children: any
  style?: any
}) => (

  <a
    href={to}
    target="_blank"
    rel="noopener noreferrer"
    style={{...styles.link, ...style}}
    {...props}
  >
    {children}
  </a>

)

export default ExternalLink
