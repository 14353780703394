import TouchableOpacity from '@/components/TouchableOpacity'
import Dialog from '@/components/Dialog'
import { CollectionId } from '@/types'
import { useState } from 'react'
import TextInput from '@/components/TextInput'
import Text from '@/components/Text'
import Button from '@/components/Button'
import { useTranslation } from '@/i18n'
import { StyleSheet } from 'react-native'
import { space } from '@/styles/theme'
import { create, sync } from '@/features/docs'
import { useStore } from '@/store'
import { useNavigation } from '@/navigation'
import useLoading from '@/hooks/useLoading'
import { set } from '@/features/entities'

const styles = StyleSheet.create({
  button: {
    marginTop: space[1],
    alignSelf: 'flex-end',
  }
})

const DocURLImport = ({
  collectionId,
  children,
}: {
  collectionId: CollectionId,
  children: React.ReactNode,
}) => {

  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState('')
  const { dispatch, getState } = useStore()
  const navigation = useNavigation()
  const { loading, withLoading } = useLoading()

  const submit = async() => {
    await withLoading(async()=> {
      const { data } = await create({
        title: '',
        collectionId,
        externalId: url,
      })(dispatch, getState)
      const doc = data?.doc
      if(doc){
        await sync(doc.id)(dispatch, getState)
        // trigger polling
        set('docs', {...doc, updatingFrom: Date.now()})(dispatch)

        setOpen(false)
        navigation.navigate('Doc', { docId: doc.id })
      }
    })
  }

  return (
    <>
      <TouchableOpacity testID="button-url-import"
        onPress={()=> setOpen(true)}
      >
        {children}
      </TouchableOpacity>
      <Dialog
        title={t('docs.urlImport.title')}
        visible={open}
        onClose={()=> setOpen(false)}
      >
        <Text body2>
          {t('docs.urlImport.description')}
        </Text>
        <TextInput testID="input-url"
          label={t('docs.urlImport.url.label')}
          placeholder={t('docs.urlImport.url.placeholder')}
          value={url}
          onChangeText={setUrl}
        />

        <Button small testID="button-submit"
          onPress={submit}
          label={t('docs.urlImport.submit')}
          style={styles.button}
          loading={loading}
        />
      </Dialog>
    </>
  )

}

export default DocURLImport
