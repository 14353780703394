import { getAuth, removeAuth } from '@/features/auth'
import { add } from '@/features/flash'
import i18n from '@/i18n'
import { AppDispatch, GetState } from '@/store'
import { ErrorResponse } from '@/types/response'
import * as utils from '@/utils'

type Json = object

export type SafeResponse = {
  ok: boolean
  status: number
  json: () => Promise<Json>
  clone: () => SafeResponse
}

const headers = {
  'Content-Type': 'application/json',
}

export const getHeaders = () => {
  return async(dispatch: AppDispatch, getState: GetState) => {
    const jwt = await getAuth()(dispatch, getState)
    const language = getState().generate.language
    return {
      ...headers,
      Authorization: `Bearer ${jwt}`,
      ...(language ? { 'Accept-Language': language } : {}),
    }
  }
}

const query = (endpoint: string, params?: any) => {
  const url = new URL(endpoint)
  if (params) {
    Object.keys(params).forEach(key => {
      // don't add undefined or null params
      if(params[key] !== undefined && params[key] !== null){
        url.searchParams.append(key, params[key])
      }
    })
  }
  return url.toString()
}

const handleResponseErrors = (response: Response) => {
  return async(dispatch: AppDispatch, getState: GetState) => {
    if(response.status === 400){
      const cloned = response.clone()
      const json: ErrorResponse = await cloned.json()
      if(json.error){
        add({ type: 'error', text: json.error })(dispatch, getState)
      }

      if(json.errors){
        Object.keys(json.errors).forEach((key: string) => {
          const errors = json.errors?.[key]
          if(errors){
            errors.forEach((error: string) => {
              add({ type: 'error', text: error })(dispatch, getState)
            })
          }
        })
      }
    }
    if(response.status === 401){
      add({ type: 'error', text: i18n.t('flash.auth.unauthorized') })(dispatch, getState)
      await removeAuth()(dispatch, getState)
    }
    if(response.status === 402){
      add({ type: 'error', text: i18n.t('flash.payment_required') })(dispatch, getState)
    }
    if(response.status === 403){
      add({ type: 'error', text: i18n.t('flash.forbidden') })(dispatch, getState)
    }
    if(response.status === 404){
      add({ type: 'error', text: i18n.t('flash.not_found') })(dispatch, getState)
    }
    if(response.status === 409){
      add({ type: 'warning', text: i18n.t('flash.conflict') })(dispatch, getState)
    }
    if(response.status === 500){
      add({ type: 'error', text: i18n.t('flash.server_error') })(dispatch, getState)
    }
    if(response.status === 502){
      add({ type: 'error', text: i18n.t('flash.server_error') })(dispatch, getState)
    }
  }
}

export const handleFetch = (
  url: string,
  method: 'GET' | 'POST' | 'DELETE',
  payload?: object,
  params?: object,
  retry?: number,
) => {
  return async(dispatch: AppDispatch, getState: GetState): Promise<SafeResponse> => {
    const endpoint = query(url, params)
    const headers = await getHeaders()(dispatch, getState)
    let count = retry || 0
    try {
      const response = await fetch(endpoint, {
        method,
        headers,
        body: payload ? JSON.stringify(payload) : undefined,
      })

      await handleResponseErrors(response)(dispatch, getState)

      return response
    } catch (error) {
      p(error)
      if(count < 3){
        count++
        await utils.sleep(1000)
        return await handleFetch(url, method, payload, params, count)(dispatch, getState)
      }else{
        throw error
      }
    }
  }
}

const get = (endpoint: string, params?: any) => {
  return async(dispatch: AppDispatch, getState: GetState) => {
    return await handleFetch(endpoint, 'GET', undefined, params)(dispatch, getState)
  }
}

export const post = (endpoint: string, payload?: object)=> {
  return async(dispatch: AppDispatch, getState: GetState) => {
    return await handleFetch(endpoint, 'POST', payload)(dispatch, getState)
  }
}

const remove = (endpoint: string, params?: any)=> {
  return async(dispatch: AppDispatch, getState: GetState) => {
    return await handleFetch(endpoint, 'DELETE', undefined, params)(dispatch, getState)
  }
}

export const poll = async({
  reader,
  taker,
}: {
  reader: ()=> Promise<Json>,
  taker: (json: Json)=> number | null | undefined,
})=> {
  return new Promise<void>(async(resolve, reject) => {
    try{
      const data = await reader()
      const updatingFrom = taker(data)
      if(updatingFrom) { // is still updating
        // stop when updatingFrom more than 10 minutes
        const updatedAt = new Date(updatingFrom).getTime()
        const now = new Date().getTime()
        if(now - updatedAt > 1000 * 60 * 10) {
          resolve()
          return
        }

        setTimeout(() => {
          poll({reader, taker})
        }, 1000)
      }else{
        resolve()
      }
    }catch(error){
      reject(error)
    }
  })
}



export const requests = {
  get,
  post,
  remove,
  poll,
}
