import { configureStore } from '@reduxjs/toolkit'

import { reducer as auth } from '@/features/auth'
import { reducer as imports } from '@/features/imports'
import { reducer as entities } from '@/features/entities'
import { reducer as flash } from '@/features/flash'
import { reducer as generate } from '@/features/generate'
import { reducer as navigation } from '@/features/navigation'

import { useStore as useReduxStore } from 'react-redux'
import { combineReducers, } from 'redux'

import {
  persistStore,
  persistReducer,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['generate'],
}

const reducer = combineReducers({
  auth,
  imports,
  entities,
  flash,
  generate,
  navigation,
})

const persistedReducer = persistReducer(persistConfig, reducer)

const middleware = []

if(process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  const { logger } = require('redux-logger')
  middleware.push(logger)
}

export const store = configureStore({
  middleware: middleware,
  reducer: persistedReducer,
})

export const persistor = persistStore(store)

export const useStore = ()=> {
  const { dispatch, getState } = useReduxStore()
  return {
    dispatch: dispatch as AppDispatch,
    getState: getState as GetState,
  }
}

export { useSelector, useDispatch } from 'react-redux'

export type ClearAction = {
  type: 'all/clear'
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type GetState = ()=> RootState
