import { useSelector, useStore } from '@/store'
import { space, colors } from '@/styles/theme'
import { setModel } from '@/features/generate'
import { selectModel } from '@/features/generate/selectors'
import SmallButton from '@/components/SmallButton'
import { ChatModel } from '@/types'

const toLabel = (model: ChatModel) => {
  switch(model) {
    case 'gpt-3.5-turbo':
      return 'Economy'
    case 'gpt-4':
      return 'Premium'
  }
}

const toColor = (model: ChatModel) => {
  switch(model) {
    case 'gpt-3.5-turbo':
      return colors.green[100]
    case 'gpt-4':
      return colors.purple[100]
  }
}

const nextModel = (model: ChatModel) => {
  switch(model) {
    case 'gpt-3.5-turbo':
      return 'gpt-4'
    case 'gpt-4':
      return 'gpt-3.5-turbo'
  }
}

const ModelPicker = () => {

  const current: ChatModel = useSelector(selectModel)
  const { dispatch } = useStore()

  const switchModel = ()=> {
    const next = nextModel(current)
    setModel(next)(dispatch)
  }

  return (
    <SmallButton testID='button-toggle-model'
      style={{
        backgroundColor: toColor(current),
        marginRight: space[2],
      }}
      onPress={switchModel}
      iconName='repeat-outline'
      label={toLabel(current)}
    />
  )

}

export default ModelPicker
