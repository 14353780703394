import View from '@/components/View'
import Text from '@/components/Text'
import Icon from '@/components/Icon'
import TouchableOpacity from '@/components/TouchableOpacity'
import { colors, space, radius, shadow, size } from '@/styles/theme'
import { StyleSheet } from 'react-native'
import { useSelector, RootState, useStore } from '@/store'
import { useEffect } from 'react'
import { remove } from '@/features/flash'

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1000,
  },
  snackbar: {
    minWidth: 288,
    margin: space[3],
    padding: space[2],
    paddingTop: space[1],
    paddingBottom: space[1],
    paddingRight: space[1], // space[1] is added by close button
    borderRadius: radius.sm,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...shadow[8],
  },
  text: {
    color: colors.contrastText,
    fontWeight: '500',
    paddingTop: space[1],
    paddingBottom: space[1],
  }
})

const Snackbar = ({
  id,
}: {
  id: number
}) => {

  const { dispatch, getState } = useStore()
  const type = useSelector((state: RootState) => state.flash.messages.find(m => m.id === id)?.type)
  const text = useSelector((state: RootState) => state.flash.messages.find(m => m.id === id)?.text)
  let backgroundColor = type === 'success' ? colors.green[600] : colors.red[600]
  if(type === 'warning') backgroundColor = colors.amber[300]

  const close = () => remove(id)(dispatch, getState)

  useEffect(() => {
    const timeout = setTimeout(() => {
      close()
    }, 5000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <View style={[ styles.snackbar, { backgroundColor } ]} >
      <Text style={styles.text}>{text}</Text>
      <TouchableOpacity onPress={close}
        style={{
          padding: space[1],
        }}
      >
        <Icon
          icon='xmark'
          size={size.button.small}
          color={colors.contrastText}
        />
      </TouchableOpacity>
    </View>
  )

}

const Flash = () => {

  const visible = useSelector((state: RootState) => state.flash.messages.length > 0)
  const ids = useSelector((state: RootState) => state.flash.messages.map(m => m.id))

  const display = visible ? 'flex' : 'none'

  return (
    <View style={[styles.container, { display } ]}>
      {ids.map(id => ( <Snackbar key={id} id={id} />))}
    </View>
  )
}

export default Flash
