import { useState } from 'react'
import log from '@/log'

type Action = () => Promise<void>

const useLoading = () => {
  const [loading, setLoading] = useState<boolean|undefined>(undefined)

  const withLoading = async (action: Action) => {
    if(loading) return

    setLoading(true)
    try {
      await action()
    } catch (e) {
      console.error(e)
      log.capture(e as Error, { action })
    } finally {
      setLoading(false)
    }
  }

  return { loading, withLoading }
}

export default useLoading
