import { AppDispatch } from '@/store'

export const toggleDrawer = () => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: types.toggleDrawer })
  }
}

export const closeDrawer = () => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: types.closeDrawer })
  }
}

const types = {
  toggleDrawer: 'navigation/toggleDrawer',
  closeDrawer: 'navigation/closeDrawer'
} as const

type ToggleDrawerAction = {
  type: 'navigation/toggleDrawer'
}
type CloseDrawerAction = {
  type: 'navigation/closeDrawer'
}
type ClearAction = {
  type: 'all/clear'
}

type Action = ToggleDrawerAction |
  CloseDrawerAction |
  ClearAction

type State = {
  drawerOpen: boolean
}

const initialState: State = {
  drawerOpen: true
}

export const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case types.toggleDrawer: {
      return {
        ...state,
        drawerOpen: !state.drawerOpen
      }
    }

    case types.closeDrawer: {
      return {
        ...state,
        drawerOpen: false
      }
    }

    case 'all/clear': { return initialState }
    default:
      return state
  }
}
