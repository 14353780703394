import {
  FlatList as NativeFlatList,
  FlatListProps,
} from 'react-native'
import ActivityIndicator from '@/components/ActivityIndicator'
import View from '@/components/View'
import { StyleSheet } from 'react-native'
import { space } from '@/styles/theme'
import { forwardRef } from 'react'

interface CustomProps<ItemT> extends FlatListProps<ItemT> {
  loading: boolean | undefined,
}

const styles = StyleSheet.create({
  loading: {
    position: 'absolute',
    top: space[2],
    left: 0,
    right: 0,
    zIndex: 1,
  }
})

const FlatList = <ItemT,>({
  loading,
  ListEmptyComponent,
  ...props
}: CustomProps<ItemT>,
  ref: any
)=> {

  return(
    <View style={{ flex: 1 }}>
      {loading && (
        <View style={styles.loading}>
          <ActivityIndicator />
        </View>
      )}
      <NativeFlatList
        ref={ref}
        // don't show untill the load in complete.
        ListEmptyComponent={loading === false ? ListEmptyComponent : null}
        {...props}
      />
    </View>
  )
}

export { FlatListProps } from 'react-native'
export default forwardRef(FlatList)
