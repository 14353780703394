import {
  ViewStyle,
  ScrollView,
  View,
  StyleSheet,
} from 'react-native'
import { space, maxWidth } from '@/styles/theme'
import useIsMobile from '@/hooks/useIsMobile'

const styles = StyleSheet.create({
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    maxWidth: maxWidth.md,
    width: '100%',
    padding: space[5]
  },
  mobile: {
    padding: space[2],
  }
})

type Props = {
  children: React.ReactNode
  style?: ViewStyle
}
const ScrollablePage = ({
  children,
  style
}: Props) => {

  const isMobile = useIsMobile()

  return(
    <ScrollView
      contentContainerStyle={styles.scrollContainer}
    >
      <View style={[
        styles.container,
        isMobile ? styles.mobile : undefined,
        style
      ]}>
        {children}
      </View>
    </ScrollView>
  )

}

export default ScrollablePage
