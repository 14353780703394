import { initializeApp } from 'firebase/app'
import {
  getAnalytics,
  isSupported,
  logEvent,
} from 'firebase/analytics'
import config from '@/config'
import { Event } from '@/types/analytics'

// configure and initialize Firebase for web (copy this from the Firebase Console Project Settings for the exact values)

let app = null
let webAnalytics = null as any

isSupported().then((supported) => {
  if (supported) {
    app = initializeApp(config.firebase)
    webAnalytics = getAnalytics(app)
  }
})

// keep the interface consistent with the native Firebase Analytics
const analytics = ()=> {
  return {
    logEvent: (name: Event, params?: object) => {
      try{
        logEvent(
          webAnalytics,
          name as string,
          params
        )
      }catch(e){
        p('Error logging event', e)
      }
    }
  }
}

export default analytics
