import OrgsList from '@/components/orgs/List'
import View from '@/components/View'
import { colors, space, } from '@/styles/theme'
import { StyleSheet, ScrollView } from 'react-native'
import { useSelector, RootState, useStore } from '@/store'
import useIsMobile from '@/hooks/useIsMobile'
import { useNavigation } from '@/navigation'
import { useEffect } from 'react'
import { closeDrawer } from '@/features/navigation'

const width = 260
const styles = StyleSheet.create({
  contanier: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    backgroundColor: 'white',
  },
  scroll: {
    maxWidth: width,
  },
  sidebar: {
    width,
    minHeight: '100%',
    paddingLeft: space[3],
    paddingRight: space[3],
    paddingBottom: space[3],
    borderRightWidth: 1,
    borderRightColor: colors.grey[300],
    borderStyle: 'solid',
  },
  hidden: {
    display: 'none',
  },
  mobileSidebar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: 'white',
  },
  page: {
    flex: 1,
    backgroundColor: colors.grey[50],
  },
})

const PageWithDrawer = ({
  children
}: {
  children: React.ReactNode
})=> {

  const isMobile = useIsMobile()
  const drawerOpen = useSelector((state: RootState) => state.navigation.drawerOpen)
  const { dispatch } = useStore()

  const navigation = useNavigation()

  useEffect(() => {
    // always close after navigation on mobile
    if(isMobile){
      // 'blur' does not trigger when navigating between same screens with different params
      const ubsubscribe = navigation.addListener('state', () => {
        closeDrawer()(dispatch)
      })
      return ubsubscribe
    }
  }, [navigation, isMobile])

  return (
    <>
      <View style={styles.contanier} >
        <ScrollView
          style={[
            styles.scroll,
            drawerOpen ? undefined : styles.hidden,
            isMobile ? styles.mobileSidebar : undefined,
          ]}
          contentContainerStyle={styles.sidebar}
        >
          <OrgsList />
        </ScrollView>

        <View style={styles.page}>
          {children}
        </View>
      </View>
    </>
  )
}

export default PageWithDrawer
