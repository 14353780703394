import ModelPicker from '@/components/generate/ModelPicker'
import LanguagePicker from '@/components/generate/LanguagePicker'
import { View, StyleSheet } from 'react-native'
import { space } from '@/styles/theme'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  left: {
    marginRight: space[2],
  }
})


const Options = ()=> {

  return (
    <View style={styles.container}>
      <LanguagePicker style={styles.left} />
      <ModelPicker />
    </View>
  )

}

export default Options
