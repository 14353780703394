import View from '@/components/View'
import { space } from '@/styles/theme'
import Logo from '@/components/Logo'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  bar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: space[1],
    height: space[8],
    margin: space[2],
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const Header = ({
  left,
  right,
  logoColor,
}: {
  left?: React.ReactNode
  right?: React.ReactNode
  logoColor?: string
})=> {

  return(
    <View style={styles.bar}>

      <View style={styles.leftContainer}>
        {left}
        <Logo color={logoColor} />
      </View>

      {right}

    </View>
  )
}

export default Header
