import { DraftId } from '@/types'
import { update } from '@/features/drafts'
import { useState } from 'react'
import { useStore, useSelector } from '@/store'
import Alert from '@/lib/Alert'
import { ViewStyle } from 'react-native'
import useEntityValue from '@/hooks/useEntityValue'
import { useTranslation } from '@/i18n'
import SmallButton from '@/components/SmallButton'
import { selectModel } from '@/features/generate/selectors'

const GenerateOutlineButton = ({
  draftId,
  style,
  disabled,
  title,
}: {
  draftId: DraftId
  style?: ViewStyle
  disabled?: boolean
  // user can update the title before generate
  title?: string
}) => {

  const { t } = useTranslation()
  const { dispatch, getState } = useStore()
  const [loading, setLoading] = useState(false)
  const outline = useEntityValue('drafts', draftId, 'outline')
  const shouldRegen = !!outline
  const model = useSelector(selectModel)

  const onPress = async()=> {
    if(shouldRegen) {
      regen()
    } else {
      await generate()
    }
  }

  const generate = async()=> {
    setLoading(true)
    await update(draftId,
      {
        model,
        generateOutline: true,
        ...(title ? { title } : {}),
      }
    )(dispatch, getState)
    setLoading(false)
  }

  const regen = ()=> {
    Alert.alert(
      t('alert.confirmRegenerate.title'),
      t('alert.confirmRegenerate.message'),
      [
        { text: t('alert.cancel'), style: 'cancel' },
        { text: t('alert.confirmRegenerate.ok'), onPress: generate },
      ],
    )
  }

  let iconName = 'color-wand-outline'
  let label = t('drafts.generateOutline')
  let testID = 'button-generate-outline'
  if(shouldRegen) {
    iconName = 'repeat-outline'
    label = t('drafts.regenerateOutline')
    testID = 'button-regenerate-outline'
  }

  return(
    <SmallButton testID={testID}
      iconName={iconName}
      label={label}
      onPress={onPress}
      style={style}
      loading={loading}
      disabled={disabled}
    />
  )

}

export default GenerateOutlineButton
