import useResponsiveFlex from '@/hooks/useResponsiveFlex'
import { View, ViewStyle } from 'react-native'

const ResponsiveBox = ({
  children,
  style,
  reverseColumns,
}: {
  children: React.ReactNode,
  style?: ViewStyle,
  reverseColumns?: boolean,
}) => {

  const {
    flexDirection,
    alignItems,
  } = useResponsiveFlex({ reverseColumns })

  return (
    <View style={[
      { flexWrap: 'wrap', },
      { flexDirection, alignItems, },
      style
    ]}>
      {children}
    </View>
  )
}

export default ResponsiveBox

