import Text from '@/components/Text'
import View from '@/components/View'
import { StyleSheet } from 'react-native'
import { KeywordId } from '@/types'
import useEntityValue from '@/hooks/useEntityValue'
import Link from '@/components/Link'
import { card, space, colors } from '@/styles/theme'
import { useTranslation } from '@/i18n'
import FontIcon from '@/components/FontIcon'

const styles = StyleSheet.create({
  link: {
    marginBottom: space[2],
  },
  card: {
    ...card.listItem,
    borderRadius: space[1],
    padding: space[2],
    display: 'flex',
    backgroundColor: colors.contrast,
  },
  value: {
    marginBottom: space[1],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  keyword: {
    marginLeft: space[1],
  },
  target: {
    marginBottom: space[1]
  }
})

const KeywordCard = ({
  keywordId,
}: {
  keywordId: KeywordId
}) => {

  const { t } = useTranslation()
  const keyword = useEntityValue('keywords', keywordId, 'value')
  const target = useEntityValue('keywords', keywordId, 'target')

  return (
    <Link to={`/keywords/${keywordId}`} style={styles.link}>
      <View style={styles.card}>

        <View style={styles.value}>
          <FontIcon name='pricetag-outline'/>
          <Text body2 style={styles.keyword}>{keyword}</Text>
        </View>

        <View style={styles.target}>
          <Text caption>
            {t('keywords.item.target.label')}
          </Text>
          {target ? (
            <Text body2>{target}</Text>
          ) : (
            <Text label>{t('keywords.item.target.none')}</Text>
          )}
        </View>
      </View>
    </Link>
  )
}

export default KeywordCard
