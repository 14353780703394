import {
  Modal,
  View,
  StyleSheet,
} from 'react-native'
import { colors, space, card, fontSize } from '@/styles/theme'
import Text from '@/components/Text'
import IconButton from '@/components/IconButton'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:'rgba(0,0,0,0.3)',
  },
  dialog: {
    backgroundColor: colors.contrast,
    padding: space[3],
    borderRadius: space[0.5],
    maxWidth: 600,
    width: '100%',
    maxHeight: '80%',
    ...card.dialog
  },
  title: {
  },
  header: {
    marginBottom: space[2],
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

const Dialog = ({
  title,
  children,
  visible,
  onClose
}: {
  title: string,
  children: React.ReactNode,
  visible: boolean,
  onClose: ()=> void,
})=> {

  return (
    <Modal
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.container}>
        <View style={styles.dialog}>
          <View style={styles.header}>
            <Text h2 style={styles.title}>{title}</Text>
            <IconButton
              name="close-outline"
              onPress={onClose}
              size={fontSize.h2}
            />
          </View>
          {children}
        </View>
      </View>
    </Modal>
  )

}

export default Dialog
