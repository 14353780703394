import { createIconSetFromFontello } from '@expo/vector-icons'
import { iconSize, colors } from '@/styles/theme'
import { TextStyle } from 'react-native'

import selection from '@/assets/fonts/myfont.json'
type Glyph = {
  css: string,
}
const iconNames = selection.glyphs.map((icon: Glyph) => icon.css)

const IconSet = createIconSetFromFontello(
  selection,
  'MyFont',
  'myfont.ttf'
)

const isValidName = (name: string) => {
  return iconNames.includes(name)
}

const FontIcon = ({
  name,
  size = iconSize.small,
  color,
  style,
  inverted,
}: {
  name: string,
  size?: number,
  color?: string,
  style?: TextStyle,
  inverted?: boolean,
}) => {

  // only in dev
  if(process.env.NODE_ENV === 'development' && !isValidName(name)) {
    console.error(`Invalid icon name: ${name}`)
    console.error(`Valid icon names are:\n${iconNames.join('\n')}`)
    return null
  }

  return (
    <IconSet
      name={name}
      size={size}
      color={inverted ? colors.contrastText : color}
      style={style}
    />
  )
}
export default FontIcon
