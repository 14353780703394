import {
  CollectionId,
  OrgId,
} from '@/types'
import { CollectionShow } from '@/types/response'
import { requests, SafeResponse } from '@/features/requests'
import api from '@/api'
import { set, append, remove as removeEntity } from '@/features/entities'
import i18n from '@/i18n'
import { add } from '@/features/flash'
import { AppDispatch, GetState } from '@/store'

export const read = (id: CollectionId)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<CollectionShow> => {
    const response = await requests.get(api.collections.show(id))(dispatch, getState)
    if(response.ok){
      const json: CollectionShow = await response.json()
      set('collections', json.collection)(dispatch)
      return json
    }
    return {}
  }
}

type CreateParams = {
  orgId: OrgId,
  name: string
}

export const create = (params: CreateParams)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<CollectionShow> => {
    const response = await requests.post(api.collections.create(), { ...params })(dispatch, getState)
    if(response.ok){
      const json: CollectionShow = await response.json()
      set('collections', json.collection)(dispatch)
      if(json.collection?.id){
        append('collections', json.collection.id, params.orgId)(dispatch)
      }
      add({ type: 'success', text: i18n.t('flash.collections.create.success') })(dispatch, getState)
      return json
    }

    return {}
  }
}

type UpdateParams = {
  name?: string
  sourceCollectionIds?: CollectionId[]
  syncUrl?: string
  isPartialRSS?: boolean
  template?: string
  sitemapUrl?: string
}

export const update = (id: CollectionId, params: UpdateParams)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<SafeResponse> => {
    const response = await requests.post(api.collections.update(id), { ...params })(dispatch, getState)
    if(response.ok){
      const json: CollectionShow = await response.json()
      set('collections', json.collection)(dispatch)
      add({ type: 'success', text: i18n.t('flash.collections.update.success') })(dispatch, getState)
    }
    return response
  }
}

export const remove = (id: string)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<SafeResponse> => {
    const response = await requests.remove(api.collections.remove(id))(dispatch, getState)
    if(response.ok){
      removeEntity('collections', id)(dispatch)
      add({ type: 'success', text: i18n.t('flash.collections.remove.success') })(dispatch, getState)
    }

    return response
  }
}

type SyncParams = {
  type?: 'sitemap' // default to feed sync
}

export const sync = (id: CollectionId, params: SyncParams)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<SafeResponse> => {
    const response = await requests.post(api.collections.sync(id), params)(dispatch, getState)
    if(response.ok){
      const json: CollectionShow = await response.json()
      set('collections', json.collection)(dispatch)
      add({ type: 'success', text: i18n.t('flash.collections.sync.success') })(dispatch, getState)
    }
    return response
  }
}
