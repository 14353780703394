import SVGLogo from '@/components/svgs/Logo';
import { StyleSheet } from 'react-native'
import View from '@/components/View'

const styles = StyleSheet.create({
  logo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
})

const Logo = ({
  color
}: {
  color?: string
}) => {

  return(
    <View style={styles.logo}>
      <View style={{ width: 100 }}>
        <SVGLogo color={color} />
      </View>
    </View>
  )

}

export default Logo
