import { OrgId } from '@/types'
import { usage } from '@/features/orgs'
import { useEffect, useState } from 'react'
import { Usage, OrgUsage } from '@/types/response'
import { useStore } from '@/store'
import { View, StyleSheet, ViewStyle } from 'react-native'
import Text from '@/components/Text'
import { useTranslation } from '@/i18n'
import { space } from '@/styles/theme'
import Alert from '@/components/Alert'

const styles = StyleSheet.create({
  container: {
    marginTop: space[2],
  },
  table: {
    marginTop: space[1]
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: space[1],
  },
  cell: {
    flex: 1,
    textAlign: 'right',
  },
  name: {
    flex: 2,
    textAlign: 'left',
  },
  alert: {
    marginTop: space[1],
  }
})

const asDate = (timestamp?: number): string=> {
  if (!timestamp) return ''

  const date = new Date(timestamp)
  // only show date, not time
  return date.toLocaleDateString()
}

// add commas to numbers
const asQuantity = (quantity?: number): string=> {
  if (!quantity) return '0'

  return quantity.toLocaleString()
}

const asDollar = (cents?: number): string=> {
  if (!cents) return '$0'

  return `$${(cents / 100).toFixed(2)}`
}

const totalAmount = (usages?: Usage[]): number=> {
  if (!usages) return 0

  return usages.reduce((acc, usage)=> acc + (usage.amount || 0), 0)
}

const totalQuantity = (usages?: Usage[]): number=> {
  if (!usages) return 0

  return usages.reduce((acc, usage)=> acc + (usage.quantity || 0), 0)
}

const Cell = ({
  value,
  bold,
  label,
  style,
}: {
  value: string | number | undefined
  bold?: boolean
  label?: boolean
  style?: ViewStyle
})=> {
  return (
    <View style={[styles.cell, style]}>
      <Text body2 bold={bold} label={label}>{value || ''}</Text>
    </View>
  )
}

const UsageList = ({
  orgId,
}: {
  orgId: OrgId
}) => {

  const { t } = useTranslation()
  const [start, setStart] = useState<number|undefined>(undefined)
  const [end, setEnd] = useState<number|undefined>(undefined)
  const [usages, setUsages] = useState<Usage[]|[]>([])
  const { dispatch, getState } = useStore()

  useEffect(()=> {
    const load = async()=> {
      const response = await usage(orgId)(dispatch, getState)
      const json: OrgUsage = await response.json()
      setStart(json.period?.start)
      setEnd(json.period?.end)
      setUsages(json.usages || [])
    }
    load()
  }, [orgId, dispatch, getState])

  return (
    <View style={styles.container}>
      <Text label>{t('orgs.usage.title')}</Text>

      <Text label>{t('orgs.usage.period')}: {asDate(start)} - {asDate(end)}</Text>

      <View style={styles.table}>
        {/* Header */}
        <View style={styles.row}>
          <Cell label style={styles.name} value={t('orgs.usage.name')} />
          <Cell label value={t('orgs.usage.quantity')} />
          <Cell label value={t('orgs.usage.amount')} />
        </View>

        {/* Usage */}
        {usages?.map((usage, i)=> (
          <View key={i} style={styles.row}>
            <Cell bold style={styles.name} value={t(`pricing.payAsYouGo.prices.${usage.name}.title`)} />
            <Cell value={asQuantity(usage.quantity)} />
            <Cell value={asDollar(usage.amount)} />
          </View>
        ))}

        {/* Total */}
        <View style={styles.row}>
          <Cell style={styles.name} value={t('orgs.usage.total')} />
          <Cell value={asQuantity(totalQuantity(usages))} />
          <Cell value={asDollar(totalAmount(usages))} />
        </View>
      </View>

      <Alert foldable
        style={styles.alert}
        type='info'
        title={t('orgs.usage.info.title')}
      >
        <Text body2>{t('orgs.usage.info.description')}</Text>
      </Alert>

    </View>
  )

}

export default UsageList
