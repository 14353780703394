import PageContainer from '@/components/layouts/PageContainer'
// @ts-ignore platform specific codes
import AuthGoogleButton from '@/components/auth/GoogleButton'
import Header from '@/components/layouts/Header'
import BackButton from '@/navigation/BackButton'
import View from '@/components/View'
import Text from '@/components/Text'
import { StyleSheet } from 'react-native'

import { LoginProps } from '@/navigation/types'
import { useEffect } from 'react'
import useAuth from '@/hooks/useAuth'
import { useTranslation } from '@/i18n'
import { space, card } from '@/styles/theme'
import useIsMobile from '@/hooks/useIsMobile'

const styles = StyleSheet.create({
  container: {
    marginTop: space[4],
    display: 'flex',
  },

  card: card[10],
  cardContent: {
    padding: space[6],
  },

  optionsContainer: {
    marginTop: space[4],
    display: 'flex',
    alignItems: 'center',
  },
})



const Login = ({
  navigation,
}: LoginProps) => {

  const { isLoggedIn } = useAuth()
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  useEffect(()=> {
    if (isLoggedIn) {
      navigation.reset({ index: 0, routes: [{ name: 'Home' }] })
    }
  }, [isLoggedIn])


  return(
    <PageContainer>
      <Header
        left={
          <View style={{marginRight: space[3]}} >
            <BackButton onPress={()=> navigation.navigate('Landing')} />
          </View>
        }
      />

      <View style={[
        { padding: space[4] },
        isMobile ? {} : { width: '50%', alignSelf: 'center' }
      ]}>
        <View style={styles.card}>
          <View style={styles.cardContent}>
            <Text h2>
              {t('auth.login.title')}
            </Text>

            <View style={styles.optionsContainer}>
              <AuthGoogleButton isLogin />
            </View>

          </View>
        </View>
      </View>
    </PageContainer>
  )

}

export default Login
