import { View } from 'react-native'

import '@/analytics'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from '@/store'
import RootErrorBoundary from '@/components/layouts/RootErrorBoundary'

import { NavigationContainer } from '@/navigation'
import RootStack from '@/navigation/RootStack'
import linking from '@/navigation/linking'
import i18n, { I18nextProvider } from '@/i18n'
import { useFonts } from 'expo-font'
import {
  NotoSans_300Light,
  NotoSans_400Regular,
  NotoSans_600SemiBold,
} from '@expo-google-fonts/noto-sans'

import Flash from '@/components/layouts/Flash'

// attach p to window for debugging
window.p = console.log
window.pp = console.log

export default function App() {

  // noto sans is not loaded for JP.
  const [fontsLoaded] = useFonts({
    NotoSans_300Light,
    NotoSans_400Regular,
    NotoSans_600SemiBold,
    MyFont: require('@/assets/fonts/myfont.ttf'),
  });

  if(!fontsLoaded) return null

  return (
    <RootErrorBoundary>
      <View style={{
        flex: 1,
        backgroundColor: '#fff',
      }}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <I18nextProvider i18n={i18n}>
              <Flash />
              <NavigationContainer linking={linking}>
                <RootStack />
              </NavigationContainer>
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </View>
    </RootErrorBoundary>
  );
}

