import Constants from 'expo-constants'
const name = Constants.expoConfig?.name || ''

type Config = {
  env: 'development' | 'staging' | 'production'
  domain: string
  apiDomain: string
  scheme: 'http' | 'https'
  google: {
    webClientId: string
  },
  sentry: {
    dsn: string
  }
  firebase: {
    apiKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
  }
  tuneup: {
    apiKey: string
  }
}

const devFirebase = {
  apiKey: "AIzaSyAMfDK4V7qrtZqHikmnrqD390znTTbCiec",
  authDomain: "tuneup-staging.firebaseapp.com",
  projectId: "tuneup-staging",
  storageBucket: "tuneup-staging.appspot.com",
  messagingSenderId: "344241521724",
  appId: "1:344241521724:web:2b3918088ad8b277d72f39",
  measurementId: "G-3JGYG9CR8R"
}

const development: Config = {
  env: 'development',
  domain: 'tuneup-dev.serveo.net',
  //apiDomain: 'api-tuneup-dev.serveo.net',
  apiDomain: 'localhost:3001',
  scheme: 'http',
  google: {
    webClientId: '502499585249-oto44eeeq7bjor24fpuodlraq0g25rpm.apps.googleusercontent.com'
  },
  sentry: {
    dsn: ''
  },
  firebase: devFirebase,
  tuneup: {
    apiKey: '7d49663164aa3516370dd3c7620749508d901fd25f98365804e3e47795edce02',
  }
}

const staging: Config = {
  env: 'staging',
  domain: 'tuneup-staging.web.app',
  apiDomain: 'api-staging.tuneup.cc',
  scheme: 'https',
  google: {
    webClientId: '344241521724-9d9usfc9lcvuh5mb90sl9omt2mp4s1c2.apps.googleusercontent.com'
  },
  sentry: {
    dsn: 'https://3faad2d6f5874776a8df70d13b4a9898@o87934.ingest.sentry.io/4505032694824960'
  },
  firebase: devFirebase,
  tuneup: {
    apiKey: '7d49663164aa3516370dd3c7620749508d901fd25f98365804e3e47795edce02',
  }
}

const production: Config = {
  env: 'production',
  domain: 'tuneup.cc',
  apiDomain: 'api.tuneup.cc',
  scheme: 'https',
  google: {
    webClientId: '104183320887-2s03hadu8r0rf1l39q935pvj1m26epem.apps.googleusercontent.com'
  },
  sentry: {
    dsn: 'https://65549241dc93459ba2c08ed5943331fd@o87934.ingest.sentry.io/4505032997928960'
  },
  firebase: {
    apiKey: "AIzaSyAdQjyIEN8DIXRcVDhBtja2psVG-RYRuu0",
    authDomain: "tuneup-383223.firebaseapp.com",
    projectId: "tuneup-383223",
    storageBucket: "tuneup-383223.appspot.com",
    messagingSenderId: "104183320887",
    appId: "1:104183320887:web:c11cbe7fef0b0ccfbfcab2",
    measurementId: "G-VZCQRDXPP7"
  },
  tuneup: {
    apiKey: '34f97d200cafcb271fe35e23d44c6af90bc3d7a8ac113fd44b8182e1f0ace67e'
  }
}

let config = development

if(name.includes('staging')) {
  config = staging
}
if(name === 'tuneup') {
  config = production
}

export default config
