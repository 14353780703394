import { KeywordId } from '@/types'
import SmallButton from '@/components/SmallButton'
import { useStore, useSelector } from '@/store'
import { useState } from 'react'
import { update } from '@/features/keywords'
import { useTranslation } from '@/i18n'
import { selectModel } from '@/features/generate/selectors'

const GenerateTitlesButton = ({
  keywordId
}: {
  keywordId: KeywordId
}) => {

  const { t } = useTranslation()
  const { dispatch, getState } = useStore()
  const [loading, setLoading] = useState(false)
  const model = useSelector(selectModel)

  const onPress = async () => {
    setLoading(true)
    await update(keywordId, { generateTitles: true, model, })(dispatch, getState)
    setLoading(false)
  }

  return (
    <SmallButton
      testID="button-generate-titles"
      iconName='color-wand-outline'
      loading={loading}
      onPress={onPress}
      label={t('keywords.generateTitles')}
    />
  )

}

export default GenerateTitlesButton
