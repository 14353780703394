import View from '@/components/View'
import AppIconButton from '@/navigation/AppIconButton'
import BackButton from '@/navigation/BackButton'
import { space } from '@/styles/theme'
import { useRoute, useNavigation } from '@/navigation'
import { StyleSheet } from 'react-native'
import Icon from '@/components/Icon'
import TouchableOpacity from '@/components/TouchableOpacity'
import { toggleDrawer } from '@/features/navigation'
import { useStore } from '@/store'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuButton: {
    padding: space[2],
  }
})

const MenuButton = ()=> {
  const { dispatch } = useStore()

  const onPress = ()=> {
    toggleDrawer()(dispatch)
  }

  return(
    <TouchableOpacity style={styles.menuButton} onPress={onPress}>
      <Icon icon='bars' />
    </TouchableOpacity>
  )
}

const HeaderLeft = ({
  canGoBack,
}: {
  canGoBack: boolean
})=> {

  const route = useRoute()
  const navigation = useNavigation()

  const isAtHome = route.name === 'Home'

  const goBack = ()=> {
    if (canGoBack) {
      navigation.goBack()
    }else{
      navigation.reset({ index: 0, routes: [{ name: 'Home' }] })
    }
  }

  return(
    <View style={styles.container}>
      <MenuButton />
      {canGoBack || !isAtHome ? (
        <BackButton onPress={goBack} />
      ) : (
        <AppIconButton />
      )}
    </View>
  )
}

export default HeaderLeft
