import View from '@/components/View'
import { StyleSheet } from 'react-native'
import DocsList from '@/components/docs/List'
import FontIcon from '@/components/FontIcon'
import Link from '@/components/Link'
import { LinkButton } from '@/components/Button'
import Text from '@/components/Text'
import Alert from '@/components/Alert'
import CollectionPicker from '@/components/collections/Picker'
import SyncFeedForm from '@/components/collections/SyncFeedForm'
import CollectionSitemapForm from '@/components/collections/SitemapForm'
import DocURLImport from '@/components/docs/URLImport'

import OptionCard from '@/components/OptionCard'
import TouchableOpacity from '@/components/TouchableOpacity'
import BreadCrumbs from '@/components/Breadcrumbs'

import { space, colors, border } from '@/styles/theme'
import { CollectionId } from '@/types'
import { useTranslation } from '@/i18n'
import { useSelector, RootState, useStore } from '@/store'
import { create } from '@/features/docs'
import { useNavigation } from '@/navigation'
import useLoading from '@/hooks/useLoading'

const styles = StyleSheet.create({
  listHeader: {
    zIndex: 1,
  },

  topContainer: {
    marginTop: space[3],
    marginLeft: space[3],
    marginRight: space[3],
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: space[2],
    // it is required to show the drop down menu over the DocForm,
    // since RN-web inserts index: 0 to every element and it causes issue
    zIndex: 1,
  },

  headerLeft: {
  },

  headerRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  settingButton: {
    backgroundColor: colors.contrast,
    // border
    ...border[1],
    // padding
    paddingLeft: space[1.5],
    paddingRight: space[1.5],
    paddingTop: space[1],
    paddingBottom: space[1],
  },

  emptyAlert: {
    margin: space[2],
  },
  emptyButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: space[1],
  },

  options: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: space[1],
    columnGap: space[2],
    marginBottom: space[2],
  },

})

const SettingsButton = ({
  collectionId,
}: {
  collectionId: CollectionId
}) => {
  return (
    <Link to={`/collections/${collectionId}/settings`}
      style={styles.settingButton}>
      <FontIcon name="settings-outline" />
    </Link>
  )
}

const DocCreate = ({
  collectionId,
}: {
  collectionId: CollectionId
}) => {
  const { t } = useTranslation()
  const { loading, withLoading } = useLoading()
  const { dispatch, getState } = useStore()
  const navigation = useNavigation()

  const submit = async()=> {
    await withLoading(async()=> {
      const { data } = await create({
        collectionId,
        title: '',
      })(dispatch, getState)
      if(data?.doc){
        navigation.navigate('Doc', { docId: data.doc.id })
      }
    })
  }

  return (
    <TouchableOpacity onPress={submit} disabled={loading}>
      <OptionCard
        title={t('docs.create.title')}
        description={t('docs.create.description')}
        icon={(
          <FontIcon name="document-text-outline" />
        )}
        loading={loading}
      />
    </TouchableOpacity>
  )
}


const DocGenerate = ({
  collectionId,
}: {
  collectionId: CollectionId
}) => {
  const { t } = useTranslation()

  return (
    <Link to={`/collections/${collectionId}/keywords`}>
      <OptionCard
        title={t('docs.generate.title')}
        description={t('docs.generate.description')}
        icon={(
          <FontIcon color={colors.blue[500]} name="color-wand-outline" />
        )}
      />
    </Link>
  )

}

const DocImport = ({
  collectionId,
}: {
  collectionId: CollectionId
}) => {
  const { t } = useTranslation()

  return (
    <DocURLImport collectionId={collectionId}>
      <OptionCard
        title={t('docs.urlImport.title')}
        description={t('docs.urlImport.description')}
        icon={(
          <FontIcon name="link-outline" />
        )}
      />
    </DocURLImport>
  )

}

const ListHeader = ({
  name,
  collectionId,
}: {
  name: string,
  collectionId: CollectionId,
}) => {

  const list = [
    { label: name },
  ]

  return(
    <View style={styles.topContainer}>
      <View style={styles.header}>
        <BreadCrumbs list={list} />

        <View style={styles.headerRight}>
          <View style={{ marginRight: space[1]}} >
            <CollectionPicker collectionId={collectionId}/>
          </View>
          <SettingsButton collectionId={collectionId} />
        </View>
      </View>

      <View style={styles.options}>
        <DocGenerate collectionId={collectionId} />
        <DocCreate collectionId={collectionId} />
        <DocImport collectionId={collectionId} />
      </View>

    </View>
  )
}

const ListEmpty = ({
  collectionId,
}: {
  collectionId: CollectionId,
}) => {
  const { t } = useTranslation()
  return (
    <Alert
      type='info'
      title={t('docs.list.empty.title')}
      style={styles.emptyAlert}
    >

      <Text body2 style={{ marginBottom: space[1] }}>{t('docs.list.empty.description')}</Text>
      <SyncFeedForm collectionId={collectionId} />
      <CollectionSitemapForm collectionId={collectionId} />

      <Text body2>{t('docs.list.empty.descriptionImport')}</Text>
      <View style={styles.emptyButtonContainer}>
        <LinkButton
          to={`/collections/${collectionId}/settings`}
          label={t('collections.settings.import.title')}
        />
      </View>
    </Alert>
  )
}

const CollectionShow = ({
  collectionId,
}: {
  collectionId: CollectionId
}) => {

  const { t } = useTranslation()
  const name = useSelector((state: RootState)=> state.entities?.collections[collectionId]?.name)
    || t('collections.item.unnamed') || ''

  return (
    <DocsList
      // key is required to reset the state of the list
      // otherwise, it will re use the previous state (DocCreteForm, list of items)
      key={collectionId}
      collectionId={collectionId}
      ListHeaderComponent={<ListHeader name={name} collectionId={collectionId} />}
      ListHeaderComponentStyle={styles.listHeader}
      ListEmptyComponent={<ListEmpty collectionId={collectionId} />}
    />
  )
}

export default CollectionShow
