import { AppDispatch, GetState, ClearAction } from '@/store'

export const add  = (message: MessageParam) => {
  return (dispatch: AppDispatch, getState: GetState) => {
    dispatch({ type: 'flash/add', payload: message })
  }
}

export const remove = (id: number) => {
  return (dispatch: AppDispatch, getState: GetState) => {
    dispatch({ type: 'flash/remove', payload: id })
  }
}

interface MessageParam {
  type: 'success' | 'error' | 'warning'
  text: string
}
type Message = MessageParam & {
  id: number
}
type State = {
  messages: Message[]
}

const initialState: State = {
  messages: []
}

type AddAction = {
  type: 'flash/add'
  payload: MessageParam
}
type RemoveAction = {
  type: 'flash/remove'
  payload: number
}
type Action = AddAction | RemoveAction | ClearAction

export const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case 'flash/add': {
      const lastFlash = state.messages[state.messages.length - 1]
      const id = lastFlash ? lastFlash.id + 1 : 1

      // don't add if the last flash had same message
      if (lastFlash && lastFlash.text === action.payload.text) {
        return state
      }

      return {
        ...state,
        messages: [
          ...state.messages,
          { ...action.payload, id }
        ]
      }
    }

    case 'flash/remove': {
      return {
        ...state,
        messages: state.messages.filter((message) => message.id !== action.payload)
      }
    }

    case 'all/clear': { return initialState }
    default:
      return state
  }
}
