import { DocId } from '@/types'
import InputArea from '@/components/fragments/InputArea'
import OutputArea from '@/components/fragments/OutputArea'
import { space } from '@/styles/theme'
import { useState } from 'react'
import { create } from '@/features/fragments'
import { useStore } from '@/store'
import { useTranslation } from '@/i18n'
import View from '@/components/View'
import useLoading from '@/hooks/useLoading'
import SmallButton from '@/components/SmallButton'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  button: {
    marginTop: space[1],
  }
})

const FragmentCreateForm = ({
  docId,
}: {
  docId: DocId
})=> {

  const { dispatch, getState } = useStore()
  const { t } = useTranslation()

  const [input, setInput] = useState('')
  const [output, setOutput] = useState('')
  const { loading, withLoading } = useLoading()

  const handleSubmit = async()=> {
    await withLoading(async()=> {
      await handleCreate()
    })
  }

  const handleCreate = async()=> {
    await create({
      docId,
      input,
      output,
      // create without output -> GPT generate
      // create with output -> Only save
    })(dispatch, getState)
    setInput('')
    setOutput('')
  }

  return (
    <>
      <View style={{ marginBottom: space[2] }}>
        <InputArea
          value={input}
          onChangeText={setInput}
          onPressEnter={handleSubmit}
          caption={t('fragments.form.input.caption')}
        />
        {output ? null : (
          <SmallButton
            iconName='color-wand-outline'
            onPress={handleSubmit}
            style={styles.button}
            label={t('fragments.form.button.generate')}
            loading={loading}
            disabled={!input}
          />
        )}
      </View>
      <OutputArea value={output} onChangeText={setOutput} />
      {output ? (
        <SmallButton
          iconName='checkmark-outline'
          onPress={handleSubmit}
          style={styles.button}
          label={t('fragments.form.button.save')}
          loading={loading}
          disabled={!input}
        />
      ) : null}
    </>
  )
}

export default FragmentCreateForm
