import { OrgId } from '@/types'
import View from '@/components/View'
import { orgRole } from '@/features/entities/selectors'
import { RootState, useStore, useSelector } from '@/store'
import Button from '@/components/Button'
import { useTranslation } from '@/i18n'
import { colors, space } from '@/styles/theme'
import Text from '@/components/Text'
import { post } from '@/features/requests'
import api from '@/api'
import { OrgPaymentPortal } from '@/types/response'
import { add } from '@/features/flash'
import useLoading from '@/hooks/useLoading'

const PaymentSettings = ({
  orgId
}:{
  orgId: OrgId
}) => {

  const { dispatch, getState } = useStore()
  const { t } = useTranslation()
  const role = useSelector((state: RootState )=> orgRole(state, orgId))
  const hasValidPayment = useSelector((state: RootState )=> state.entities?.orgs[orgId]?.hasValidPayment)
  const isValid = !!hasValidPayment
  const { loading, withLoading } = useLoading()

  const onPress = async()=> {
    await withLoading(async()=> {
      const response = await post(api.orgs.paymentPortal(orgId))(dispatch, getState)
      const json: OrgPaymentPortal = await response.json()
      const { url } = json
      if(!url) {
        add({
          type: 'error',
          text: t('settings.payment.portal.error')
        })(dispatch, getState)
        return
      }
      window.location.href = url
    })
  }

  if(role !== 'owner') return null
  return (
    <View testID='payment-status' style={{ marginTop: space[2] }}>
      <Text label style={{ marginBottom: space[1] }}>
        {t('settings.payment.title')}
      </Text>
      <Button testID={'button-billing-portal'}
        label={isValid ? t('settings.payment.update') : t('settings.payment.add')}
        onPress={onPress}
        danger={!isValid}
        loading={loading}
      />
    </View>
  )
}

export default PaymentSettings
