
import { requests } from '@/features/requests'
import api from '@/api'
import {
  Fragment,
  FragmentId,
  DocId,
} from '@/types'
import { FragmentsIndex, FragmentShow } from '@/types/response'
import {
  setMany,
  set,
  append,
} from '@/features/entities'
import { AppDispatch, GetState } from '@/store'

export type UpdateParams = {
  input?: Fragment['input']
  output?: Fragment['output']
  isChecked?: Fragment['isChecked']
  continue?: boolean
}
type CreateParams = UpdateParams & {
  docId: DocId
}

export const create = (fragment: CreateParams)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<FragmentShow> => {

    const model = getState().generate.model

    const response = await requests.post(
      api.fragments.create(),
      { ...fragment, model }
    )(dispatch, getState)
    const json = await response.json() as FragmentShow
    set('fragments', json.fragment)(dispatch)
    if(json.fragment?.id) {
      append('fragments', json.fragment.id, fragment.docId)(dispatch)
    }
    // polling will be automatically started by fragments/UpdateForm
    return json
  }
}

export const read = (id: FragmentId)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<FragmentShow> => {
    const docId = getState().entities?.fragments?.[id]?.docId
    const response = await requests.get(api.fragments.show(id), { docId })(dispatch, getState)
    const json = await response.json() as FragmentShow
    set('fragments', json.fragment)(dispatch)
    return json
  }
}

export const poll = (id: FragmentId)=> {
  return async(dispatch: AppDispatch, getState: GetState) => {
    await requests.poll({
      reader: async()=> await read(id)(dispatch, getState),
      taker: (json: FragmentShow)=> json.fragment?.updatingFrom
    })
  }
}

export const update = (id: FragmentId, payload: UpdateParams)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<FragmentShow> => {
    const model = getState().generate.model

    const response = await requests.post(
      api.fragments.put(id),
      { ...payload, model }
    )(dispatch, getState)
    const json = await response.json() as FragmentShow
    set('fragments', json.fragment)(dispatch)
    // polling will be automatically started by fragments/UpdateForm
    return json
  }
}

export const index = (params: { docId: string })=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<FragmentsIndex> => {
    const response = await requests.get(
      api.fragments.index(),
      { docId: params.docId }
    )(dispatch, getState)
    const json = await response.json() as FragmentsIndex
    setMany('fragments', json.fragments)(dispatch)
    return json
  }
}

export const remove = (id: FragmentId)=> {
  return async(dispatch: AppDispatch, getState: GetState) => {
    const fragment = getState().entities?.fragments?.[id]
    const docId = fragment?.docId
    if(!docId) return

    const doc = getState().entities?.docs?.[fragment?.docId]
    if(!doc) return

    const response = await requests.remove(api.fragments.remove(id), { docId } )(dispatch, getState)

    const updatedDoc = {
      ...doc,
      fragments: doc?.fragments?.filter((fragmentId: FragmentId)=> fragmentId !== id),
    }
    set('docs', updatedDoc)(dispatch)

    return response
  }
}

export const fragments = {
  create,
}


