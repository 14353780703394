import Cookies, { CookieAttributes } from 'js-cookie'

const defaultOptions: CookieAttributes = {
  ...(__DEV__ ? {} : {
    domain: '',
    expires: 180,
    secure: true,
    sameSite: 'lax',
  }),
}

const SecureStorage = {

  setItemAsync: async (key:string, value:string, options={})=> {
    return Cookies.set(key, value, {
      ...defaultOptions,
      ...options
    })
  },

  getItemAsync: async (key:string, options={})=> {
    return Cookies.get(key)
  },

  deleteItemAsync: async(key:string, options={}) => {
    return Cookies.remove(key, {
      ...defaultOptions,
      ...options
    })

  }

}

export default SecureStorage
