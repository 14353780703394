import { useSelector, RootState } from '@/store'
import View from '@/components/View'
import FlatList from '@/components/FlatList'
import FragmentItem from '@/components/fragments/Item'
import AddFragment from '@/components/fragments/AddFragment'
import { DocId } from '@/types'
import { space } from '@/styles/theme'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  item: {
    marginTop: space[3],
    marginLeft: space[3],
    marginRight: space[3],
  },
  bottom: {
    marginBottom: space[3],
  },
})

const Fragments = ({
  docId,
  loading,
  ListHeaderComponent,
}: {
  docId: DocId
  loading: boolean | undefined
  ListHeaderComponent?: any
})=> {

  const ids = useSelector((state: RootState)=> state.entities?.docs?.[docId]?.fragments)

  const renderItem = ({ item }: { item: unknown })=> {
    return(
      <View style={styles.item}>
        <FragmentItem fragmentId={String(item)} />
      </View>
    )
  }

  return (
    <FlatList
      data={ids}
      keyExtractor={(item)=> String(item)}
      renderItem={renderItem}
      loading={loading}
      ListHeaderComponent={loading ? null : (
        ListHeaderComponent
      )}
      ListFooterComponent={loading ? null : (
        <View style={[styles.item, styles.bottom]}>
          <AddFragment docId={docId} />
        </View>
      )}
    />
  )
}

export default Fragments
