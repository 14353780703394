import Linking from '@/lib/ExpoLinking'

const prefix = Linking.createURL('/')

const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      Landing: '/',
      Signup: '/auth/signup',
      Login: '/auth/login',
      Home: '/home',
      Collection: '/collections/:collectionId',
      CollectionSettings: '/collections/:collectionId/settings',
      CollectionKeywords: '/collections/:collectionId/keywords',
      Doc: '/docs/:docId',
      OrgSettings: '/orgs/:orgId/settings',
      OrgCollectionNew: '/orgs/:orgId/collections/new',
      Invite: '/orgs/:orgId/invites/:inviteId',
      Keyword: '/keywords/:keywordId',
      Draft: '/drafts/:draftId',

      Developers: '/developers',

      Inquiry: '/inquiry',

      Commerce: '/legal/commerce',
      Privacy: '/legal/privacy',
      Terms: '/legal/terms',

      NotFound: '*',
    },
  },
}

export default linking
