
import { StyleSheet, ViewStyle, ActivityIndicator } from 'react-native'
import FontIcon from '@/components/FontIcon'
import Text from '@/components/Text'
import TouchableOpacity from '@/components/TouchableOpacity'
import { colors, card, space, iconSize } from '@/styles/theme'

export const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    borderRadius: space[1],
    paddingLeft: space[2],
    paddingRight: space[2],
    paddingTop: space[1],
    paddingBottom: space[1],
    backgroundColor: colors.contrast,
  },
  text: {
    marginLeft: space[0.5],
  }
})

const SmallButton = ({
  onPress,
  style,
  iconName,
  label,
  loading,
  disabled,
  color,
  danger,
}: {
  onPress: ()=> void,
  style?: ViewStyle
  iconName: string,
  label?: string | null,
  loading?: boolean,
  disabled?: boolean,
  color?: string,
  danger?: boolean,
  testID?: string,
})=> {

  const handlePress = ()=> {
    if(loading || disabled) return

    onPress()
  }

  let col = colors.grey[900]
  if(color) {
    col = color
  }
  if(danger) {
    col = colors.red[600]
  }

  return (
    <TouchableOpacity
      onPress={handlePress}
      disabled={disabled || loading}
      style={[
        styles.button,
        card.button,
        style
      ]}
    >
      {loading ? (
        <ActivityIndicator size={iconSize.small} color={colors.grey[900]} />
      ) : (
        <FontIcon name={iconName} color={col} />
      )}

      {label ? (
        <Text caption
          style={[
            styles.text,
            { color: col }
          ]}
        >{label}</Text>
      ) : null}

    </TouchableOpacity>
  )
}

export default SmallButton
