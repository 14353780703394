import { Link as NativeLink } from '@react-navigation/native'
import { TextProps, Text } from 'react-native'

type Props = {
  to: string
} & TextProps

const Link = ({
  to,
  children,
  style
}: Props) => {

  if(to.includes('undefined')) return <Text style={style}>{children}</Text>

  return (
    <NativeLink
      to={to}
      style={style}
    >
      {children}
    </NativeLink>
  )
}

export { Link as NativeLink } from '@react-navigation/native'
export default Link
