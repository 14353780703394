
import {
  View,
  StyleSheet
} from 'react-native'
import { space, colors } from '@/styles/theme'

const styles = StyleSheet.create({
  box: {
    marginBottom: space[2],
    padding: space[3],
    backgroundColor: colors.contrast,
    borderRadius: space[2],
  },
})

const Card = ({
  children,
}: {
  children: React.ReactNode
}) => {

  return (
    <View style={styles.box}>
      {children}
    </View>
  )
}

export default Card
