import Sentry from '@/sentry'
import { readAuth, parse } from '@/features/auth'

type AnyObject = {
  [key: string]: any
}
export const capture = async(
  error: Error,
  extra?: AnyObject
) => {

  const jwt = await readAuth()
  const auth = jwt ? parse(jwt) : {}

  Sentry.withScope((scope) => {
    scope.setTag("captureHandled", "true")
    scope.setLevel('error')

    if (extra) {
      for (const key in extra) {
        scope.setExtra(key, extra[key])
      }
    }

    // do not send anything sensitive
    const id = auth.id
    const exp = auth.exp

    scope.setUser({
      id,
      token: { exp },
    })

    p("capture", error, extra)
    Sentry.captureException(error)
  })
}

export default { capture }
