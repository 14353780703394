import { CollectionId } from '@/types'
import TextInput from '@/components/TextInput'
import SmallButton from '@/components/SmallButton'
import { StyleSheet } from 'react-native'
import { space } from '@/styles/theme'
import { useTranslation } from '@/i18n'
import { useState } from 'react'
import useLoading from '@/hooks/useLoading'
import { create } from '@/features/keywords'
import { useStore } from '@/store'
import { useNavigation } from '@/navigation'

const styles = StyleSheet.create({
  button: {
    alignSelf: 'flex-end',
    marginTop: space[1],
  }
})

const KeywordForm = ({
  collectionId
}: {
  collectionId: CollectionId
}) => {

  const navigation = useNavigation()
  const { dispatch, getState } = useStore()
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [target, setTarget] = useState('')

  const { loading, withLoading } = useLoading()

  const submit = async() => {
    await withLoading(async() => {
      const data = await create({ collectionId, value, target, })(dispatch, getState)
      if(data?.keyword?.id){
        navigation.navigate('Keyword', { keywordId: data.keyword.id })
      }
    })
  }

  return(
    <>
      <TextInput required testID='input-keyword-value'
        label={t('keywords.new.value.label')}
        placeholder={t('keywords.new.value.placeholder')}
        value={value}
        onChangeText={setValue}
      />

      <TextInput optional testID='input-keyword-target'
        label={t('keywords.new.target.label')}
        placeholder={t('keywords.new.target.placeholder')}
        value={target}
        onChangeText={setTarget}
      />

      <SmallButton testID='button-keyword-submit'
        iconName='color-wand-outline'
        label={t('keywords.new.button')}
        onPress={submit}
        loading={loading}
        style={styles.button}
      />
    </>
  )

}

export default KeywordForm
