import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { library, IconProp } from '@fortawesome/fontawesome-svg-core'
import { colors } from '@/styles/theme'

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faArrowTurnDown } from '@fortawesome/free-solid-svg-icons/faArrowTurnDown'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faFileLines } from '@fortawesome/free-solid-svg-icons/faFileLines'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faRepeat } from '@fortawesome/free-solid-svg-icons/faRepeat'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan'
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons/faWandMagicSparkles'
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark'
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear'
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'

library.add(
  faArrowLeft,
  faArrowTurnDown,
  faCheck,
  faCheckCircle,
  faFileLines,
  faPlus,
  faRepeat,
  faTrashCan,
  faWandMagicSparkles,
  faXmark,
  faGear,
  faInfo,
  faBars,
)

const Icon = ({
  icon,
  size,
  color,
  style,
}: {
  icon: IconProp,
  size?: number,
  color?: string,
  style?: any,
}) => {

  return (
    <FontAwesomeIcon
      icon={icon}
      color={color ? color : colors.grey[600]}
      size={size}
      style={{
        // @ts-ignore it's for web
        outlineWidth: '0.5px',
        outlineOffset: '5px',
        outlineColor: colors.grey[500],
        ...style,
      }}
    />
  )

}

export type { IconProp } from '@fortawesome/fontawesome-svg-core'
export default Icon
