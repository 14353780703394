import * as Sentry from 'sentry-expo'
import config from '@/config'

Sentry.init({
  dsn: config.sentry.dsn,
  enableInExpoDevelopment: __DEV__,
  debug: __DEV__,
})

export default Sentry.Browser
