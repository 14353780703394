
import View from '@/components/View'
import Text from '@/components/Text'
import Link from '@/components/Link'
import { space } from '@/styles/theme'
import { useTranslation } from '@/i18n'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  item: {
    marginRight: space[2],
  }
})

const Footer = () => {

  const { t } = useTranslation()
  const thisYear = new Date().getFullYear()

  return(
    <View style={{
      marginTop: space[4],
      marginLeft: space[2],
      paddingBottom: space[4],
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    }}>
      <Text light label style={styles.item}>
        © {thisYear} {t('general.appName')}
      </Text>
      <Link to="/legal/terms" style={styles.item}>
        <Text light link label>
          {t('general.terms')}
        </Text>
      </Link>
      <Link to="/legal/privacy" style={styles.item}>
        <Text light link label>
          {t('general.privacy')}
        </Text>
      </Link>
      <Link to="/legal/commerce" style={styles.item}>
        <Text light link label>
          {t('general.commerce')}
        </Text>
      </Link>
    </View>
  )
}

export default Footer
