import { useStore, useSelector, RootState } from '@/store'
import View from '@/components/View'
import Link from '@/components/Link'
import Text from '@/components/Text'
import { DocId } from '@/types'
import { StyleSheet, ViewStyle } from 'react-native'
import { card, space } from '@/styles/theme'
import IconButton from '@/components/IconButton'
import { remove } from '@/features/docs'
import { useState } from 'react'
import Alert from '@/lib/Alert'
import { useTranslation } from '@/i18n'
import { add } from '@/features/flash'
import FontIcon from '@/components/FontIcon'
import Timestamp from '@/components/Timestamp'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: space[1],

    flex: 1,
    ...card.listItem,
  },

  link: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: space[2],
    width: '100%'
  },

  titleText: {
    marginLeft: space[1],
    flexWrap: 'wrap',
  },

  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  metadata: {
    width: '100%',
    marginTop: space[0.5],
  }
})

const ListItem = ({
  id,
  style,
}: {
  id: DocId,
  style?: ViewStyle,
}) => {
  const { t } = useTranslation()
  const { dispatch, getState } = useStore()

  const title = useSelector((state: RootState) => state.entities?.docs?.[id]?.title)
  const externalId = useSelector((state: RootState) => state.entities?.docs?.[id]?.externalId)
  const externalCreatedAt = useSelector((state: RootState) => state.entities?.docs?.[id]?.externalCreatedAt)
  const [isRemoved, setIsRemoved] = useState(false)

  const confirmRemove = () => {
    Alert.alert(
      t('alert.confirmRemove.title'),
      t('alert.confirmRemove.message') || '',
      [
        { text: t('alert.cancel') || '', style: 'cancel', },
        {
          text: t('alert.confirmRemove.ok') || '',
          onPress: handleRemove,
        },
      ],
    )
  }

  const handleRemove = async() => {
    await remove(id)(dispatch, getState)
    add({
      type: 'success',
      text: t('flash.docs.remove.success'),
    })(dispatch, getState)
    setIsRemoved(true)
  }

  if(isRemoved) return null

  return(
    <View style={[
      styles.container,
      style
    ]}>

      <View style={styles.link} >
        <Link to={`/docs/${id}`}>
          <View style={styles.title}>
            <FontIcon name='document-text-outline' />
            <Text style={styles.titleText} label={!title}>
              {title || t('docs.item.unnamed')}
            </Text>
          </View>
          {externalId && (
            <View style={styles.metadata}>
              <Text caption>{t('docs.item.externalId')}: {externalId}</Text>
            </View>
          )}
          {externalCreatedAt && (
            <View style={styles.metadata}>
              <Text caption>{t('docs.item.externalCreatedAt')}: <Timestamp textProps={{ caption: true }} at={externalCreatedAt}/></Text>
            </View>
          )}
        </Link>
      </View>

      <IconButton
        name='trash-outline'
        onPress={confirmRemove}
        style={{ height: '100%',  }}
      />
    </View>
  )

}

export default ListItem
