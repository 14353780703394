import { useState, useEffect } from 'react'
import { CollectionId } from '@/types'
import View from '@/components/View'
import Text from '@/components/Text'
import TextInput from '@/components/TextInput'
import Button from '@/components/Button'
import Alert from '@/components/Alert'
import { space } from '@/styles/theme'
import { StyleSheet } from 'react-native'
import SyncButton from '@/components/collections/SyncButton'

import { useStore } from '@/store'
import { useTranslation } from '@/i18n'
import useLoading from '@/hooks/useLoading'
import { update } from '@/features/collections'
import useEntityValue from '@/hooks/useEntityValue'

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  }
})

const SyncFeedForm = ({
  collectionId,
}: {
  collectionId: CollectionId
}) => {

  const { t } = useTranslation()
  const { dispatch, getState } = useStore()

  const remoteSitemapUrl = useEntityValue('collections', collectionId, 'sitemapUrl') || ''
  const [sitemapUrl, setSitemapUrl] = useState(remoteSitemapUrl)
  useEffect(()=> setSitemapUrl(remoteSitemapUrl), [remoteSitemapUrl])

  const [isChanged, setChanged] = useState(false)

  const { loading, withLoading } = useLoading()

  const submit = async()=> {
    await withLoading(async()=> {
      await update(collectionId, {
        sitemapUrl,
      })(dispatch, getState)
      setChanged(false)
    })
  }

  const onChange = (sitemapUrl: string)=> {
    setSitemapUrl(sitemapUrl)
    setChanged(sitemapUrl !== remoteSitemapUrl)
  }

  return (
    <>
      <Text label style={{ marginBottom: space[1] }}>
        {t('collections.settings.sitemapUrl.title')}
      </Text>
      <TextInput testID='input-sitemap-url'
        value={sitemapUrl}
        onChangeText={onChange}
        style={{ marginBottom: space[1] }}
        placeholder={t('collections.settings.sitemapUrl.placeholder')}
      />
      {isChanged ? (
        <>
          <Alert testID='alert-data'
            type='warning'
            title={t('collections.settings.sitemapUrl.data.title')}
            style={{ marginBottom: space[1] }}
            description={t('collections.settings.sitemapUrl.data.description')}
          />
          <Alert testID='alert-liability'
            type='warning'
            title={t('collections.settings.syncUrl.liability.title')}
            style={{ marginBottom: space[1] }}
            description={t('collections.settings.syncUrl.liability.description')}
          />
        </>
      ) : null}
      <View style={styles.buttons}>
        {sitemapUrl && !isChanged ? (
          <View style={{ marginRight: space[1] }}>
            <SyncButton collectionId={collectionId} type='sitemap' />
          </View>
        ) : null}
        {isChanged ? (
          <Button small testID='button-submit'
            variant="contained"
            onPress={submit}
            loading={loading}
            label={t('general.save')}
          />
        ) : null}
      </View>
    </>
  )
}

export default SyncFeedForm
