import { ViewStyle } from 'react-native'
import { index } from '@/features/docs'
import { useStore } from '@/store'
import FlatList from '@/components/FlatList'
import {
  DocId,
  CollectionId,
} from '@/types'
import View from '@/components/View'
import ListItem from '@/components/docs/ListItem'
import { space } from '@/styles/theme'
import usePaginate from '@/hooks/usePaginate'

const keyExtractor = (item: unknown)=> String(item)

const renderItem = ({ item }: { item: unknown })=> {
  return (
    <View style={{ marginLeft: space[3], marginRight: space[3] }}>
      <ListItem id={item as DocId} />
    </View>
  )
}

const DocsList = ({
  collectionId,
  ListHeaderComponent,
  ListHeaderComponentStyle,
  ListEmptyComponent,
}: {
  collectionId: CollectionId,
  ListHeaderComponent?: any,
  ListHeaderComponentStyle?: ViewStyle
  ListEmptyComponent?: any,
}) => {

  const { dispatch, getState } = useStore()

  const {
    loading,
    ids,
    read,
  } = usePaginate(
    (next)=> index(collectionId, next)(dispatch, getState),
    'docs',
    [collectionId],
  )

  return (
    <FlatList testID='list-docs'
      data={ids}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      onEndReached={read}
      ListHeaderComponent={ListHeaderComponent}
      ListHeaderComponentStyle={ListHeaderComponentStyle}
      ListEmptyComponent={ListEmptyComponent}
      loading={loading}
    />
  )

}

export default DocsList
