import { UUID } from '@/types'
import View from '@/components/View'
import Text from '@/components/Text'
import PageContainer from '@/components/layouts/PageContainer'
// @ts-ignore platform specific codes
import AuthGoogleButton from '@/components/auth/GoogleButton'
import { useTranslation } from '@/i18n'
import { space } from '@/styles/theme'
import { InviteProps } from '@/navigation/types'

const Invite = ({
  route,
}: InviteProps) => {

  const { inviteId, orgId } = route.params
  const { t } = useTranslation()

  return (
    <PageContainer>

      <View style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: space[4],
      }}>
        <Text style={{
          marginBottom: space[2]
        }}>
          {t('inviteMember.joinOrg')}
        </Text>

        <AuthGoogleButton orgId={orgId} inviteId={inviteId} />
      </View>
    </PageContainer>
  )

}

export default Invite
