import { requests } from '@/features/requests'
import api from '@/api'
import { CollectionId, KeywordId, ChatModel } from '@/types'
import { KeywordShow, KeywordsIndex } from '@/types/response'
import { set, setMany } from '@/features/entities'
import { add } from '@/features/flash'
import i18n from '@/i18n'
import { AppDispatch, GetState } from '@/store'

type CreateParams = {
  value: string
  collectionId: CollectionId
  target?: string
}

export const create = (params: CreateParams)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<KeywordShow|null> => {
    const response = await requests.post(api.keywords.create(), params)(dispatch, getState)
    if(response.ok){
      const json: KeywordShow = await response.json()
      if(json.keyword){
        set('keywords', json.keyword)(dispatch)
        add({ type: 'success', text: i18n.t('keywords.create.success') })(dispatch, getState)
      }
      return json
    }else{
      return null
    }
  }
}

type UpdateParams = {
  value?: string
  target?: string
  generateTitles?: boolean
  model?: ChatModel
}

export const update = (
  id: KeywordId,
  params: UpdateParams
)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<KeywordShow> => {
    const response = await requests.post(api.keywords.update(id), params)(dispatch, getState)
    const json: KeywordShow = await response.json()
    set('keywords', json.keyword)(dispatch)
    add({ type: 'success', text: i18n.t('keywords.update.success') })(dispatch, getState)
    return json
  }
}

export const read = (id: KeywordId)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<KeywordShow> => {
    const response = await requests.get(api.keywords.show(id))(dispatch, getState)
    const json: KeywordShow = await response.json()
    set('keywords', json.keyword)(dispatch)
    return json
  }
}

export const poll = (id: KeywordId) => {
  return async(dispatch: AppDispatch, getState: GetState) => {
    await requests.poll({
      reader: async()=> await read(id)(dispatch, getState),
      taker: (json: KeywordShow)=> json.keyword?.updatingFrom
    })
  }
}

export const index = (collectionId: CollectionId, nextPageKey?: KeywordsIndex['nextPageKey'])=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<KeywordsIndex> => {
    const response = await requests.get(
      api.keywords.index(),
      {
        collectionId,
        nextPageKey: JSON.stringify(nextPageKey)
      }
    )(dispatch, getState)
    const json: KeywordsIndex = await response.json()
    if(json.keywords) {
      setMany('keywords', json.keywords)(dispatch)
    }
    return json
  }
}
