import ScrollablePage from '@/components/layouts/ScrollablePage'
import Card from '@/components/layouts/Card'
import Text from '@/components/Text'
import View from '@/components/View'
import InviteMember from '@/components/settings/InviteMember'

import TouchableOpacity from '@/components/TouchableOpacity'
import PageWithDrawer from '@/components/layouts/PageWithDrawer'
import PaymentSettings from '@/components/payments/Settings'
import UsageList from '@/components/orgs/UsageList'

import { space } from '@/styles/theme'
import { useTranslation } from '@/i18n'
import { OrgSettingsProps } from '@/navigation/types'
import { logout } from '@/features/auth'
import { useNavigation } from '@/navigation'
import { useStore } from '@/store'

const Settings = ({
  route
}: OrgSettingsProps) => {

  const navigation = useNavigation()
  const { t } = useTranslation()
  const { orgId } = route.params
  const { dispatch, getState } = useStore()

  const handleLogout = async()=> {
    await logout()(dispatch, getState)
    navigation.navigate('Landing')
  }

  return (
    <PageWithDrawer>
      <ScrollablePage>
        <Text h2 style={{ marginBottom: space[2] }}>
          {t('orgs.settings.title')}
        </Text>

        <Card>
          <Text light>{t('orgs.list.title')} ID: {orgId}</Text>

          <UsageList orgId={orgId} />

          <PaymentSettings orgId={orgId} />

          <InviteMember orgId={orgId} />

          <View style={{ marginTop: space[2] }}>
            <TouchableOpacity onPress={handleLogout}>
              <Text label link>{t('general.logout')}</Text>
            </TouchableOpacity>
          </View>
        </Card>
      </ScrollablePage>
    </PageWithDrawer>
  )
}

export default Settings
