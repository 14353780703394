import { getAuth } from '@/features/auth'
import { useEffect, useState } from 'react'
import { useSelector, RootState, useStore } from '@/store'

const useAuth = () => {

  const { dispatch, getState } = useStore()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const storedJwt = useSelector((state: RootState) => state.auth.jwt)

  useEffect(() => {
    const checkAuth = async() => {
      const jwt = await getAuth()(dispatch, getState)
      setIsLoggedIn(!!jwt)
      setLoaded(true)
    }
    checkAuth()
  } , [])

  // detect stored jwt changes
  useEffect(() => {
    if(!loaded) return
    setIsLoggedIn(!!storedJwt)
  }, [storedJwt])

  return { isLoggedIn, loaded }

}

export default useAuth
