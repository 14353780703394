import { requests, SafeResponse } from '@/features/requests'
import api from '@/api'
import { setMany } from '@/features/entities'
import { OrgsIndex } from '@/types/response'
import { AppDispatch, GetState } from '@/store'
import { OrgId } from '@/types'

export const index = ()=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<SafeResponse> => {
    const response = await requests.get(api.orgs.index())(dispatch, getState)
    if(response.ok){
      const json: OrgsIndex = await response.json()
      setMany('orgs', json.orgs)(dispatch)
    }
    return response
  }
}

export const usage = (orgId: OrgId)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<SafeResponse> => {
    const response = await requests.get(api.orgs.usage(orgId))(dispatch, getState)
    return response
  }
}

