import {
  OrgId,
  CollectionId,
  DocId,
  FragmentId,
  AccessTokenId,
  KeywordId,
  DraftId,
} from '@/types'
import Config from '@/config'
export const host = `${Config.scheme}://${Config.apiDomain}`
const base = host + '/v1'

interface API {

  orgs: {
    index: ()=> string
    invite: (id: OrgId)=> string
    paymentPortal: (id: OrgId)=> string
    usage: (id: OrgId)=> string
  },

  collections: {
    update: (id: CollectionId)=> string
    create: () => string
    sync: (id: CollectionId)=> string
    show: (id: CollectionId)=> string
    remove: (id: CollectionId)=> string
  },

  docs: {
    create: () => string
    show: (id: DocId) => string
    index: () => string
    update: (id: DocId) => string
    remove: (id: DocId) => string
    sync: (id: DocId) => string
  },

  fragments: {
    create: ()=> string
    show: (id: FragmentId)=> string
    index: ()=> string
    put: (id: FragmentId)=> string
    remove: (id: FragmentId)=> string
  },

  accessTokens: {
    create: ()=> string
    remove: (id: AccessTokenId)=> string
    update: (id: AccessTokenId)=> string
  },

  keywords: {
    create: ()=> string
    update: (id: KeywordId)=> string
    show: (id: KeywordId)=> string
    index: ()=> string
  },

  drafts: {
    update: (id: DraftId)=> string
    show: (id: DraftId)=> string
  },

  auth: {
    google: ()=> string
  },

  inquiries: {
    create: ()=> string
  },
}

const api: API = {

  orgs: {
    index: ()=> `${base}/orgs`,
    invite: (id: OrgId)=> `${base}/orgs/${id}/invite`,
    paymentPortal: (id: OrgId)=> `${base}/orgs/${id}/payment_portal`,
    usage: (id: OrgId)=> `${base}/orgs/${id}/usage`,
  },

  collections: {
    update: (id: CollectionId)=> `${base}/collections/${id}`,
    create: ()=> `${base}/collections`,
    sync: (id: CollectionId)=> `${base}/collections/${id}/sync`,
    show: (id: CollectionId)=> `${base}/collections/${id}`,
    remove: (id: CollectionId)=> `${base}/collections/${id}`,
  },

  docs: {
    create: ()=> `${base}/docs`,
    show: (id: DocId)=> `${base}/docs/${id}`,
    index: ()=> `${base}/docs`,
    update: (id: DocId)=> `${base}/docs/${id}`,
    remove: (id: DocId)=> `${base}/docs/${id}`,
    sync: (id: DocId)=> `${base}/docs/${id}/sync`,
  },

  fragments: {
    create: ()=> `${base}/fragments`,
    show: (id: FragmentId)=> `${base}/fragments/${id}`,
    index: ()=> `${base}/fragments`,
    put: (id: FragmentId)=> `${base}/fragments/${id}`,
    remove: (id: FragmentId)=> `${base}/fragments/${id}`,
  },

  accessTokens: {
    create: ()=> `${base}/access_tokens`,
    remove: (id: AccessTokenId)=> `${base}/access_tokens/${id}`,
    update: (id: AccessTokenId)=> `${base}/access_tokens/${id}`,
  },

  keywords: {
    create: ()=> `${base}/keywords`,
    update: (id: KeywordId)=> `${base}/keywords/${id}`,
    show: (id: KeywordId)=> `${base}/keywords/${id}`,
    index: ()=> `${base}/keywords`,
  },

  drafts: {
    update: (id: DraftId)=> `${base}/drafts/${id}`,
    show: (id: DraftId)=> `${base}/drafts/${id}`,
  },

  auth: {
    google: ()=> `${base}/auth/google`,
  },

  inquiries: {
    create: ()=> `${base}/inquiries`,
  },

}

export default api
