import { AppDispatch, RootState, ClearAction } from '@/store'

import type { DraftDoc } from '@/features/imports/parser/src/types'
import { xmlToJSON } from '@/features/imports/parser/src/xml'
import { Atom, atomToDraftDocs } from '@/features/imports/parser/src/atom'
import { RSS, rssToDraftDocs } from '@/features/imports/parser/src/rss'

export const jsonToDocs = async(json: Atom | RSS): Promise<DraftDoc[]> => {
  if('feed' in json){
    return atomToDraftDocs(json)
  }
  if('rss' in json){
    return rssToDraftDocs(json)
  }
  return []
}

export const xmlToDocs = async(base64: string): Promise<DraftDoc[]> => {
  const xml = decodeURIComponent(escape(atob(base64)))
  const json = await xmlToJSON(xml) as Atom | RSS
  return await jsonToDocs(json)
}

export const setDocs = (docs: DraftDoc[]) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: types.setDocs,
      payload: {
        docs
      }
    })
  }
}

export const selectDocs = (state: RootState) => state.imports.docs

const initialState = {
  docs: []
}

type State = {
  docs: DraftDoc[]
}

type SetAction = {
  type: 'drafts/setDocs'
  payload: {
    docs: DraftDoc[]
  }
}

const types = {
  setDocs: 'drafts/setDocs'
} as const

export const reducer = (
  state: State = initialState,
  action:  ClearAction | SetAction
) => {
  switch (action.type) {
    case types.setDocs: {
      return {
        ...state,
        docs: action.payload.docs
      }
    }

    case 'all/clear': { return initialState }
    default:
      return state
  }
}

