import PageContainer from '@/components/layouts/PageContainer'
// @ts-ignore platform specific codes
import AuthGoogleButton from '@/components/auth/GoogleButton'
import View from '@/components/View'
import Header from '@/components/layouts/Header'
import BackButton from '@/navigation/BackButton'
import Text from '@/components/Text'
import { StyleSheet } from 'react-native'
import Logo from '@/components/Logo'
import ResponsiveBox from '@/components/ResponsiveBox'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import Footer from '@/components/layouts/Footer'

import { SignupProps } from '@/navigation/types'
import useAuth from '@/hooks/useAuth'
import { useEffect } from 'react'
import { space, card, colors } from '@/styles/theme'
import { useTranslation, Trans } from '@/i18n'
import { PriceName } from '@/types/payment'

const prices = [
  'embedding',
  'GPT-3.5-turbo',
  'GPT-4-8k-prompt',
  'GPT-4-8k-completion',
] as PriceName[]

const styles = StyleSheet.create({
  container: {
    marginTop: space[4],
    display: 'flex',
  },

  left: {
    padding: space[4],
    paddingTop: 0,
    margin: space[2],
  },
  right: {
    flex: 1,
    margin: space[2],
    minWidth: 300,
  },

  card: card[10],
  cardContent: {
    padding: space[6],
  },

  optionsContainer: {
    marginTop: space[4],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  featureItem: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: space[2],
  }
})

// using multiple components inside Trans will remove the children.
const LegalLink = ({
  to,
  children
}: {
  to: string,
  children: string,
})=> {

  return(
    <Link to={to}>
      <Text link label>
        {children}
      </Text>
    </Link>
  )
}

const AgreeToTerms = ()=> {

  return(
    <Text label style={{ marginTop: space[2] }}>
      <Trans
        i18nKey="auth.signup.agreeToTerms"
        components={{
          terms: <LegalLink to="/legal/terms">terms</LegalLink>,
          privacy: <LegalLink to="/legal/privacy">privacy</LegalLink>,
        }}
      />
    </Text>
  )
}

const Price = ({
  name,
}: {
  name: PriceName,
}) => {
  const { t } = useTranslation()

  return(
    <View style={{ marginTop: space[2] }}>
      <Text light>
        {t(`pricing.payAsYouGo.prices.${name}.title`)}
      </Text>
      <Text label style={{ marginLeft: space[2], marginTop: space[0.5] }}>
        {t(`pricing.payAsYouGo.prices.${name}.price`)}
      </Text>
    </View>
  )
}

const features = [1,2,3]
const Feature = ({
  n,
}: {
  n: number,
}) => {
  const { t } = useTranslation()

  return (
    <View style={styles.featureItem}>
      <View style={{ height: '100%', marginTop: space[0.5] }}>
        <Icon
          icon="check-circle"
          style={{ marginRight: space[1], }}
          color={colors.blue[500]}
        />
      </View>

      <View style={{ width: '100%' }}>
        <Text light>
          {t(`pricing.payAsYouGo.features.${n}.title`)}
        </Text>
        <Text light body2 preLine
          style={{ marginTop: space[1] }}
        >
          {t(`pricing.payAsYouGo.features.${n}.description`)}
        </Text>
      </View>
    </View>
  )
}


const Signup = ({
  navigation,
}: SignupProps) => {

  const { isLoggedIn } = useAuth()
  const { t } = useTranslation()

  useEffect(()=> {
    if (isLoggedIn) {
      navigation.reset({ index: 0, routes: [{ name: 'Home' }] })
    }
  }, [isLoggedIn])

  return (
    <PageContainer>
      <Header
        left={
          <View style={{marginRight: space[3]}} >
            <BackButton onPress={()=> navigation.navigate('Landing')} />
          </View>
        }
      />

        <ResponsiveBox style={styles.container} reverseColumns>
          {/* left */}
          <View style={styles.left}>
            <Logo />
            <View style={{ marginTop: space[2] }}>
              <Text light h2>
                {t('pricing.payAsYouGo.name')}
              </Text>

              <View style={{marginLeft: space[3] }} >
                {features.map((n) => (<Feature key={n} n={n} />))}
              </View>

              <View style={{ marginTop: space[2], marginLeft: space[2] }} >
                <Text light h3>
                  {t('pricing.payAsYouGo.prices.title')}
                </Text>
                <View style={{marginLeft: space[4] }} >
                  {prices.map((name) => (<Price key={name} name={name} />))}
                </View>
              </View>
            </View>
          </View>

          {/* right */}
          <View style={styles.right}>
            <View style={styles.card}>
              <View style={styles.cardContent}>
                <Text h2 light>
                  {t('auth.signup.title')}
                </Text>

                <View style={styles.optionsContainer}>
                  <AuthGoogleButton />
                </View>

                <AgreeToTerms />
              </View>
            </View>

            <View style={{ marginTop: space[2] }}>
              <Text light body2>
                {t('auth.signup.alreadyHaveAccount')}
              </Text>
              <Link to="/auth/login">
                <Text link label>
                  {t('auth.login.title')}
                </Text>
              </Link>
            </View>
          </View>
        </ResponsiveBox>

      <Footer />
    </PageContainer>
  )
}

export default Signup
