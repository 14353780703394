import { Component } from 'react'
import Updates from '@/lib/Updates'
import sentry from '@/sentry'
import Platform from '@/lib/Platform'
import config from '@/config'

interface Props {
  children: any
}

interface State {
  error: any
}

class RootErrorBoundary extends Component<Props, State> {

  state = { error: null }

  // @ts-ignore error boundary incomplete type support
  componentDidCatch(error, errorInfo) {
    console.error('componentDidCatch')
    this.setState({ error })
    if(config.env !== 'development'){

      sentry.withScope((scope) => {
        scope.setTag("ErrorBoundary", "true")
        scope.setLevel('error')
        if(errorInfo){
          for (const key in errorInfo) {
            scope.setExtra(key, errorInfo[key])
          }
        }
        sentry.captureException(error)
      })

      this.reload()
    }
  }

  reload = async() => {
    if(Platform.OS === 'web'){
      (window as Window).location = '/'
    }else{
      await Updates.reloadAsync()
    }
  }

  render(){
    if(this.state.error){
      return null
    }else{
      return this.props.children
    }
  }

}

export default RootErrorBoundary
