import { useState, useEffect } from 'react'
import { update } from '@/features/docs'
import { useStore } from '@/store'
import { DocId } from '@/types'
import TitleInput from '@/components/TitleInput'
import { useTranslation } from '@/i18n'
import useLoading from '@/hooks/useLoading'
import DocGenerateOutlineButton from '@/components/docs/GenerateOutlineButton'
import useEntityValue from '@/hooks/useEntityValue'

const DocUpdateForm = ({
  docId,
}: {
  docId: DocId
}) => {

  const storedTitle = useEntityValue('docs', docId, 'title')

  const { t } = useTranslation()
  const { dispatch, getState } = useStore()
  const [title, setTitle] = useState(storedTitle)
  const { loading, withLoading } = useLoading()

  useEffect(()=> {
    setTitle(storedTitle)
  }, [storedTitle])

  const onSubmit = async()=> {
    await withLoading(async()=> {
      await update(docId, { title })(dispatch, getState)
    })
  }

  return (
    <>
      <TitleInput testID='input-title'
        placeholder={t('docs.form.title.placeholder')}
        value={title || ''}
        onChangeText={setTitle}
        onPressEnter={onSubmit}
        caption={t('docs.form.update.title.caption')}
        loading={loading}
      />
      <DocGenerateOutlineButton docId={docId} title={title} />
    </>
  )

}

export default DocUpdateForm
