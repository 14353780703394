import TitleInput from '@/components/TitleInput'
import { useTranslation } from '@/i18n'
import Text from '@/components/Text'
import { space } from '@/styles/theme'
import { StyleSheet, ViewStyle } from 'react-native'

const styles = StyleSheet.create({
  caption: {
    marginBottom: space[0.5]
  },
})

const FragmentTitleInput = ({
  value,
  onChangeText,
  onPressEnter,
  loading,
  caption,
  inputStyle,
}: {
  value: string
  onChangeText: (text: string) => void
  onPressEnter?: () => void
  loading?: boolean
  caption?: string | undefined | null
  inputStyle?: ViewStyle
}) => {

  const { t } = useTranslation()

  return (
    <>
      <Text caption style={styles.caption}>
        {t('fragments.form.input.label')}
      </Text>
      <TitleInput testID='input-input'
        multiline autoAdjustHeight
        placeholder={t('fragments.form.input.placeholder')}
        caption={caption}
        value={value}
        onChangeText={onChangeText}
        onPressCtrlEnter={onPressEnter}
        loading={loading}
        style={inputStyle}
      />
    </>
  )
}

export default FragmentTitleInput
