
const baseSpace = 8

export const space = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  [0.5]: baseSpace / 2,
  1: baseSpace,
  [1.5]: baseSpace * 1.5,
  2: baseSpace * 2,
  3: baseSpace * 3,
  4: baseSpace * 4,
  5: baseSpace * 5,
  6: baseSpace * 6,
  7: baseSpace * 7,
  8: baseSpace * 8,
  9: baseSpace * 9,
  10: baseSpace * 10,
}

export const radius = {
  xs: 2,
  sm: 4,
  md: 8,
  lg: 16,
  xl: 32,
}

export const fontSize = {
  heading: 16 * 1.96,
  subheading: 16 * 1.6 - 4,

  // material-ui defaults
  body1: 16,
  body2: 14,
  caption: 12,

  h1: 38,
  h2: 24,
  h3: 20,
  h4: 18,
}

export const iconSize = {
  small: 16,
  medium: 20,
  large: 35,
}

export const size = {
  button: {
    small: 20,
    large: 52,
  },
}

export const shadow = {
  8: {
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4, },
    shadowOpacity: 0.30,
    shadowRadius: 4.65,
    elevation: 8,
  }
}

export const maxWidth = {
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
}

export const colors = {
  contrastText: '#fff',
  contrast: '#fff',
  primary: { // deepPurple
    400: '#7e57c2',
    700: '#512da8'
  },
  blue: {
    10: '#f6fbff',
    50: '#e3f2fd',
    100: '#bbdefb',
    500: '#2196f3',
    700: '#1976d2',
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    300: '#e0e0e0',
    500: '#9e9e9e',
    600: '#757575',
    900: '#212121',
  },
  amber: {
    30: '#fffbe7',
    50: '#fff8e1',
    300: '#ffd54f',
    500: '#ffc107',
  },
  green: {
    100: '#c8e6c9',
    200: '#a5d6a7',
    600: '#43a047',
  },
  red: {
    50:  '#ffebee',
    600: '#e53935',
  },
  deepPurple: {
    400: '#7e57c2',
  },
  purple: {
    100: '#e1bee7',
  },
}

export const border = {
  1: {
    borderColor: colors.grey[300],
    borderWidth: 1,
    borderRadius: 4,
  }
}

export const card = {

  flat: {
    borderRadius: space[1],
    borderWidth: 1,
    borderColor: colors.grey[300],
    borderStyle: 'solid' as const
  },

  listItem: { // modified of 3
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1.1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    borderRadius: space[1],
    backgroundColor: colors.contrast,
  },

  button: { // modified of 5
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,

    elevation: 5,
    borderRadius: space[1],
    backgroundColor: colors.contrast,
  },

  dialog: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 24,
    },
    shadowOpacity: 0.34,
    shadowRadius: 38,

    elevation: 10,
  },

  1: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.00,

    elevation: 1,
    borderRadius: space[1],
  },

  5: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,

    elevation: 5,
    borderRadius: space[1],
  },

  7: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 5,
    elevation: 7,
  },

  10: {
    borderRadius: space[1],

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 10.27,

    elevation: 10,
  }
}
