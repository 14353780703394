import FontIcon from '@/components/FontIcon'
import { ViewStyle, TextStyle, StyleSheet } from 'react-native'
import TouchableOpacity from '@/components/TouchableOpacity'
import { colors, space, iconSize } from '@/styles/theme'
import Text from '@/components/Text'

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  on: {
    color: colors.primary[400],
  },
  off: {
    color: colors.grey[500],
  },
  text: {
    marginLeft: space[0.5],
  }
})

const Toggle = ({
  value,
  onPress,
  style,
  label,
}: {
  value: boolean
  onPress: (value: boolean)=> void
  style?: ViewStyle
  label?: string
  testID?: string
})=> {

  const handlePress = ()=> {
    onPress(!value)
  }

  return (
    <TouchableOpacity onPress={handlePress} style={[
      styles.button,
      style
    ]}>
      <FontIcon
        name={value ? 'toggle-on' : 'toggle-off'}
        size={iconSize.medium}
        style={[
          value ? styles.on : styles.off,
        ] as TextStyle}
      />
      {label ? (
        <Text body2 style={styles.text}>
          {label}
        </Text>
      ) : null}
    </TouchableOpacity>
  )
}

export default Toggle

