import NativeDropDownPicker from 'react-native-dropdown-picker'
import type { DropDownPickerProps, ValueType } from 'react-native-dropdown-picker'
import { View, StyleSheet } from 'react-native'
import { border, space, colors } from '@/styles/theme'
import { useState } from 'react'
import i18n from '@/i18n'
import { styles as textStyles } from '@/components/Text'

const text = {
  ...textStyles.text,
  ...textStyles.body2,
  color: colors.grey[600],
}

const styles = StyleSheet.create({
  picker: {
    ...border[1],
    minHeight: 'unset',
    height: 36,
    minWidth: 200,
    maxWidth: '50vw',
    paddingHorizontal: space[1],
    paddingVertical: space[1],
  },
  container: {
    ...border[1],
    overflow: 'scroll',
  },
  text,
  labelText: {
    ...text,
  },
  badgeText: {
    ...textStyles.textLight,
  },
  badge: {
    borderRadius: space[2],
  }
})

NativeDropDownPicker.setLanguage(i18n.language)
NativeDropDownPicker.addTranslation("ja", {
  PLACEHOLDER: "追加データセットの選択",
  SEARCH_PLACEHOLDER: "検索",
  SELECTED_ITEMS_COUNT_TEXT: "{count} コレクションを利用中",
  NOTHING_TO_SHOW: "コレクションがありません",
})
NativeDropDownPicker.addTranslation("en", {
  PLACEHOLDER: "Choose Additional Data Set",
  SEARCH_PLACEHOLDER: "Search",
  SELECTED_ITEMS_COUNT_TEXT: "Using {count} collection(s)",
  NOTHING_TO_SHOW: "No collections",
})

const DropDownPicker = ({
  items,
  setItems,
  value,
  setValue,
  onChangeValue,
}: {
  items: DropDownPickerProps<ValueType>['items'],
  setItems: DropDownPickerProps<ValueType>['setItems'],
  value: ValueType[],
  setValue: DropDownPickerProps<ValueType[]>['setValue'],
  onChangeValue?: any,
  testID?: string,
}) => {

  const [open, setOpen] = useState(false);
  const onSetValue = (value: any) => {
    setValue(value)
    setOpen(false)
  }

  // DropDownPicker requires View as a parent to render with correct positioning
  return(
    <View>
      <NativeDropDownPicker multiple={true}
        mode='BADGE'
        showBadgeDot={false}
        extendableBadgeContainer={false}

        // items are the base list of collections to pick from
        items={items}
        setItems={setItems}
        // values are the list of selected collections
        value={value}
        setValue={onSetValue}
        onChangeValue={onChangeValue}

        open={open}
        setOpen={setOpen}
        style={styles.picker}
        textStyle={styles.text}
        listItemLabelStyle={styles.labelText}
        dropDownContainerStyle={styles.container}
        badgeTextStyle={styles.badgeText}
        badgeColors={[colors.blue[50]]}
        badgeStyle={styles.badge}

        props={{ activeOpacity: 0.8 }}
        itemProps={{ activeOpacity: 0.8 }}
        badgeProps={{ activeOpacity: 0.8 }}
      />
    </View>
  )

}

export default DropDownPicker
