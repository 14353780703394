import { StyleSheet } from 'react-native'
import { useState, useEffect } from 'react'
import Text from '@/components/Text'
import View from '@/components/View'
import TextInput from '@/components/TextInput'
import Alert from '@/components/Alert'
import AlertDialog from '@/lib/Alert'
import SmallButton from '@/components/SmallButton'
import Toggle from '@/components/Toggle'
import { host } from '@/api'

import { remove, update } from '@/features/accessTokens'
import { accessToken, } from '@/features/entities/selectors'
import { useStore, useSelector, RootState } from '@/store'
import { useTranslation } from '@/i18n'
import { colors, space } from '@/styles/theme'
import useLoading from '@/hooks/useLoading'

const styles = StyleSheet.create({
  token: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputContainer: {
    marginBottom: space[1],
  },
  input: {
    fontSize: 14,
    color: colors.grey[600],
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: space[1],
  }
})

const dummyString = '**************************'

const toExample = (token: string) => {
  return `curl -X POST ${host}/v1/collections \\
    -H "Content-Type: application/json" \\
    -H "Accept-Language: en" \\
    -H "api-key: ${token}" \\
    -d '{ \"name\": \"Hello World\" }'`
}

const AccessTokenForm = ({
  tokenId,
}: {
  tokenId: string
}) => {

  const [revealed, setRevealed] = useState(false)
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => accessToken(state, tokenId))

  const { loading, withLoading } = useLoading()
  const { dispatch, getState } = useStore()
  const [isRemoved, setIsRemoved] = useState(false)
  const [allowDomain, setAllowDomain] = useState(false)
  const [domain, setDomain] = useState(token?.domain ||'')
  const isDomainUpdated = domain !== (token?.domain || '')

  useEffect(()=> {
    if(token?.domain) {
      setAllowDomain(true)
      setDomain(token.domain)
    }
  }, [token?.domain])

  const reveal = () => {
    setRevealed(!revealed)
  }

  const revoke = ()=> {
    AlertDialog.alert(
      t('alert.confirmRemove.title'),
      t('alert.confirmRemove.message'),
      [
        { text: t('alert.cancel'), style: 'cancel', },
        {
          text: t('alert.confirmRemove.ok'),
          onPress: handleRevoke,
          style: 'destructive',
        },
      ],
    )
  }

  const handleRevoke = async() => {
    await withLoading(async()=>{
      const removed = await remove(tokenId)(dispatch, getState)
      if(removed) {
        setIsRemoved(true)
      }
    })
  }

  const handleUpdate = async() => {
    await withLoading(async()=>{
      const json = await update(tokenId, { domain })(dispatch, getState)
      if(json.accessToken?.domain) {
        setDomain(json.accessToken.domain)
      }
    })
  }

  if(!token || isRemoved) return null

  return (
    <View>

      <TextInput selectTextOnFocus testID='input-token'
        label={`${t('accessTokens.item.token')} (ID: ${token.id})`}
        value={revealed ? token.token : dummyString}
        onChangeText={() => null}
        style={styles.inputContainer}
        inputStyle={styles.input}
        right={!revealed ? (
          <SmallButton testID='button-reveal-token'
            label={t('accessTokens.item.reveal')}
            iconName='eye-off-outline'
            onPress={reveal}
            style={{ marginLeft: space[1] }}
          />
        ) : null}
      />

      {allowDomain ? (
        <>
          <TextInput testID='input-domain'
            label={t('accessTokens.item.domain.input.label')}
            placeholder={t('accessTokens.item.domain.input.placeholder')}
            value={domain}
            onChangeText={setDomain}
            style={{ marginBottom: space[1] }}
          />
        </>
      ) : null}

      {revealed ? (
        <>
          <Alert
            type='code'
            title={t('accessTokens.item.example.title')}
            style={{ marginBottom: space[1] }}
            description={toExample(token.token)}
          />
          {!allowDomain ? (
            <Alert
              type='warning'
              title={t('accessTokens.item.token')}
              description={t('accessTokens.item.warning')}
            />
          ) : null}
        </>
      ) : null}

      {allowDomain ? (
        <Alert type='info' testID='info-domain'
          title={t('accessTokens.item.domain.info.title')}
          style={{ marginBottom: space[1] }}
          description={t('accessTokens.item.domain.info.description')}
        />
      ) : null}

      <View style={styles.buttons}>
        <Toggle testID='toggle-domain'
          label={t('accessTokens.item.domain.toggle.label')}
          value={allowDomain}
          onPress={setAllowDomain}
          style={{ marginRight: space[1] }}
        />

        <SmallButton testID='button-revoke-token' danger
          label={t('accessTokens.item.revoke')}
          iconName='trash-outline'
          onPress={revoke}
          loading={loading}
        />

        {isDomainUpdated ? (
          <SmallButton testID='button-update'
            label={t('accessTokens.item.update')}
            iconName='checkmark-outline'
            onPress={handleUpdate}
            style={{ marginLeft: space[1] }}
            loading={loading}
          />
        ) : null}
      </View>
    </View>
  )
}

export default AccessTokenForm
