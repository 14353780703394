import { requests, SafeResponse } from '@/features/requests'
import api from '@/api'
import { CollectionId, DocId, KeywordId, ChatModel } from '@/types'
import { setMany, set, remove as removeEntity } from '@/features/entities'
import { DocShow, DocsIndex } from '@/types/response'
import { add } from '@/features/flash'
import i18n from '@/i18n'
import { AppDispatch, GetState } from '@/store'

type DocParams = {
  title?: string
  externalId?: string
}

type UpdateParams = DocParams & {
  generateOutline?: boolean
  model?: ChatModel
}

type CreateParams = DocParams & {
  isImported?: boolean
  externalCreatedAt?: number
  externalId?: string
}

type CollectionParams = CreateParams & {
  collectionId: CollectionId,
}

type KeywordParams = CreateParams & {
  keywordId: KeywordId,
}

export const create = (params: CollectionParams | KeywordParams)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<{
    ok: boolean
    status: number
    data?: DocShow
  }> => {
    const response = await requests.post(api.docs.create(), params)(dispatch, getState)
    if(response.ok){
      const json: DocShow = await response.json()
      set('docs', json.doc)(dispatch)
      add({ type: 'success', text: i18n.t('flash.docs.create.success') })(dispatch, getState)
      return { ok: true, status: response.status, data: json }
    }else{
      const data = await response.json()
      return { ok: false, status: response.status, data }
    }
  }
}

export const update = (id: DocId, params: UpdateParams)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<DocShow> => {
    const response = await requests.post(api.docs.update(id), { ...params })(dispatch, getState)
    const json: DocShow = await response.json()
    set('docs', json.doc)(dispatch)
    return json
  }
}

export const sync = (id: DocId)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<DocShow> => {
    const response = await requests.post(api.docs.sync(id))(dispatch, getState)
    const json: DocShow = await response.json()
    set('docs', json.doc)(dispatch)
    return json
  }
}

export const index = (collectionId: CollectionId, nextPageKey?: DocsIndex['nextPageKey'])=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<DocsIndex> => {
    const response = await requests.get(
      api.docs.index(),
      {
        collectionId,
        nextPageKey: JSON.stringify(nextPageKey),
      }
    )(dispatch, getState)
    const json: DocsIndex = await response.json()
    setMany('docs', json.docs)(dispatch)
    return json
  }
}

export const read = (id: string)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<DocShow> => {
    const response = await requests.get(api.docs.show(id))(dispatch, getState)
    const json: DocShow = await response.json()
    set('docs', json.doc)(dispatch)
    return json
  }
}

export const poll = (id: DocId) => {
  return async(dispatch: AppDispatch, getState: GetState) => {
    await requests.poll({
      reader: async()=> await read(id)(dispatch, getState),
      taker: (json: DocShow)=> json.doc?.updatingFrom
    })
  }
}

export const remove = (id: string)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<SafeResponse> => {
    const response = await requests.remove(api.docs.remove(id))(dispatch, getState)
    if(response.ok){
      removeEntity('docs', id)(dispatch)
    }

    return response
  }
}

