import PageWithDrawer from '@/components/layouts/PageWithDrawer'
import { KeywordProps } from '@/navigation/types'
import { poll } from '@/features/keywords'
import { useStore } from '@/store'
import useEntityValue, { hasSameIds } from '@/hooks/useEntityValue'
import KeywordCard from '@/components/keywords/Card'
import DraftListItem from '@/components/drafts/ListItem'
import { StyleSheet } from 'react-native'
import { space } from '@/styles/theme'
import FlatList from '@/components/FlatList'
import Breadcrumbs from '@/components/Breadcrumbs'
import { useTranslation } from '@/i18n'
import Text from '@/components/Text'
import GenerateTitlesButton from '@/components/keywords/GenerateTitlesButton'
import usePolling from '@/hooks/usePolling'
import PollingCard from '@/components/PollingCard'
import { DraftId } from '@/types'

const styles = StyleSheet.create({
  page: {
    padding: space[3]
  },
  item: {
  },
  listHeader: {
    marginBottom: space[1]
  }
})

const KeywordPage = ({
  route
}: KeywordProps)=> {

  const { keywordId } = route.params
  const { t } = useTranslation()
  const { dispatch, getState } = useStore()
  const name = useEntityValue('keywords', route.params.keywordId, 'value')
  const draftIds = useEntityValue('keywords', route.params.keywordId, 'drafts', hasSameIds)
  const updatingFrom = useEntityValue('keywords', keywordId, 'updatingFrom')

  const collectionId = useEntityValue('keywords', route.params.keywordId, 'collectionId')
  const collectionName = useEntityValue('collections', collectionId, 'name')

  const { loading } = usePolling(
    updatingFrom,
    async()=> await poll(keywordId)(dispatch, getState),
    [keywordId]
  )

  const list = [
    { label: collectionName, to: `/collections/${collectionId}` },
    { label: t('keywords.list.title'), to: `/collections/${collectionId}/keywords` },
    { label: name },
  ]

  const renderItem = ({ item }: { item: unknown })=> (
    <DraftListItem draftId={item as DraftId} />
  )

  return (
    <PageWithDrawer>
      <FlatList
        style={styles.page}
        data={draftIds}
        renderItem={renderItem}
        loading={loading}
        ListHeaderComponent={
          <>
            <Breadcrumbs list={list} />
            <KeywordCard keywordId={route.params.keywordId} />
            <GenerateTitlesButton keywordId={route.params.keywordId} />
            <Text caption style={styles.listHeader}>{t('drafts.title.label')}</Text>
            <PollingCard updatingFrom={updatingFrom} />
          </>
        }
      />
    </PageWithDrawer>
  )

}

export default KeywordPage
