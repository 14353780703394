import View from '@/components/View'
import Text from '@/components/Text'
import { useEffect } from 'react'
import { index } from '@/features/orgs'
import {
  OrgId,
  Collection as CollectionType,
} from '@/types'
import { useStore } from '@/store'
import { space, colors } from '@/styles/theme'
import ActivityIndicator from '@/components/ActivityIndicator'
import Link from '@/components/Link'
import FontIcon from '@/components/FontIcon'
import MenuItems from '@/components/layouts/MenuItems'

import { useTranslation } from '@/i18n'
import { StyleSheet } from 'react-native'
import { selectOrgIds } from '@/features/entities/selectors'
import { useSelector, RootState } from '@/store'
import useLoading from '@/hooks/useLoading'

const styles = StyleSheet.create({
  childList: {
    paddingLeft: space[1],
    marginLeft: space[1],
    marginTop: space[1],
    borderLeftWidth: 1,
    borderLeftColor: colors.grey[300],
    borderStyle: 'solid',
  },
  item: {
    marginTop: space[1],
  },
  itemId: {
    marginLeft: space[2],
  },
  section: {
    marginTop: space[2],
  },
})

const partId = (id: string) => `${id.split('-')[0]}...${id.slice(-4)}`

const Collection = ({
  collectionId,
}: {
  collectionId: CollectionType['id']
}) => {

  const { t } = useTranslation()
  const name = useSelector((state: RootState) => state.entities?.collections[collectionId]?.name) || t('collections.item.unnamed')

  return(
    <Link to={`/collections/${collectionId}`} style={styles.item}>
      <View>
        <Text>
          {name}
        </Text>
        <Text caption style={styles.itemId}>
          ID: {partId(collectionId)}
        </Text>
      </View>
    </Link>
  )
}


const OrgCollections = ({
  orgId,
}: {
  orgId: OrgId
}) => {

  const collectionIds = useSelector((state: RootState) => state.entities?.orgs[orgId]?.collections)
  const { t } = useTranslation()

  return (
    <>
      {collectionIds?.map((collectionId)=> (<Collection key={collectionId} collectionId={collectionId} />))}
      <Link to={`/orgs/${orgId}/collections/new`} style={styles.item}>
        <View style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <FontIcon
            name="add-outline"
            color={colors.grey[900]}
          />
          <Text style={{ marginLeft: space[0.5] }}>
            {t('collections.list.new')}
          </Text>
        </View>
      </Link>
    </>
  )

}

const Organization = ({
  orgId,
}: {
  orgId: OrgId
}) => {

  const { t } = useTranslation()

  return (
    <View style={styles.section}>
      <Text label>{t('orgs.list.title')}</Text>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <Text>
          {partId(orgId)}
        </Text>

        <Link to={`/orgs/${orgId}/settings`}>
          <FontIcon
            name="settings-outline"
            color={colors.grey[900]}
          />
        </Link>
      </View>

      <View style={styles.childList}>
        <Text label>
          {t('collections.list.title')}
        </Text>
        <OrgCollections orgId={orgId} />
      </View>

    </View>
  )

}

const OrgsList = () => {

  const orgIds = useSelector(selectOrgIds)
  const { dispatch, getState } = useStore()
  const { loading, withLoading } = useLoading()

  useEffect(()=> {
    const load = async ()=> {
      await withLoading(async()=> {
        if(orgIds?.length) return

        await index()(dispatch, getState)
      })
    }
    load()
  }, [])

  return (
    <>
      {loading ? (
        <View style={{ marginTop: space[2] }}>
          <ActivityIndicator />
        </View>
      ): (
        <>
          {orgIds?.map((orgId)=> <Organization key={orgId} orgId={orgId} />)}

          <View style={styles.section}>
            <MenuItems />
          </View>
        </>
      )}
    </>
  )

}

export default OrgsList
