import removeAttributes from './removeAttributes'

export const toDocument = (html: string) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(html, 'text/html');
  return htmlDoc
}

const init = (html: string): string => {

  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(html, 'text/html');
  // remove any attributes
  const elements = htmlDoc.querySelectorAll('*')
  elements.forEach(removeAttributes)

  // back to string with decode
  return htmlDoc.body.innerHTML
}

export default init
