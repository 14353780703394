import View from '@/components/View'
import { poll } from '@/features/docs'
import { useStore } from '@/store'
import Fragments, {
  styles as fragmentsStyles,
} from '@/components/fragments/Fragments'
import { DocProps } from '@/navigation/types'
import PageWithDrawer from '@/components/layouts/PageWithDrawer'
import { StyleSheet } from 'react-native'
import useEntityValue from '@/hooks/useEntityValue'
import Breadcrumbs from '@/components/Breadcrumbs'
import usePolling from '@/hooks/usePolling'
import { lazy, Suspense } from 'react'
import DocUpdateForm from '@/components/docs/UpdateForm'
const DocExport = lazy(()=> import('@/components/docs/Export'))
import { useTranslation } from '@/i18n'
import Text from '@/components/Text'

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    flex: 1,
  }
})

const Doc = ({
  route,
}: DocProps)=> {

  const { docId } = route.params
  const collectionId = useEntityValue('docs', docId, 'collectionId')
  const docTitle = useEntityValue('docs', docId, 'title')
  const updatingFrom = useEntityValue('docs', docId, 'updatingFrom')
  const collectionName = useEntityValue('collections', collectionId, 'name')
  const { t } = useTranslation()

  const items = [
    { label: collectionName, to: `/collections/${collectionId}` },
    { label: docTitle },
  ]

  const { dispatch, getState } = useStore()

  const { loading } = usePolling(
    updatingFrom,
    async()=> await poll(docId)(dispatch, getState),
    [docId]
  )

  return (
    <PageWithDrawer>
      <View style={styles.page}>
        <Fragments
          docId={docId}
          loading={loading}
          ListHeaderComponent={(
            <View style={fragmentsStyles.item}>
              <Breadcrumbs list={items} />
              <Suspense fallback={null}>
                <DocExport docId={docId} />
              </Suspense>
              <Text caption>
                {t('docs.item.title')}
              </Text>
              <DocUpdateForm docId={docId} />
            </View>
          )}
        />
      </View>
    </PageWithDrawer>
  )

}

export default Doc
