import { AppDispatch, GetState } from '@/store'
import i18n from '@/i18n'
import { add } from '@/features/flash'
import { requests } from '@/features/requests'
import { AccessTokenShow } from '@/types/response'
import api from '@/api'
import { set, append } from '@/features/entities'
import { OrgId } from '@/types'

type CreateParams = {
  orgId: OrgId
}
type UpdateParams = {
  domain?: string
}

export const create = (params: CreateParams)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<AccessTokenShow> => {
    const response = await requests.post(api.accessTokens.create(), params)(dispatch, getState)
    if(response.ok){
      const json: AccessTokenShow = await response.json()
      if(!json.accessToken) return {}
      const token = json.accessToken

      set('accessTokens', token)(dispatch)
      append('accessTokens', token.id, params.orgId)(dispatch)
      add({ type: 'success', text: i18n.t('flash.accessTokens.create.success') })(dispatch, getState)
      return json
    }

    return {}
  }
}

export const update = (id: string, payload: UpdateParams) => {
  return async(dispatch: AppDispatch, getState: GetState): Promise<AccessTokenShow> => {
    const response = await requests.post(
      api.accessTokens.update(id),
      payload
    )(dispatch, getState)

    if(response.ok){
      const json: AccessTokenShow = await response.json()
      if(!json.accessToken) return {}
      const token = json.accessToken
      set('accessTokens', token)(dispatch)
      add({ type: 'success', text: i18n.t('flash.accessTokens.create.success') })(dispatch, getState)
      return json
    }

    return {}
  }
}

export const remove = (id: string) => {
  return async(dispatch: AppDispatch, getState: GetState): Promise<boolean> => {
    const response = await requests.remove(
      api.accessTokens.remove(id)
    )(dispatch, getState)

    if(response.ok){
      add({ type: 'success', text: i18n.t('flash.accessTokens.remove.success') })(dispatch, getState)
      return true
    }

    return false
  }
}
