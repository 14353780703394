import { useState, useEffect } from 'react'
import { CollectionId } from '@/types'
import View from '@/components/View'
import Text from '@/components/Text'
import TextInput from '@/components/TextInput'
import Button from '@/components/Button'
import Toggle from '@/components/Toggle'
import Alert from '@/components/Alert'
import { space } from '@/styles/theme'
import { StyleSheet } from 'react-native'
import SyncButton from '@/components/collections/SyncButton'

import { useStore } from '@/store'
import { useTranslation } from '@/i18n'
import useLoading from '@/hooks/useLoading'
import { update } from '@/features/collections'
import useEntityValue from '@/hooks/useEntityValue'

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  }
})

const SyncFeedForm = ({
  collectionId,
}: {
  collectionId: CollectionId
}) => {

  const { t } = useTranslation()
  const { dispatch, getState } = useStore()

  const remoteSyncUrl = useEntityValue('collections', collectionId, 'syncUrl') || ''
  const [syncUrl, setSyncUrl] = useState(remoteSyncUrl)
  useEffect(()=> setSyncUrl(remoteSyncUrl), [remoteSyncUrl])

  const [isChanged, setChanged] = useState(false)

  const remoteIsPartialRSS = useEntityValue('collections', collectionId, 'isPartialRSS')
  const [isPartialRSS, setIsPartialRSS] = useState(false)
  useEffect(()=> setIsPartialRSS(!!remoteIsPartialRSS), [remoteIsPartialRSS])

  const { loading, withLoading } = useLoading()

  const submit = async()=> {
    await withLoading(async()=> {
      await update(collectionId, {
        syncUrl,
        isPartialRSS,
      })(dispatch, getState)
      setChanged(false)
    })
  }

  const onChange = (syncUrl: string)=> {
    setSyncUrl(syncUrl)
    setChanged(syncUrl !== remoteSyncUrl || isPartialRSS !== remoteIsPartialRSS)
  }

  const onToggle = (value: boolean)=> {
    setIsPartialRSS(value)
    setChanged(value !== remoteIsPartialRSS || syncUrl !== remoteSyncUrl)
  }

  return (
    <>
      <Text label style={{ marginBottom: space[1] }}>
        {t('collections.settings.syncUrl.title')}
      </Text>
      <TextInput testID='input-url'
        value={syncUrl}
        onChangeText={onChange}
        style={{ marginBottom: space[1] }}
        placeholder={t('collections.settings.syncUrl.placeholder')}
      />
      {syncUrl ? (
        <>
          <View style={[styles.buttons, { marginBottom: space[1] }]}>
            <Toggle testID='toggle-partial-rss'
              label={t('collections.settings.syncUrl.isPartialRSS.label') || ''}
              value={isPartialRSS}
              onPress={onToggle}
            />
          </View>
          {isPartialRSS ? (
            <Alert testID='alert-is-partial-rss'
              type='warning'
              title={t('collections.settings.syncUrl.isPartialRSS.alert.title')}
              style={{ marginBottom: space[1] }}
              description={t('collections.settings.syncUrl.isPartialRSS.alert.description')}
            />
          ) : null}
        </>
      ) : null}
      {isChanged ? (
        <Alert testID='alert-liability'
          type='warning'
          title={t('collections.settings.syncUrl.liability.title')}
          style={{ marginBottom: space[1] }}
          description={t('collections.settings.syncUrl.liability.description')}
        />
      ) : null}
      <View style={styles.buttons}>
        {syncUrl && !isChanged ? (
          <View style={{ marginRight: space[1] }}>
            <SyncButton collectionId={collectionId} />
          </View>
        ) : null}
        {isChanged ? (
          <Button small testID='button-submit'
            variant="contained"
            onPress={submit}
            loading={loading}
            label={t('general.save')}
          />
        ) : null}
      </View>
    </>
  )
}

export default SyncFeedForm
