import { ChatModel } from '@/types'
import { AppDispatch, ClearAction } from '@/store'
import { Locale } from '@/i18n'

export const setModel = (model: ChatModel) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: types.set,
      payload: { model }
    })
  }
}

export const setLanguage = (language: Locale) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: types.set,
      payload: { language }
    })
  }
}

type SetAction = {
  type: 'generate/set'
  payload: {
    model?: ChatModel,
    language?: Locale
  }
}
type Action = SetAction | ClearAction

const types = {
  set: 'generate/set',
} as const

type State = {
  model: ChatModel
  language?: Locale
}
const initialState = {
  model: 'gpt-3.5-turbo' as ChatModel,
} as State

export const reducer = (state = initialState, action: Action) => {

  switch(action.type){
    case types.set: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case 'all/clear': { return initialState }
    default: { return state }
  }
}

