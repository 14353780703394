import { DocId } from '@/types'
import FragmentCreateForm from '@/components/fragments/CreateForm'

const AddFragment = ({
  docId,
}: {
  docId: DocId
})=> {

  return (
    <FragmentCreateForm
      docId={docId}
    />
  )
}

export default AddFragment
