import { requests } from '@/features/requests'
import api from '@/api'
import { DraftId, ChatModel } from '@/types'
import { DraftShow } from '@/types/response'
import { AppDispatch, GetState } from '@/store'
import i18n from '@/i18n'
import { set } from '@/features/entities'
import { add } from '@/features/flash'

type UpdateParams = {
  title?: string
  outline?: string
  generateDoc?: boolean
  generateOutline?: boolean
  model: ChatModel
}

export const update = (
  draftId: DraftId,
  payload?: UpdateParams
)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<DraftShow|null> => {
    const response = await requests.post(api.drafts.update(draftId), payload)(dispatch, getState)
    if(response.ok){
      const json: DraftShow = await response.json()
      set('drafts', json.draft)(dispatch)
      add({ type: 'success', text: i18n.t('drafts.update.success') })(dispatch, getState)
      return json
    }else{
      return null
    }
  }
}

export const read = (id: DraftId)=> {
  return async(dispatch: AppDispatch, getState: GetState): Promise<DraftShow> => {
    const response = await requests.get(api.drafts.show(id))(dispatch, getState)
    const json: DraftShow = await response.json()
    set('drafts', json.draft)(dispatch)
    return json
  }
}

export const poll = (id: DraftId)=> {
  return async(dispatch: AppDispatch, getState: GetState) => {
    await requests.poll({
      reader: async()=> await read(id)(dispatch, getState),
      taker: (json: DraftShow)=> json.draft?.updatingFrom
    })
  }
}

