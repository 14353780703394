import { useTranslation } from '@/i18n'
import { OrgCollectionNewProps } from '@/navigation/types'
import { space } from '@/styles/theme'
import { useState } from 'react'
import useLoading from '@/hooks/useLoading'
import { create } from '@/features/collections'
import { useStore, } from '@/store'

import View from '@/components/View'
import Text from '@/components/Text'
import TextInput from '@/components/TextInput'
import Button from '@/components/Button'
import PageWithDrawer from '@/components/layouts/PageWithDrawer'
import PageContainer from '@/components/layouts/PageContainer'

const OrgCollectionNew = ({
  route,
  navigation,
}: OrgCollectionNewProps) => {

  const { t } = useTranslation()
  const { dispatch, getState } = useStore()

  const { orgId } = route.params
  const [name, setName] = useState('')
  const { loading, withLoading } = useLoading()

  const submit = async()=> {
    await withLoading(async()=> {
      const data = await create({
        name,
        orgId,
      })(dispatch, getState)

      if(data?.collection){
        navigation.navigate('Collection', { collectionId: data.collection.id })
      }
    })
  }

  return (
    <PageWithDrawer>
      <PageContainer style={{ padding: space[2] }}>
        <Text label style={{ marginBottom: space[2] }}>
          {t('collections.new.title')}
        </Text>

        <TextInput testID='input-name'
          placeholder={t('collections.new.name.placeholder')}
          style={{ marginBottom: space[2], }}
          value={name}
          onChangeText={setName}
        />

        <View style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}>
          <Button testID='button-submit'
            small
            variant='contained'
            label={t('collections.new.submit')}
            onPress={submit}
            loading={loading}
          />
        </View>

      </PageContainer>
    </PageWithDrawer>
  )

}

export default OrgCollectionNew
