import { useEffect, useState } from 'react'

type SafeDependency = string | number | boolean | null | undefined
const usePolling = (
  updatingFrom: number | null | undefined,
  pollFn: ()=> Promise<void>,
  dependencies: SafeDependency[] = [],
) => {

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(!updatingFrom) {
      setLoading(false)
      return
    }

    const load = async () => {
      setLoading(true)
      await pollFn()
      setLoading(false)
    }
    load()
  }, [updatingFrom])

  useEffect(() => {
    if(dependencies.length === 0) return

    const load = async () => {
      setLoading(true)
      await pollFn()
      setLoading(false)
    }
    load()
  }, dependencies)

  return { loading }
}

export default usePolling
