import Text, { TextOptions } from '@/components/Text'
import { useMemo } from 'react'

const Timestamp = ({
  at,
  textProps,
}: {
  at: number
  textProps?: TextOptions
}) => {

  const readable = useMemo(() => {
    const date = new Date(at)
    return date.toLocaleString()
  }, [at])

  return(
    <Text {...textProps}>
      {readable}
    </Text>
  )
}

export default Timestamp
